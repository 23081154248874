import React, { useCallback, memo, useEffect, useMemo } from "react";
import '../styles/MainMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { baseBlade } from "../images/blade";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

/**
 * Steuerung des Hauptmenüs, sowie Bereitstellung alles Menü-Funktionen
 * @param {*} props 
 * @returns 
 */
const MainMenu = (props) => {
    //console.log("Menu rerender")
    /***
     *     __  ___      ___  ___  __  
     *    /__`  |   /\   |  |__  /__` 
     *    .__/  |  /~~\  |  |___ .__/ 
     *                                
     */

    const [showWidgets,setShowWidgets] = React.useState(false);
    const [showApplications,setShowApplications] = React.useState(false);
    const [showVisualisations,setShowVisualisations] = React.useState(false);
    const [availableDashboards,setAvailableDashboards] = React.useState([{name: "Default Dashboard", _id: "63da2e37814fe3b185af2ca4"}]);

    const menuWrapperClass = props.isShown ? "MainMenuWrapperHover" : "MainMenuWrapper"

    /***
     *     ___            __  ___    __        __  
     *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
     *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
     *                                             
     */


    const updateAvailableDashboards = useEffect(() => {
    
        //console.log("Props availableDashboards:")
        //console.log(props.availableDashboards)
        if(props.availableDashboards !== undefined){
            setAvailableDashboards(props.availableDashboards)
        }        
        
    },[props.availableDashboards]);

    /**
     * Funktion welche dafuer sorgt, dass das Menu bei dem Betreten mit der Maus
     * aufgeklappt wird.
     */
    const hoverHandler = () => {
        props.handleHover(true);
    }
    
    /**
     * Funktion welche dafuer sorgt, dass das Menu bei dem verlassen mit der Maus
     * wieder eingefahren wird
     */
    const outHandler = () => {
        props.handleHover(false);
    }

    /**
     * Funktion welche einen Link in einem neuen Tab oeffnet
     * Der Link, welcher geoeffnet werden soll wird mitgegeben
     * @param {*} link 
     */
    const openLink = (link) => {
        window.open(link,"_blank");
    }

    /**
     * Funktion welche den Klick auf ein Dashboard verarbeitet
     * Es werden die Dashboardinformationen mitgegeben
     * @param {*} dashboard 
     */
    const openDashboard = (dashboard) => {
        props.handleNotify({type: 'success', message: 'Dashboard:'+ dashboard.name + ' geladen'})
    }

    /**
     * Funktion welche den Klick auf den Button Dashboard erstellen verarbeitet
     */
    const createDashboard = () => {
        props.handleNotify({type: 'info', message: 'TODO: Dashboard erstellen'})
    }

    /**
     * Funktion welche den Klick auf die Handlungsanweisungen verarbeitet
     */
    const handlungsanweisungen = () => {
        props.handleNotify({type: 'info', message: 'TODO: Handlungsanweisungen aufrufen'})
    }

    /**
     * Funktion welche den Klick auf das entsprechende Widget verarbeitet
     * Es wird hier der Typ des Widgets mitgegeben
     * @param {*} widget 
     */
    const addWidget = (widget) => {
        props.handleNotify({type: 'success', message: widget + ' hinzugefügt'})
    }

    /**
     * Funktion welche den Klick auf die 3 Wochen Graph Checkbox verarbeitet
     */
    const setGraph = () => {
        props.handleNotify({type: 'info', message: 'TODO: 3 Wochen Graph global setzen'})
    }

    /**
     * Funktion welche den Klick auf die Bad Only Ansicht verarbeitet
     */
    const badOnly = () => {
        props.handleNotify({type: 'info', message: 'TODO: Bad Only Ansicht global setzen'})
    }

    /**
     * Funktion, welche den Klick auf den Logout Button verarbeitet
     */
    const logout = () => {
        props.handleNotify({type: 'success', message: 'Logout'})
    }

    /**
     * 
     * @returns Den Inhalt des Widget-Drop-Down-Menues
     */
    const MenuWidgets = () => {

        return(
            <div className="MainMenuItem--Content">
                <div className="MainMenuItemContent--Name" onClick={() => props.handleAddNewWidget('productoverview')}>Produktübersicht</div>
                <div className="MainMenuItemContent--Name" onClick={() => props.handleAddNewWidget('graphcontainer')}>Graphcontainer</div>
            </div>
        )
    }


    /**
     * 
     * @returns Den Inhalt des Applikationen-Dropdown-Menues
     */
    const Applikations = () => {
        return(
            <div className="MainMenuItem--DropDownContent">
                <div className="MainMenuItemContent--Name" onClick={() => openLink("https://monitoring-portal.freenet-group.de")}>Check-MK</div>
                <div className="MainMenuItemContent--Name" onClick={() => openLink("https://md.splunkcloud.com")}>Splunk</div>
            </div>
        )
    }

    /**
     * 
     * @returns Den Inhalt desVisualisierungs-Drop-Down-Menues
     */
    const Visualisierung = () => {
        return(
            <div className="MainMenuItem--DropDownContent">
                <div className="MainMenuItemContent--Name" onClick={() => openLink("https://monitoring.freenet-group.de")}>E2E</div>
            </div>
        )
    }

    const ExterneLinks = () => {
        return(
            <div className="MainMenuItem--Content">
                <div className="MainMenuItemContent--Name" onClick={() => openLink("https://monitoring-portal.freenet-group.de")}>Check-MK</div>
                <div className="MainMenuItemContent--Name" onClick={() => openLink("https://app.powerbi.com/Redirect?action=OpenApp&appId=babf065d-1aca-448a-afb2-7d0cafc00db2&ctid=7d95deb3-0bca-4c9d-a61e-1fd6c47ed60c")}>IT Dashboard</div>
                <div className="MainMenuItemContent--Name" onClick={() => openLink("https://md.splunkcloud.com")}>Splunk</div>
            </div>
        )
    }

    /***
     *     __   __            __   ___ ___       __       
     *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
     *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
     *                                                    
     */

    //console.log("Menu re-rendered!")

    return(
        <div    
            onMouseEnter={hoverHandler}
            onMouseLeave={outHandler}
            className={menuWrapperClass}>
            {/*className="MainMenuWrapperHover">*/}
            <div className="MainMenuContentrHover">
                {/* Header Element mit Ueberschrift und Blade Logo */}
                <div className="MainMenuHeader">
                    <div className="MenuHeader--Icon"><img src={baseBlade} alt="" width="45" heigt="45"></img></div>
                    <span className="MenuHeader--Text">Zentrale Dashboard Konfiguration</span>
                </div>
                <div className="MainMenueContent--Wrapper">
                    {/* Das erste Menuelement mit den Dashboards als Inhalt */}
                    <div className="MainMenuItem">
                        <div className="MainMenuItem--Header" >
                            <div className="MainMenuItem--Icon"><FontAwesomeIcon icon="fa-solid fa-house"/></div>
                            <div className="MainMenuItem--Text">Dashboards</div>
                        </div>
                        {/* Einbinden der Dashboards */}
                        
                        <div className="MainMenuItem--Content">
                        
                            {availableDashboards.map(dashboard => (
                                //<div key={dashboard.name + dashboard._id} className="MainMenuItemContent--Name" onClick={() => openDashboard(dashboard)}> {dashboard.name} </div>  
                                <div key={dashboard.name + dashboard._id + 'wrap'} className="MainMenuItemContent--DashboardWrapper">
                                    {
                                        dashboard.active === true ? 
                                        (<div key={dashboard.name + dashboard._id} className="MainMenuItemContent--DashboardNameActive" onClick={() => {props.handleChangeShownDashboard(dashboard._id); outHandler()}}> {dashboard.name} </div>)
                                        :
                                        (<div key={dashboard.name + dashboard._id} className="MainMenuItemContent--DashboardName" onClick={() => {props.handleChangeShownDashboard(dashboard._id); outHandler()}}> {dashboard.name} </div>)
                                    }
                                    {
                                        dashboard.name !=='Default Dashboard' ? 
                                            (
                                                <div className="MainMenuItemContent--DashboardDelete">
                                                <FontAwesomeIcon
                                                icon="fa-solid fa-trash"
                                                onClick={() => {props.handleDeleteDashboard(dashboard._id, dashboard.name)}}
                                                />
                                            </div>
                                            ) : null
                                    }
                                    
                                </div>
                            ))}
                        
                        </div>
                        
                        {/*<div className="MainMenuContent--Button" onClick={() => createDashboard()}>Dashboard Erstellen</div>*/}
                    </div>
                    <div className="MainMenuItem--Divider"></div>
                    {/* Das zweite Menuelement mit den Editoren als Inhalt */}
                    <div className="MainMenuItem">
                        <div className="MainMenuItem--Header" >
                            <div className="MainMenuItem--Icon"><FontAwesomeIcon icon="fa-solid fa-pen-to-square"/></div>
                            <div className="MainMenuItem--Text">Tools</div>
                        </div>
                        <div className="MainMenuItem--Content">
                            <div className="MainMenuItemContent--Name" onClick={() => props.handleToggleDowntime()}>Downtimeverwaltung</div>
                            {/*<div className="MainMenuItemContent--Name" onClick={() => handlungsanweisungen()}>Handlungsanweisungen</div>*/}
                        </div>
                    </div>
                    <div className="MainMenuItem--Divider"></div>
                    {/* Das dritte Menuelement mit den Widgets als Inhalt */}
                    <div className="MainMenuItem">
                        <div className="MainMenuItem--Header">
                            <div className="MainMenuItem--Icon"><FontAwesomeIcon icon="fa-solid fa-folder-plus"/></div>
                            <div className="MainMenuItem--Text">Widget hinzufügen</div>
                        </div>
                        <div className="MainMenuItem--Content">
                        {/*<div className="MainMenuItemContent--DropdownSeperator"></div>*/}
                        {/*
                            <div className="MainMenuItemContent--Header" onClick={() => setShowWidgets(!showWidgets)}>
                                <span className="MainMenuContent--DropdownName">Widget Hinzufügen</span>
                                {showWidgets ? <div className="MainMenuItemContent--Icon"><FontAwesomeIcon icon="fa-solid fa-chevron-up"/></div> : <div className="MainMenuItemContent--Icon"><FontAwesomeIcon icon="fa-solid fa-chevron-down"/></div> } 
                            </div>
                            */}
                            {/* Einblenden der Widgets Komponenten je nachdem ob der State showWidgets gesetzt ist oder nicht */}
                            {/*showWidgets ? <MenuWidgets /> : null*/}
                            <div className="MainMenuItem--Content">
                                <div className="MainMenuItemContent--Name" onClick={() => props.handleAddNewWidget('cloudflarewidget')}>Cloudflare Firewall</div>
                                <div className="MainMenuItemContent--Name" onClick={() => props.handleAddNewWidget('graphcontainer')}>Graphcontainer</div>
                                <div className="MainMenuItemContent--Name" onClick={() => props.handleAddNewWidget('productoverview')}>Produktübersicht</div>
                                <div className="MainMenuItemContent--Name" onClick={() => props.handleAddNewWidget('mauiwidget')}>Maui Services</div>
                                <div className="MainMenuItemContent--Name" onClick={() => props.handleAddNewWidget('productstatus')}>TV Error only</div>
                            </div>
                        </div>
                    </div>
                    <div className="MainMenuItem--Divider"></div>
                    {/* Das vierte Menuelement mit den Externen Links als Inhalt */}
                    <div className="MainMenuItem">
                        <div className="MainMenuItem--Header" >
                            <div className="MainMenuItem--Icon"><FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square"/></div>
                            <div className="MainMenuItem--Text">Externe Links</div>
                        </div>
                        <div className="MainMenuItem--Content">
                            {/* 
                            <div className="MainMenuItemContent--DropdownSeperator"></div>
                            <div className="MainMenuItemContent--Header" onClick={() => setShowApplications(!showApplications)}>
                                <div className="MainMenuContent--DropdownName">Applikationen</div>
                                {showApplications ? <div className="MainMenuItemContent--Icon"><FontAwesomeIcon icon="fa-solid fa-chevron-up"/></div> : <div className="MainMenuItemContent--Icon"><FontAwesomeIcon icon="fa-solid fa-chevron-down"/></div> } 
                            </div>
                            */}
                            {/* Einblenden der Applikations Komponenten je nachdem ob der State showApplications gesetzt ist oder nicht */}
                            {/*showApplications ? <Applikations /> : null*/}
                            {/* TODO hier Element Seperator nur erstellen, wenn showApplikations gesetzt */}
                            {/*showApplications ? <div className="MainMenuItemContent--DropdownSeperator"></div> : null*/}
                            {/*
                            <div className="MainMenuItemContent--DropdownSeperator"></div>
                            <div className="MainMenuItemContent--Header" onClick={() => setShowVisualisations(!showVisualisations)}>
                                <div className="MainMenuContent--DropdownName">Visualisierungen</div>
                                {showVisualisations ? <div className="MainMenuItemContent--Icon"><FontAwesomeIcon icon="fa-solid fa-chevron-up"/></div> : <div className="MainMenuItemContent--Icon"><FontAwesomeIcon icon="fa-solid fa-chevron-down"/></div> } 
                            </div>
                            */}                        
                            {/* Einblenden der Visualisierungs Komponenten je nachdem ob der State showVisualisations gesetzt ist oder nicht */}
                            {/*showVisualisations ? <Visualisierung /> : null*/}
                            <ExterneLinks />
                        </div>
                    </div>
                    
                    <div className="MainMenuItem--Divider"></div>
                    <button className="darkmodeSwitchBTN" onClick={props.handleSwitchTheme}>
                        Switch Darkmode
                    </button>
                    {/*
                    <div className="MainMenuItem">
                        <div className="MainMenuItem--Header" >
                            <div className="MainMenuItem--Icon"><FontAwesomeIcon icon="fa-solid fa-user"/></div>
                            <div className="MainMenuItem--Text">Usereinstellungen</div>
                        </div>
                        <div className="MainMenuItem--Content">
                            <div className="MainMenuItemContent--CheckboxHeader">
                                <div className="MainMenuItemContent--CheckboxName" >3 Weeks Graph</div> 
                                <label className="switch">
                                    <input type="checkbox" onChange={() => setGraph()}></input>
                                    <span className="slider round" ></span>
                                </label>
                            </div>
                            <div className="MainMenuItemContent--CheckboxHeader">
                                <div className="MainMenuItemContent--CheckboxName">Bad Only Display</div>
                                    <label className="switch">
                                        <input type="checkbox" onChange={() => badOnly()}></input>
                                        <span className="slider round"></span>
                                    </label>
                            </div>
                            {/*<div className="MainMenuContent--Button" onClick={() => logout()}>Logout</div>}
                        </div>
                    </div>
                    */}
                </div>
            </div>
            {
            /* 
                Einblenden des Menubuttons jenachdem, ob der State isShow, welcher aus der App.js mit uebergeben wurde,
                gesetzt ist oder nicht
            */
            }
            {!props.isShown ? 
                <div className="MainMenuButton">
                    <div className="MainMenuButton--Text">Konfiguration</div>
                </div> : null
            }
        </div>
    )
}

export default memo(MainMenu)