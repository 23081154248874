import React, { memo, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropagateLoader from 'react-spinners/PropagateLoader';
import { productStaticAPI, timeseriesAPI, graphPrioEventsAPI, graphDeploymentEventsAPI } from "../http-common";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import noThumbnail from "../images/noimage.png";

import '../styles/SingleGraph.css';
import "../styles/SynmonAnalyse.css";


const SynmonAnalyse = (props) => {
    console.log(props)
    /***
     *     __  ___      ___  ___  __
     *    /__`  |   /\   |  |__  /__`
     *    .__/  |  /~~\  |  |___ .__/
     *
     */
    const [isLoaded, setIsLoaded] = React.useState(false);                                       // Indikator ob Daten geladen wurden.       
    const [hasError, setHasError] = React.useState(false);                                       // Indikator ob Fehler vorliegen
    const [errorsIsLoaded, setErrorsIsLoaded] = React.useState(false);                                       // Indikator ob Daten geladen wurden.       
    const [errorsHasError, setErrorsHasError] = React.useState(false);                                       // Indikator ob Fehler vorliegen    
    const [hdImageIsLoaded, setHdImageIsLoaded] = React.useState(false);                                       // Indikator ob Daten geladen wurden.       
    const [hdImageHasError, setHdImageHasError] = React.useState(false);                                       // Indikator ob Fehler vorliegen        
    const [hdImage, setHdImage] = React.useState([]);                                                           // speichern des hdImages
    const [latestErrors, setLatestErrors] = React.useState([]);                                                // speichern der abgerufenen Error
    const selectedErrorIndex = React.useRef(0);
    const initialLoadingDone = React.useRef(0);
    const [okIsLoaded, setOkIsLoaded] = React.useState(false);                                               // Indikator ob Daten geladen wurden.       
    const [okHasError, setOkHasError] = React.useState(false);                                               // Indikator ob Fehler vorliegen    
    const [okData, setOkData] = React.useState([]);                                                           // speichern des ok Objekts
    const [graphIsLoaded, setGraphIsLoaded] = React.useState(false);                                         // Indikator ob Daten geladen wurden.       
    const [graphIsShown, setGraphIsShown] = React.useState(false);                                           // Indikator ob ein Graph angezeigt wird
    const [graphHasError, setGraphHasError] = React.useState(false);                                         // Indikator ob Fehler vorliegen    
    const [options, setOptions] = React.useState(null);                                          // Options holds the options for the Highcharts
    const ref = React.useRef();
    const seriesCount = React.useRef(0);
    const prioCount = React.useRef(0);
    const deploymentCount = React.useRef(0);

    /***
     *          __   ___     ___  ___  ___  ___  __  ___  __
     *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__`
     *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/
     *
     */

    React.useEffect(() => {
        fetchAllErrors();
        fetchOkData();
    }, []);


    /***
     *     ___            __  ___    __        __
     *    |__  |  | |\ | /  `  |  | /  \ |\ | /__`
     *    |    \__/ | \| \__,  |  | \__/ | \| .__/
     *
     */

    /* *******************************************************************************************************************************************
    /*                            Funktionen zum Handling der Error Screenshots & Infos
    /* ****************************************************************************************************************************************** */

    const switchError = (index) => {
        console.log("Switch to Error Index: " + index)
        selectedErrorIndex.current = index
        fetchAllErrors();
    }

    const fetchAllErrors = async () => {
        //setErrorsIsLoaded(false);
        setErrorsHasError(false);

        const endpointURL = "/"+ props.data.data.productID +"/tests/"+ props.data.data.testID +"/errors"
        const idToken = props.receiveIDToken();
        const headers = { 'Authorization': idToken };

        const allErrors = await productStaticAPI.get(endpointURL, {headers})
        .then(response => {   
            console.log("response:")
            console.log(response)
            let obj = {
              data:  response.data
            };
            setErrorsIsLoaded(true);
            setErrorsHasError(false);
            setLatestErrors(obj);
            fetchHdImage(response.data[selectedErrorIndex.current]._id);
            return obj
        })
        .catch(function (error) {
          console.log("Fetch issue: get all errors " +error)
          props.handleNotify({type: 'alert', message: "Fehler beim Abrufen der letzten Crit Messungen"})
          let obj = {
            data:  []
          };
          setErrorsIsLoaded(true);
          setErrorsHasError(true);
          setHdImageIsLoaded(true);
          setHdImageHasError(true)
          setLatestErrors(obj);
          return (obj)
        });          
    }

    const fetchHdImage = async (errorID) => {
        console.log("Get HD Image for: " + errorID)
        setHdImageIsLoaded(false);
        setHdImageHasError(false);

        const endpointURL = "/"+ props.data.data.productID +"/tests/"+ props.data.data.testID +"/errors/"+errorID+"/screenshot"
        const idToken = props.receiveIDToken();
        const headers = { 'Authorization': idToken };

        const hdImage = await productStaticAPI.get(endpointURL, {headers})
        .then(response => {   
            console.log("response HD:")
            console.log(response)
            let obj = {
              data:  response.data
            };
            setHdImageIsLoaded(true);
            setHdImageHasError(false);
            setHdImage(obj);
            return obj
        })
        .catch(function (error) {
          console.log("Fetch issue: get all errors " +error)
          props.handleNotify({type: 'alert', message: "Fehler beim Abrufen des letzten Fehler Screenshots"})
          let obj = {
            data:  []
          };
          setHdImageIsLoaded(true);
          setHdImageHasError(true);
          setHdImage(obj);
          return (obj)
        });          
    }   
    
    const fetchOkData = async () => {
        
        setOkIsLoaded(false);
        setOkHasError(false);

        const endpointURL = "/"+ props.data.data.productID +"/tests/"+ props.data.data.testID +"/oks"
        const idToken = props.receiveIDToken();
        const headers = { 'Authorization': idToken };

        const okData = await productStaticAPI.get(endpointURL, {headers})
        .then(response => {   
            console.log("response:")
            console.log(response)
            let obj = {
              data:  response.data
            };
            setOkIsLoaded(true);
            setOkHasError(false);
            setOkData(obj);
            return obj
        })
        .catch(function (error) {
          console.log("Fetch issue: get ok Status " +error)
          props.handleNotify({type: 'alert', message: "Fehler beim Abrufen des letzten OK Status"})
          let obj = {
            data:  []
          };
          setOkIsLoaded(true);
          setOkHasError(true);
          setOkData(obj);
          return (obj)
        });          
    }     

    /* *******************************************************************************************************************************************
    /*                            Funktionen zum Handling des Detail Graphen
    /* ****************************************************************************************************************************************** */
    const endpointURL = "/"+ props.data.data.productID +"/tests/"+ props.data.data.testID +"/performance?grouped=false"

    const fetchData = async () => {
        const idToken = props.receiveIDToken();
        const headers = { 'Authorization': idToken };

        const res = await timeseriesAPI.get(endpointURL, {headers});

        const prioEvents = await graphPrioEventsAPI.get("", {headers})
        .then(response => {
          
          if(props.showPrioFlags === true){
            let obj = {
              data:  response.data
            };
            return obj
          } else {
            let obj = {
              data:  []
            };
            return (obj)          
          }        
        })
        .catch(function (error) {
          console.log("Fetch error prio events" +error)
          props.handleNotify({type: 'alert', message: "Fehler beim Abrufen der Flags: Priostörungen"})
          let obj = {
            data:  []
          };
          return (obj)
        });
  
        //console.log("prioevents:")
        //console.log(prioEvents)
  
        const deploymentEvents = await graphDeploymentEventsAPI.get("", {headers})
        .then(response => {
          if(props.showDeploymentFlags === true){
            let obj = {
              data:  response.data
            };
            return obj
          } else {
            let obj = {
              data:  []
            };
            return (obj)          
          }        
        })
        .catch(function (error) {
          console.log("Fetch error deployment events" + error)
          props.handleNotify({type: 'alert', message: "Fehler beim Abrufen der Flags: Deployments"})
          let obj = {
            data:  []
          };
          return (obj)
        });
      
        //console.log("Loaded Prio:")
        //console.log(prioEvents.data)
  
        //console.log(res)      
        const result = {
            status: res.status + "-" + res.statusText,
            headers: res.headers,
            data: res.data,
            prio: prioEvents.data,
            deployments: deploymentEvents.data          
        };
  
        /*
        console.log("PrioData:")
        console.log(result.prio)
        console.log("deployments:")
        console.log(result.deployments)
        */
  
        return result
      }

    const showDetailGraph = () => {
        setGraphIsShown(true)
        setGraphIsLoaded(false)
                
        const fetchRecords = async () => {
          
            const idToken = props.receiveIDToken();
            const headers = { 'Authorization': idToken };
            const res = await timeseriesAPI.get(endpointURL, {headers});
  
            const prioEvents = await graphPrioEventsAPI.get("", {headers})
            .then(response => {

                let obj = {
                  data:  response.data
                };
                return obj
      
            })
            .catch(function (error) {
              console.log("Fetch error prio events" +error)
              props.handleNotify({type: 'alert', message: "Fehler beim Abrufen der Flags im Detailgraphen: Priostörungen"})
              let obj = {
                data:  []
              };
              return (obj)
            });          
  
  
            const deploymentEvents = await graphDeploymentEventsAPI.get("", {headers})
            .then(response => {

                let obj = {
                  data:  response.data
                };
                return obj
 
            })          
            .catch(function (error) {
              console.log("Fetch error deployment events" + error)
              props.handleNotify({type: 'alert', message: "Fehler beim Abrufen der Flags im Detailgraphen: Deployments"})
              let obj = {
                data:  []
              };
              return (obj)
            });
            
            const result = {
                status: res.status + "-" + res.statusText,
                headers: res.headers,
                data: res.data,
                prio: prioEvents.data,
                deployments: deploymentEvents.data
            };
  
            const minCrit = props.data.data.ok;
            const warn = props.data.data.warn;
            const crit = props.data.data.crit;
            //const minCrit = 0;
            //const warn = 100;
            //const crit = 120;            
            const graphTitel = null;
            const subTitel = null;            
            const graphEinheit = 'sek.';
            const PlotLabelColor = 'var(--PlotLabelColorCustom)';
  
            /// Setting the Highstock options variable
            setOptions({
              
              title: {
                text: graphTitel,
                style: {
                  color: 'white',
                  fontWeight: 'bold'
                }
              },
  
              subtitle: {
                  text: subTitel,
                  style: {
                      color: 'white',
                  }
              },
  
              scrollbar: {
                barBackgroundColor: 'var(--subtext-color)',
                barBorderRadius: 5,
                barBorderWidth: 0,
                buttonBackgroundColor: 'var(--subtext-color)',
                buttonBorderWidth: 0,
                buttonBorderRadius: 5,
                trackBackgroundColor: 'var(--hover-color)',
                trackBorderWidth: 0,
                trackBorderRadius: 6,
                trackBorderColor: '#CCC'
              },
  
              chart: {
                zoomType: 'x',
                backgroundColor: 'transparent',
                reflow: true,
                
              events: {
                  load: function () {
                      
                      let series = this.series[0];
                      let prioSeries = this.series[1];
                      let deploymentSeries = this.series[2]; 
                      seriesCount.current = series.xData.length;
                      prioCount.current = prioSeries.data.length;
                      deploymentCount.current = deploymentSeries.data.length; 
                     
                          fetchData().then(
                            function(value) {
                              /*
                              console.log("value:")
                              console.log(value)
                              
                              console.log("series: ")
                              console.log(series)
          
                              console.log("PrioSeries: ")
                              console.log(prioSeries)
          
                              console.log("deploymentSeries: ")
                              console.log(deploymentSeries)  
                              */
  
                              //--------------------------------------------
                              // Prüfen ob neue Values des Graphen vorliegen
                              //--------------------------------------------
                              const oldLength = seriesCount.current;
                              //console.log("Series: ")
                              //console.log(series)
                              const newLength = value.data.length;
                              //console.log("Value: ")
                              //console.log(value)                            
  
                              if(oldLength !== newLength){
                                  console.log("Neue Daten für Graphen gefunden.")
                                  const newPoints = newLength - oldLength;
                                  const newStart = newLength - newPoints;
                                  
                                  console.log("Old Length: " + oldLength)
                                  console.log("New Length: " + newLength)
                                  console.log("New Points: " + newPoints)
                                  console.log("New Start: " + newStart)
                                  
                                  if(newPoints < 10){
                                    for(let i = 0; i < newPoints; i++ ){
                                        
                                        let getFrom = newStart;                                    
                                        console.log("Add Data: " + value.data[getFrom])
                                        series.addPoint([value.data[getFrom][0], value.data[getFrom][1]], true, true);
                                        getFrom++
  
                                    }
                                    seriesCount.current = newLength
                                  } else {
                                    console.log("Zuviele neue Messungen. Seite refreshen!")    
                                  }
  
                              } else {
                                //console.log("Keine neuen Daten vorhanden.")
                              }
  
                              //--------------------------------------------
                              // Prüfen ob neue Daten der Priostörungen vorliegen
                              //--------------------------------------------  
                              
                              const prioOldLength = prioCount.current;
                              const prioNewLength = value.prio.length;
  
                              /*
                              console.log("PrioOld: ")
                              console.log(prioCount.current) 
                              console.log("PrioNew: " + prioNewLength)
                              */
  
                              if(prioOldLength !== prioNewLength){
                                  console.log("Neue Daten für Priostörungen gefunden.")
                                  const prioNewPoints = prioNewLength - prioOldLength;
                                  const prioNewStart = prioNewLength - prioNewPoints;
  
                                  if(prioNewPoints < 10){
                                    for(let i = 0; i < prioNewPoints; i++ ){
                                        
                                        let prioGetFrom = prioNewStart;                                    
                                        console.log("Add Prio: " + value.prio[prioGetFrom])
                                        prioSeries.addPoint({x: value.prio[prioGetFrom]['x'] ,title: value.prio[prioGetFrom]['title'],text: value.prio[prioGetFrom]['text']});
                                        prioGetFrom++
  
                                    }
                                    prioCount.current = prioNewLength
                                  } else {
                                    console.log("Zuviele neue Einträge bei den Priostörungen. Seite refreshen!")    
                                  }
  
                              } else {
                                //console.log("Keine neuen Priostörungen vorhanden.")
                              }   
                              
                              //--------------------------------------------
                              // Prüfen ob neue Daten der Deployments vorliegen
                              //--------------------------------------------  
                              
                              const deploymentsOldLength = deploymentCount.current;
                              const deploymentsNewLength = value.deployments.length;
  
                              if(deploymentsOldLength !== deploymentsNewLength){
                                  console.log("Neue Daten für Priostörungen gefunden.")
                                  const deploymentsNewPoints = deploymentsNewLength - deploymentsOldLength;
                                  const deploymentsNewStart = deploymentsNewLength - deploymentsNewPoints;
  
                                  if(deploymentsNewPoints < 10){
                                    for(let i = 0; i < deploymentsNewPoints; i++ ){
                                        
                                        let deploymentsGetFrom = deploymentsNewStart;                                    
                                        console.log("Add Deployment: " + value.deployments[deploymentsGetFrom])
                                        deploymentSeries.addPoint({x: value.deployments[deploymentsGetFrom]['x'] ,title: value.deployments[deploymentsGetFrom]['title'],text: value.deployments[deploymentsGetFrom]['text']});
                                        deploymentsGetFrom++
  
                                    }
                                    deploymentCount.current = deploymentsNewLength
                                  } else {
                                    console.log("Zuviele neue Einträge bei den Deployments. Seite refreshen!")    
                                  }
  
                              } else {
                                //console.log("Keine neuen Deployments vorhanden.")
                              }                             
  
                            },
                            function(error) {
                              console.log("Error beim Updaten des Graphen")
                              console.log(error)
                              setHasError(true)
                            }
                          )                        
  

  
                  }
                }
                
              },
  
              yAxis: [{
                opposite:false,															              // Linke Seite für die Label festlegen
                gridLineColor: 'var(--subtext-color)',
                labels: {
                    formatter: function() {
                      return this.value + ' ' + graphEinheit;							// Übergebene Einheit
                    },
                    style: {
                        color: PlotLabelColor
                    }
                },
  
                title: {
                    text: 'Durchlaufzeit in ' + graphEinheit,
                },
  
                plotLines: [{
                    color: '#e6be00', 														        // Color value
                    dashStyle: 'shortdash', 												      // Style of the plot line. Default to solid
                    value: warn, 															            // Value of where the line will appear
                    width: 1, 																            // Width of the line
                    label: {
                        text: 'Warnung: ' + warn + ' ' + graphEinheit,		// Content of the label.
                        align: 'left', 														        // Positioning of the label.
                        style: {
                            color: PlotLabelColor,												// Fontcolor
                            'font-size': 'xx-small'
                        }
                    }
                },{
                    color: '#df002e', 														        // Color value
                    dashStyle: 'shortdash', 												      // Style of the plot line. Default to solid
                    value: crit,															            // Value of where the line will appear
                    width: 1, 																            // Width of the line
                    label: {
                        text: 'Kritisch: ' + crit + ' ' + graphEinheit,		// Content of the label.
                        align: 'left', 														        // Positioning of the label.
                        style: {
                            color: PlotLabelColor,												// Fontcolor
                            'font-size': 'xx-small'
                        }
                    }
                }]
              }],
  
  
              xAxis: [{
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                labels: {
                    style: {
                      color: PlotLabelColor
                    }
                }
              }],
  
              rangeSelector: {
                  buttonTheme: { 
                    fill: 'none',
                    stroke: 'none',
                    'stroke-width': 0,
                    r: 8,
                    style: {
                        color: 'var(--subtext-color)',
                        fontWeight: 'bold'
                    },
                    states: {
                        hover: {
                          fill: 'var(--hover-color)',
                            style: {
                                color: 'white'
                            }
                        },
                        select: {
                            fill: 'var(--hover-color)',
                            style: {
                                color: 'white'
                            }
                        }
                    }
                  },
                  inputStyle: {
                    color: 'var(--subtext-color)',
                    fontWeight: 'bold'
                  },
                  selected: 3,
                  inputEnabled: true,
                  inputDateFormat: '%d.%m.%Y',
                  buttons: [{
                      type: 'hour',
                      count: 6,
                      text: '6h',
                      events: {
                          click: function() {
  
                          }
                      }
                  },{
                      type: 'hour',
                      count: 12,
                      text: '12h',
                      events: {
                          click: function() {
  
                          }
                      }
                  },{
                      type: 'day',
                      count: 1,
                      text: '1d',
                      events: {
                          click: function() {
  
                          }
                      }
                  },{
                      type: 'week',
                      count: 1,
                      text: '1w',
                      events: {
                          click: function() {
  
                          }
                      }
                  },{
                      type: 'week',
                      count: 3,
                      text: '3w',
                      events: {
                          click: function() {
  
                          }
                      }
                  },{
                      type: 'all',
                      text: 'All'
                  }]
              },    
  
  
              plotOptions: {
                  line: {
                      dataLabels: {
                          enabled: false
                      },
                  }
              },
  
              series: [
                  {
                      name : 'Antwortzeit',
                      turboThreshold: 11000,
                      data: result.data,
                      id: 'dataseries',
  
                      zones: [{															      // Festlegen in welchen Bereichen Alarmiert wird
                        value: minCrit,														// Bei y-Wert bis minCrit = crit (rot)
                        color: '#df002e'
                      },{
                        value: warn,														  // Bei y-Wert bis warn = warnung (gelb)
                        color: '#84bc34'
                      }, {
                        value: crit,														  // Bei y-Wert ab crit = kritisch (rot)
                        color: '#e6be00'
                      }, {
                        color: '#df002e'													// sonst (grÃ¼n)
                    }],
  
                    dataGrouping: {
                      dateTimeLabelFormats: {
                          millisecond: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                          second: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                          minute: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                          hour: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                          day: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                          week: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                          month: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                          year: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M']
                      }
                  },
  
                  marker: {
                    symbol: 'circle',
                    states: {
                        hover: {
                            fillColor: 'white',
                            lineColor: 'white',
                            lineWidth: 3
                        }
                    }
                  },
  
                  shadow: true,
                  tooltip: {
                      xDateFormat: '%A, %e. %B, %Y %H:%M:%S',
                      pointFormat: '<p style="font-size:11px;">Durchlaufzeit: <b>{point.y}</b><br/>',		// Tooltip um Errorcode erweitern (Wird nur in grÃ¶ÃŸter Zoomstfe angezeigt)
                      valueSuffix: ' ' + graphEinheit,
                      valueDecimals: 2,
                      backgroundColor: '#FCFFC5',
                      borderColor: 'black',
                      borderRadius: 10,
                      borderWidth: 60
                  },               
  
                  },
                  {
                    type: 'flags',
                    fillColor: "#5F86B3",
                    cursor: 'pointer',
                    name: 'Flags in pane',
                    data: result.prio,
                    onSeries: 'dataseries',
                    style: { // text style
                        color: 'white',
                    },
                    states: {
                        hover: {
                            fillColor: '#395C84', // darker
                            lineColor: 'white'
                        },
                        normal: {
                            fillColor: '#395C84', // darker
                            lineColor: 'white'
                        }
                    },
  
                    point: {
                        events: {
                            click: function () {
                                window.open(this.link, '_blank');
                            }
                        }
                    }
  
                  },
                  {
                    type: 'flags',
                    fillColor: "#d09344",
                    cursor: 'pointer',
                    name: 'Flags in pane',
                    data: result.deployments,
                    onSeries: 'dataseries',
                    style: { // text style
                        color: '#000000',
                    },
                    states: {
                        hover: {
                            fillColor: '#d09344', // darker
                            lineColor: '#000000'
                        },
                        normal: {
                            fillColor: '#d09344', // darker
                            lineColor: '#000000'
                        }
                    },
  
                    point: {
                        events: {
                            click: function () {
                                window.open(this.html_url, '_blank');
                            }
                        }
                    }
                  }
              ],
  
              credits: {
                  enabled: false
              },
  
              accessibility: {
                enabled: false
              },
  
              exporting: {
                  enabled: false
              }
            });
  
            setIsLoaded(true); 
        }
  
        fetchRecords().catch(function (error) {
          if (error.response) {
            setHasError(true)
            console.log("ERROR! Request made and server responded")
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            setHasError(true)
            console.log("ERROR! The request was made but no response was received")
            console.log(error.request);
          } else {
            setHasError(true)
            console.log("ERROR! Something happened in setting up the request that triggered an Error")
            console.log('Error', error.message);
          }
        })        
    }

    /***
     *     __   __            __   ___ ___       __
     *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ |
     *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \|
     *
     */

    return (
    <>
        <div className="synmonAnalyseWrap">
            <div className="synmonAnalyseContent">
                <div className="synmonAnalyseContent--Header">
                    <div className="synmonAnalyseContent--Header--Title">
                        Synthetisches Monitoring Fehleranalyse
                    </div>
                    <div className="synmonAnalyseContent--Header--CloseBtn">
                    <FontAwesomeIcon
                        className="synmonAnalyseContent--Header--Close--Icon"
                        icon="fa-regular fa-rectangle-xmark"
                        onClick={() => props.handleToggleSynmonAnalyse()}
                    />
                    </div>
                </div>
                <div className="synmonAnalyseContent--Content">
                    <div className="synmonAnalyseContent--TopWrap">
                        <div className="synmonAnalyseContent--LeftWrap">
                            <div className="synmonAnalyseContent--Top">
                            {
                                (() => {
                                    if (errorsIsLoaded === false)
                                        return(
                                            <div className="productWidget--LoadingWrap">
                                                <p className="productWidget--LoadingWrapText">Lade Daten</p>
                                                <p><PropagateLoader color={'#6f7277'} size={4} /></p>
                                            </div> 
                                        )
                                    if (errorsHasError === true)
                                        return <div className="productWidget--ErrorWrap">Fehlerhafte Daten</div>
                                    if (latestErrors.length <= 0)
                                        return <div className="productWidget--ErrorWrap">Keine Fehler gefunden</div>
                                    else 
                                        return(
                                            <>
                                                <div className="synmonAnalyseContent--Top-Product">{props.data.data.productName} | {props.data.data.testName}</div>                                
                                                <div className="synmonAnalyseContent--Top-Date">
                                                    <div className="synmonAnalyseContent--Top-Date-Label">Fehler Zeitpunkt:</div>
                                                    <div className="synmonAnalyseContent--Top-Date-Content">{latestErrors.data[selectedErrorIndex.current].created}</div>
                                                </div>
                                                <div className="synmonAnalyseContent--Top-Error">{latestErrors.data[selectedErrorIndex.current].errorShort}</div>
                                            </>
                                        )
                                })()
                            }
                            </div>
                            <div className="synmonAnalyseContent--Bottom">
                            {
                                (() => {
                                    if (hdImageIsLoaded === false)
                                        return(
                                            <div className="productWidget--LoadingWrap">
                                                <p className="productWidget--LoadingWrapText">Lade Daten</p>
                                                <p><PropagateLoader color={'#6f7277'} size={4} /></p>
                                            </div> 
                                        )
                                    if (hdImageHasError === true)
                                        return <div className="productWidget--ErrorWrap">Fehlerhafte Daten</div>
                                    if (hdImage.length <= 0)
                                        return <div className="productWidget--ErrorWrap">Keine HD Screen vorhanden</div>                                        
                                    else 
                                        return(
                                            <>
                                            {hdImage.length <= 0 || hdImage.data.length <= 0 ? 
                                                <>  
                                                    <div className="productWidget--ErrorWrap">Kein Fehler Screenshot vorhanden</div>
                                                </> 
                                                : 
                                                <>  <div className="synmonAnalyseContent--Bottom-Title">Fehler Screenshot</div>
                                                    <div className="synmonAnalyseContent--Bottom-Image"><img src={hdImage.data} alt="" className="hdImageStyle"></img></div>
                                                </>
                                            }
                                            </>
                                        )
                                })()
                            }
                            </div>
                        </div>
                        <div className="synmonAnalyseContent--RightWrap">
                            <div className="synmonAnalyseContent--Top-Right">
                                <div className="synmonAnalyseContent--Top-Title">Fehler zur Analyse auswählen:</div>
                                <div className="synmonAnalyseContent--Top-Thumbnails">
                                {
                                    (() => {
                                        if (errorsIsLoaded === false)
                                            return(
                                                <div className="productWidget--LoadingWrap">
                                                    <p className="productWidget--LoadingWrapText">Lade Daten</p>
                                                    <p><PropagateLoader color={'#6f7277'} size={4} /></p>
                                                </div> 
                                            )
                                        if (errorsHasError === true)
                                            return <div className="productWidget--ErrorWrap">Fehlerhafte Daten</div>
                                        if (latestErrors.length <= 0)
                                            return <div className="productWidget--ErrorWrap">Keine Fehler gefunden</div>                                                                                    
                                        else 
                                            return(
                                                latestErrors.data.map((position, index) => {                                                    
                                                        
                                                        let thumbnail = ""

                                                        if(position.image.length > 0){
                                                            thumbnail = position.image                                                            
                                                        } else {
                                                            thumbnail = noThumbnail
                                                        }

                                                        if(index === selectedErrorIndex.current){
                                                            return(
                                                                <div key={position._id} className="synmonAnalyseContent--Top-ThumbnailWrap synmonAnalyseContent--Top-ThumbnailWrap-Active" onClick={() => switchError(index)}>
                                                                    <div className="synmonAnalyseContent--Top-ThumbnailImage"><img src={thumbnail} alt="" className="thumbnailImageStyle"></img></div>
                                                                    <div className="synmonAnalyseContent--Top-ThumbnailDate">{position.created}</div>
                                                                </div>                       
                                                            )
                                                        } else {
                                                            return(
                                                                <div key={position._id} className="synmonAnalyseContent--Top-ThumbnailWrap" onClick={() => switchError(index)}>
                                                                    <div className="synmonAnalyseContent--Top-ThumbnailImage"><img src={thumbnail} alt="" className="thumbnailImageStyle"></img></div>
                                                                    <div className="synmonAnalyseContent--Top-ThumbnailDate">{position.created}</div>
                                                                </div>                       
                                                            )                                                            
                                                        }                                

                                                })
                                            )
                                    })()
                                }
                                </div>                                
                            </div>
                            <div className="synmonAnalyseContent--Bottom">
                            {
                                (() => {
                                    if (okIsLoaded === false)
                                        return(
                                            <div className="productWidget--LoadingWrap">
                                                <p className="productWidget--LoadingWrapText">Lade Daten</p>
                                                <p><PropagateLoader color={'#6f7277'} size={4} /></p>
                                            </div> 
                                        )
                                    if (okHasError === true)
                                        return <div className="productWidget--ErrorWrap">Fehlerhafte Daten</div>
                                    if (okData.length <= 0)
                                        return <div className="productWidget--ErrorWrap">Keine HD Screen vorhanden</div>                                                                                
                                    else 
                                        return(
                                            <>
                                            {okData.data.length <= 0 || okData.data.image.length <= 0 ? 
                                                <>  
                                                    <div className="productWidget--ErrorWrap">Kein OK Screenshot vorhanden</div>
                                                </> 
                                                : 
                                                <>  <div className="synmonAnalyseContent--Bottom-Title2">Letzter Screenshot mit Status OK | {okData.data.created}</div>
                                                    <div className="synmonAnalyseContent--Bottom-Image"><img src={okData.data.image} alt="" className="hdImageStyle"></img></div>
                                                </>
                                            }
                                            </>
                                        )
                                })()
                            }                                
                            </div>
                        </div>                        
                    </div>
                    <div className="synmonAnalyseContent--BottomWrap">
                        {
                            (() => {
                                if (graphIsShown === false)
                                    return(
                                        <button className="synmonAnalyseContent--LoadGrapnBTN" onClick={() => showDetailGraph()}>Detail Graphen laden</button>
                                    )
                                if (isLoaded === false)
                                    return(
                                        <div className="productWidget--LoadingWrap">
                                            <p className="productWidget--LoadingWrapText">Lade Daten</p>
                                            <p><PropagateLoader color={'#6f7277'} size={4} /></p>
                                        </div> 
                                    )
                                if (hasError === true)
                                    return <div className="productWidget--ErrorWrap">Fehlerhafte Daten</div>
                                else 
                                    return(
                                        <>
                                            <div className="synmonAnalyseContent--BottomWrap-Graph">
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    constructorType={'stockChart'}
                                                    options={options}
                                                    allowChartUpdate={true}
                                                    containerProps={{ style: { height: "100%" } }}
                                                />
                                            </div>
                                        </>
                                    )
                            })()
                        }                         
                    </div>
                </div>
            </div>
        </div>    
    </>  
    )
}

export default memo(SynmonAnalyse);


