import React, { useEffect, useState, useRef } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import ProductOverviewWidget from "./ProductOverviewWidget";
import GraphContainer from "./GraphContainer";
import CloudflareWidget from "./CloudflareWidget";
import ProductStatus from "./ProductStatus";
import MauiWidget from "./MauiWidget";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "../styles/Content.css";
import _ from "lodash";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DashboardSystemWithHooks = ({
  rowHeight = 60,
  draggableCancel = ".cancelDrag",
  ...props
  }) => {

    /***
     *     __  ___      ___  ___  __  
     *    /__`  |   /\   |  |__  /__` 
     *    .__/  |  /~~\  |  |___ .__/ 
     *                                
     */

  //const [layouts, setLayouts] = useState(originalLayouts);

  const originalItems = props.activeWidgets;
  const lastDashboardID = useRef();
  const [items, setItems] = useState([]);
  const [breakpoints, setBreakpoints] = useState({
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0
  });
  const [cols, setCols] = useState({ lg: 13, md: 10, sm: 6, xs: 4, xxs: 2 });
  const [count, setCount] = useState(props.dashboardCount);
  const firstLoad = useRef(true);
  const productoverviewIsThere = useRef(false);
  const graphcontainerIsThere = useRef(false);


    /***
     *          __   ___     ___  ___  ___  ___  __  ___  __  
     *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__` 
     *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/ 
     *                                                        
     */

  useEffect(() => {
    
    /**
     * Setzt die Widgets so wie es in den Props übergeben wurde. Die Component würde nicht laden, wenn dieses Prob nicht vorhanden wäre.
    */
    if (firstLoad.current && props.productData !== undefined) {

      firstLoad.current = false;    
      lastDashboardID.current = props.shownDashboardID;
      setItems(props.activeWidgets)

    } 

  }, [props]);

  React.useEffect(() => {
    onAddItem(props.addWidgetType)
  }, [props.addWidgetType]);


    /***
     *     ___            __  ___    __        __  
     *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
     *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
     *                                             
     */

  const createElement = (el) => {
       
    let string = el.i
    let subBefore= string.substring(0, string.indexOf('_'));

    /************************************************************
     * Produkt Overwiev Widget erstellen 
     ************************************************************/
    if(subBefore === "productoverview"){

      return (
          <div
              className="reactGridItem gridItemCustomLook"
              key={el?.i}
              data-grid={{x: el?.x, y: el?.y, w: el?.w, h: el?.h, i: el.i, minW: el?.minW, maxW: el?.maxW, minH: el?.minH, maxH: el?.maxH, isDraggable: el?.drag, isResizable: el?.resize,}}
          >                                    
              <div className="widgetContentWrap">
                  <ProductOverviewWidget 
                      id={el.i}
                      handleDelete={onRemoveItem}
                      handleAddGraph={props.handleAddGraph}
                      handleAddAllGraphs={props.handleAddAllGraphs}
                      productData={props.productData}
                      handleNotify={props.handleNotify}
                      syncOptionsArray={props.syncOptionsArray}
                      savedOptionsArray={props.savedOptionsArray}
                      receiveIDToken={props.receiveIDToken}
                  />
              </div>

          </div>
          );

  } else if(subBefore === "cloudflarewidget"){   
    /************************************************************
     * Cloudflare Widget erstellen 
     ************************************************************/
      return (
          <div
              className="reactGridItem gridItemCustomLook"
              key={el?.i}
              data-grid={{x: el?.x, y: el?.y, w: el?.w, h: el?.h, i: el.i, minW: el?.minW, maxW: el?.maxW, minH: el?.minH, maxH: el?.maxH, isDraggable: el?.drag, isResizable: el?.resize,}}
          >                                    
              <div className="widgetContentWrap">
                  <CloudflareWidget 
                      id={el.i}
                      handleDelete={onRemoveItem}
                      handleNotify={props.handleNotify}
                      syncOptionsArray={props.syncOptionsArray}
                      savedOptionsArray={props.savedOptionsArray}
                      receiveIDToken={props.receiveIDToken}
                  />
              </div>

          </div> 
      );         


  } else if(subBefore === "mauiwidget"){   
    /************************************************************
     * Maui Widget erstellen 
     ************************************************************/
      return (
          <div
              className="reactGridItem gridItemCustomLook"
              key={el?.i}
              data-grid={{x: el?.x, y: el?.y, w: el?.w, h: el?.h, i: el.i, minW: el?.minW, maxW: el?.maxW, minH: el?.minH, maxH: el?.maxH, isDraggable: el?.drag, isResizable: el?.resize,}}
          >                                    
              <div className="widgetContentWrap">
                  <MauiWidget 
                      id={el.i}
                      handleDelete={onRemoveItem}
                      handleNotify={props.handleNotify}
                      syncOptionsArray={props.syncOptionsArray}
                      savedOptionsArray={props.savedOptionsArray}
                      receiveIDToken={props.receiveIDToken}
                  />
              </div>

          </div> 
      );         


  }  else if(subBefore === "graphcontainer"){   
    /************************************************************
     * Graph Container Widget erstellen 
     ************************************************************/
      return (
        <div
            className="reactGridItem gridItemCustomLook"
            key={el?.i}
            data-grid={{x: el?.x, y: el?.y, w: el?.w, h: el?.h, i: el.i, minW: el?.minW, maxW: el?.maxW, minH: el?.minH, maxH: el?.maxH, isDraggable: el?.drag, isResizable: el?.resize,}}
        >                                    
            <div className="widgetContentWrap">
                <GraphContainer 
                    id={el.i}
                    visibleGraphs={props.visibleGraphs}
                    handleDelete={onRemoveItem}
                    handleDeleteGraph={props.handleDeleteGraph}
                    handleDeleteAllGraphs={props.handleDeleteAllGraphs}
                    handleChangeGraphOptions={props.handleChangeGraphOptions}
                    handleGraphDefaultTimerange={props.handleGraphDefaultTimerange}
                    handleToggleSynmonAnalyse={props.handleToggleSynmonAnalyse}
                    replaceGraph={props.replaceGraph}
                    handleNotify={props.handleNotify}
                    syncOptionsArray={props.syncOptionsArray}
                    receiveIDToken={props.receiveIDToken}
                    receiveAccessToken={props.receiveAccessToken}
                    userName={props.userName}
                />
            </div>

        </div>
        );                           

  }  else if(subBefore === "productstatus"){   
    /************************************************************
     * Simplified Product Widget erstellen 
     ************************************************************/
      return (
        <div
            className="reactGridItem gridItemCustomLook"
            key={el?.i}
            data-grid={{x: el?.x, y: el?.y, w: el?.w, h: el?.h, i: el.i, minW: el?.minW, maxW: el?.maxW, minH: el?.minH, maxH: el?.maxH, isDraggable: el?.drag, isResizable: el?.resize,}}
        >                                    
              <div className="widgetContentWrap">
                  <ProductStatus 
                      id={el.i}
                      handleDelete={onRemoveItem}
                      handleNotify={props.handleNotify}
                      syncOptionsArray={props.syncOptionsArray}
                      savedOptionsArray={props.savedOptionsArray}
                      receiveIDToken={props.receiveIDToken}
                      productData={props.productData}

                  />
              </div>

        </div>
        );                           

  }

  };

  /**
   * Sobald der User Änderungen an der Position oder Größe eines Widgets vornimmt, wird diese Funktion ausgeführt
   * @param {*} _items 
   */
  const onLayoutChange = (_items) => {    
    props.syncVisibleWidgets(_items);
  };

  // We're using the cols coming back from this to calculate where to add new items.
  // TODO: schauen ob wirklich benötigt
  const onBreakpointChange = (breakpoint, cols) => {
    //console.log(`breakpoint hit`);
    setBreakpoints(breakpoints);
    setCols(cols);
  };

  /**
   * Hinzufügen eines neuen Widgets, welches bisher nicht im Dashboard vorhanden war
   * @param {*} itemType 
   */
  const onAddItem = (itemType) => {
    /*eslint no-console: 0*/
    
    let widgetToAdd = null;

    if(itemType !== undefined){

      switch(itemType){
        case 'productoverview':
          widgetToAdd = { i: "productoverview_default", x: 0, y: 0, w: 4, lgw: 6, minW: 4, maxW: 11, h: 25, lgh: 40, minH: 10, maxH: 500, drag: true, resize: true }
        break;
        case 'graphcontainer':
          widgetToAdd = { i: "graphcontainer_" + (Math.floor(Math.random() * 10000000)), x: 4, y: 0, w: 18, lgw: 6, minW: 6, maxW: 22, h: 25, lgh: 10, minH: 10, maxH: 500, drag: true, resize: true}
        break;
        case 'cloudflarewidget':
          widgetToAdd = { i: "cloudflarewidget_" + (Math.floor(Math.random() * 10000000)), x: 4, y: 0, w: 10, lgw: 6, minW: 10, maxW: 22, h: 25, lgh: 10, minH: 10, maxH: 500, drag: true, resize: true}
        break;
        case 'mauiwidget':
          widgetToAdd = { i: "mauiwidget_" + (Math.floor(Math.random() * 10000000)), x: 27, y: 1, w: 12, lgw: 6, minW: 12, maxW: 22, h: 25, lgh: 10, minH: 10, maxH: 500, drag: true, resize: true}
        break;
        case 'productstatus':
          //widgetToAdd = { i: "productstatus_" + (Math.floor(Math.random() * 10000000)), x: 27, y: 1, w: 12, lgw: 6, minW: 3, maxW: 22, h: 25, lgh: 10, minH: 10, maxH: 500, drag: true, resize: false}
          widgetToAdd = { i: "productstatus_" + (Math.floor(Math.random() * 10000000)), x: 27, y: 1, w: 22, lgw: 6, minW: 22, maxW: 22, h: 25, lgh: 10, minH: 25, maxH: 500, drag: true, resize: true}
          break;                    
        default:
          // Do nothing
      }

      if(itemType === "productoverview" || itemType === "graphcontainer" || itemType === "cloudflarewidget" || itemType === "mauiwidget" || itemType === "productstatus"){
      
        let checkIfAlreadyExist = null
        checkIfAlreadyExist = items.find(e => e.type === itemType);
  
        if(checkIfAlreadyExist !== undefined){
          props.handleNotify({type: 'info', message: 'Es kann nur ein Widget dieses Typs im Dashboard vorhanden sein.'})
        } else {
          const addToCount = count + 1;
          setCount(addToCount);
          setItems((previous) => [
            ...previous,
            widgetToAdd
          ]);
          props.handleNotify({type: 'success', message: 'Widget successfully added!'})
        }          
      }
    }    
  };

  /**
   * Entfernen eines Widget vom Dashboard
   * Hierzu wird im item State das Element direkt entfernt
   * @param {*} i 
   */
  const onRemoveItem = (i) => {
    console.log("removing", i);
    const filtered = items.filter((item) => item.i !== i);
    setItems(items.filter((item) => item.i !== i));
    props.handleDeleteFromOptions(i);
  };


    /***
     *     __   __            __   ___ ___       __       
     *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
     *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
     *                                                    
     */

  return (
    <div>
      <ResponsiveReactGridLayout
        className="layout"
        cols={{ lg: 22, md: 8, sm: 4, xs: 2, xxs: 2 }}
        rowHeight={15}
        //layouts={{ breakpoints }}
        onLayoutChange={onLayoutChange}
        draggableCancel=".cancelDrag"
        draggableHandle=".drag-handle"
        onBreakpointChange={onBreakpointChange}
        {...props}
      >
        {items?.map((el) => createElement(el))}
      </ResponsiveReactGridLayout>
    </div>
  );
};


export default DashboardSystemWithHooks;
