import { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

import AppWrap from './App';

function Auth({ pca }) {
    return (
      <ClientSideNavigation pca={pca}>
        <MsalProvider instance={pca}>
              <Pages />
        </MsalProvider>
      </ClientSideNavigation>
    );
  }
  
  /**
   *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
   */ 
  function ClientSideNavigation({ pca, children }) {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);
  
    // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
    const [firstRender, setFirstRender] = useState(true);
    useEffect(() => {
      setFirstRender(false);
    }, []);
  
    if (firstRender) {
      return null;
    }
  
    return children;
  }
  
  function Pages() {
    return (
      <Routes>
        <Route path="/" element={<AppWrap />} />
      </Routes>
    )
  }
  
  export default Auth;
  