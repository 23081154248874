// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        "_id": "63da2e37814fe3b185af2ca4",
        "userId": "63da2da7400a87b39c8aea93",
        "name": "Default Dashboard",
        "widgets": [
            {
                "i": "widget1",
                "x": 0,
                "y": 0,
                "w": 2,
                "h": 2
            },
            {
                "i": "widget2",
                "x": 0,
                "y": 0,
                "w": 2,
                "h": 2
            },
            {
                "i": "widget3",
                "x": 0,
                "y": 0,
                "w": 2,
                "h": 2
            },
            {
                "i": "widget4",
                "x": 0,
                "y": 0,
                "w": 2,
                "h": 2
            }
        ]
    }
]