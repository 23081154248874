import axios from "axios";

const BACKEND_SVC = process.env.REACT_APP_BACKEND_SVC;

export const setupAPI = axios.create({
  baseURL: `${BACKEND_SVC}/ts/user/setup`,
  headers: {
    "Content-type": "application/json"
  }
});

export const timeseriesAPI = axios.create({
  baseURL: `${BACKEND_SVC}/ts/products`,
  headers: {
    "Content-type": "application/json"
  }
});

export const graphPrioEventsAPI = axios.create({
  baseURL: `${BACKEND_SVC}/ts/products`,
  headers: {
    "Content-type": "application/json"
  }
});

export const graphDeploymentEventsAPI = axios.create({
  baseURL: `${BACKEND_SVC}/ts/products`,
  headers: {
    "Content-type": "application/json"
  }
});

export const cloudflareZonesAPI = axios.create({
  baseURL: `${BACKEND_SVC}/ts/graphql/cloudflare/zones`,
  headers: {
    "Content-type": "application/json"
  },
  params: {
  }
});

export const cloudflareEventsAPI = `${BACKEND_SVC}/ts/graphql/cloudflare/firewallevents`

export const productStaticAPI = axios.create({
  baseURL: `${BACKEND_SVC}/ts/products`,
  headers: {
    "Content-type": "application/json"
  }
});

export const downtimeOverviewAPI = axios.create({
  baseURL: `${BACKEND_SVC}/ts/downtimes`,
  headers: {
    "Content-type": "application/json"
  }
});

export const availableDashboardsAPI = axios.create({
  baseURL: `${BACKEND_SVC}/ts/dashboards`,
  headers: {
    "Content-type": "application/json"
  }
});

export const mauiServices = axios.create({
  baseURL:  `${BACKEND_SVC}/ts/maui/data`,
  headers: {
    "Content-type": "application/json"
  }
});


export const createTicketAPI = axios.create({
  baseURL:  `${BACKEND_SVC}/ts/jira/issues`,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
  headers: {
    "Content-type": "application/json"
  }  
});

