import React, {memo, useRef} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Tooltip } from 'react-tooltip';
import SingleGraph from './SingleGraph'
import '../styles/GraphContainer.css';

//export function GraphContainer(props){
const GraphContainer = (props) => {
    //console.log(props)
    /***
     *     __  ___      ___  ___  __  
     *    /__`  |   /\   |  |__  /__` 
     *    .__/  |  /~~\  |  |___ .__/ 
     *                                
     */

    const [configMenuStatus, setConfigMenuStatus] = React.useState(false); 
    const [drawnGraphs, setdrawnGraphs] = React.useState([])
    const [isLoaded, setIsLoaded] = React.useState(false);                                       // Indikator ob Daten geladen wurden.       
    const [hasError, setHasError] = React.useState(false);                                       // Indikator ob Fehler vorliegen
    const [showPrioFlags, setShowPrioFlags] = React.useState(true)                              // Indikator ob die Priostörungs Flags auf den Graphen mit angezeigt werden sollen
    const [showDeploymentFlags, setShowDeploymentFlags] = React.useState(true)                  // Indikator ob die Deployment Flags auf den Graphen mit angezeigt werden sollen

    /***
     *          __   ___     ___  ___  ___  ___  __  ___  __  
     *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__` 
     *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/ 
     *                                                        
     */
    
    React.useEffect(() => {
       //console.log("Use Effect is called")
        try{
            // Prüfen ob der Graph bereits vorhanden ist
            props.visibleGraphs.forEach(function(element) {   
                
                const visibleGraphsIndex = drawnGraphs.findIndex((searchedItem) => searchedItem.testID === element.testID);
    
                if(visibleGraphsIndex === -1){
                    //console.log("Add Graph from test: " + element.testID + " Product: " + element.productID + " ProductName: " + element.productName + " TestName: " + element.displayName)
                    
                    const newGraph = {
                        testID: element.testID,
                        productID: element.productID,
                        productName: element.productName,
                        testName: element.displayName,
                        thresholds: element.thresholds
                      };
    
                    setdrawnGraphs(prev => [
                        ...prev,
                        newGraph
                    ])
                    
                    //props.handleNotify({type: 'success', message: "Graph hinzugefügt"})  
                }
            }) 

            // Prüfen ob Graphen entfernt wurden
            drawnGraphs.forEach(function(element) {
                
                const toDeleteIndex = props.visibleGraphs.findIndex((searchedItem) => searchedItem.testID === element.testID);
                if(toDeleteIndex === -1){
                    setdrawnGraphs(oldValues => {
                        return oldValues.filter(obj => obj.testID !== element.testID)
                      })
                }
            })
            props.syncOptionsArray(props.id, "none")
            setIsLoaded(true)    
        }
        catch (error){
            setHasError(true)
            props.handleNotify({type: 'alert', message: error})     
        }
        
    }, [props.visibleGraphs]);    
    
    /***
     *     ___            __  ___    __        __  
     *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
     *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
     *                                             
     */

    const toggleConfigMenuStatus = () => {
        setConfigMenuStatus(!configMenuStatus)
        //props.syncOptionsArray(props.id, "none")
    }

    const toggleShowPrioFlags = () => {
        setShowPrioFlags(!showPrioFlags)
    }    

    const toggleShowDeploymentFlags = () => {
        setShowDeploymentFlags(!showDeploymentFlags)
    }   
    
    const checkboxRef = useRef(null)

    const gatherOptions = (timerange) => {
        
        let timerangeValue;

        if(timerange !== undefined){
            timerangeValue = timerange
            refreshAllGraphs();
        } else {
            timerangeValue = props.handleGraphDefaultTimerange
        }
        
        const options = {
            replaceGraph: checkboxRef.current.checked,
            timerange: timerangeValue
        }

        props.handleChangeGraphOptions(options);
    }

    const refreshAllGraphs = () => {
        const previousDrawnGraph = drawnGraphs;
        setdrawnGraphs([])
        setIsLoaded(false)
        setTimeout(() => {
            setIsLoaded(true)
            setdrawnGraphs(previousDrawnGraph)
          }, 200);
        
    }

    /***********************************************************************************
     * DOM für das Konfigurationsmenü generieren 
     ***********************************************************************************/
    const FillConfigMenu = () => {
        //console.log(visibleTests)
        return(
        <>
            <div className="productWidget--configMenuWrap">
                <div className="productWidget--HeaderWrap drag-handle">
                    <div className="productWidgetHeader--Title">Performancegraphen - Konfiguration</div>
                    <button
                        className="graphContainer--MenuBtn"
                        onClick={() => toggleConfigMenuStatus()}
                    >
                        <FontAwesomeIcon icon="fa-regular fa-rectangle-xmark" />
                    </button>
                </div>
                <div className="graphContainerConfig--ContentWrap">
                
                        <div className="graphContainerConfig--HeaderWrap">
                            <div className="productWidgetConfigContent--HeaderTitle">Widget Optionen</div>
                            <div className="productWidgetConfigContent--LineBreak"></div>
                            <div className="productWidgetConfigContent--HeaderSubTitle"></div>
                        </div>

                        <div className="graphContainerConfig--OptionWrap">
                            <div className="productWidgetConfigContent--Title">Immer nur einen Graph anzeigen: </div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    {/*<input type="checkbox" onChange={() => props.handleToggleReplaceGraph()} checked={props.replaceGraph ? "checked" : ""}></input>*/}
                                    <input type="checkbox" ref={checkboxRef} onChange={() => gatherOptions()} checked={props.replaceGraph ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>
                                               
                        <div className="graphContainer--LineBreak"></div>
                        <div className="graphContainerConfigContent--Title">Default Zeitraum für die Graphen auswählen: </div>
                        <div className="graphContainer--configTimerangeContainer">
                            {(() => {
                                if (props.handleGraphDefaultTimerange === '6h') {
                                return (
                                    <>
                                        <button className="cloudflareConfigBtn clousflareConfigActive" onClick={() => gatherOptions('6h')}>6 Stunden</button>                                                                  
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('12h')}>12 Stunden</button>                                                                  
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('1d')}>1 Tag</button>                                                                  
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('1w')}>1 Woche</button>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('3w')}>3 Wochen</button>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('all')}>Gesamt</button>
                                    </>
                                )
                                } else if (props.handleGraphDefaultTimerange === '12h') {
                                return (
                                    <>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('6h')}>6 Stunden</button>                                                                  
                                        <button className="cloudflareConfigBtn clousflareConfigActive" onClick={() => gatherOptions('12h')}>12 Stunden</button>                                                                  
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('1d')}>1 Tag</button>                                                                  
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('1w')}>1 Woche</button>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('3w')}>3 Wochen</button>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('all')}>Gesamt</button>
                                    </>
                                )
                                } else if (props.handleGraphDefaultTimerange === '1d') {
                                return (
                                    <>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('6h')}>6 Stunden</button>                                                                  
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('12h')}>12 Stunden</button>                                                                  
                                        <button className="cloudflareConfigBtn clousflareConfigActive" onClick={() => gatherOptions('1d')}>1 Tag</button>                                                                  
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('1w')}>1 Woche</button>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('3w')}>3 Wochen</button>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('all')}>Gesamt</button>
                                    </>
                                )
                                } else if (props.handleGraphDefaultTimerange === '1w') {
                                return (
                                    <>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('6h')}>6 Stunden</button>                                                                  
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('12h')}>12 Stunden</button>                                                                  
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('1d')}>1 Tag</button>                                                                  
                                        <button className="cloudflareConfigBtn clousflareConfigActive" onClick={() => gatherOptions('1w')}>1 Woche</button>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('3w')}>3 Wochen</button>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('all')}>Gesamt</button>
                                    </>
                                )                        
                                } else if (props.handleGraphDefaultTimerange === '3w') {
                                    return (
                                        <>
                                            <button className="cloudflareConfigBtn" onClick={() => gatherOptions('6h')}>6 Stunden</button>                                                                  
                                            <button className="cloudflareConfigBtn" onClick={() => gatherOptions('12h')}>12 Stunden</button>                                                                  
                                            <button className="cloudflareConfigBtn" onClick={() => gatherOptions('1d')}>1 Tag</button>                                                                  
                                            <button className="cloudflareConfigBtn" onClick={() => gatherOptions('1w')}>1 Woche</button>
                                            <button className="cloudflareConfigBtn clousflareConfigActive" onClick={() => gatherOptions('3w')}>3 Wochen</button>
                                            <button className="cloudflareConfigBtn" onClick={() => gatherOptions('all')}>Gesamt</button>
                                        </>
                                    )                        
                                } else if (props.handleGraphDefaultTimerange === 'all') {
                                    return (
                                        <>
                                            <button className="cloudflareConfigBtn" onClick={() => gatherOptions('6h')}>6 Stunden</button>                                                                  
                                            <button className="cloudflareConfigBtn" onClick={() => gatherOptions('12h')}>12 Stunden</button>                                                                  
                                            <button className="cloudflareConfigBtn" onClick={() => gatherOptions('1d')}>1 Tag</button>                                                                  
                                            <button className="cloudflareConfigBtn" onClick={() => gatherOptions('1w')}>1 Woche</button>
                                            <button className="cloudflareConfigBtn" onClick={() => gatherOptions('3w')}>3 Wochen</button>
                                            <button className="cloudflareConfigBtn clousflareConfigActive" onClick={() => gatherOptions('all')}>Gesamt</button>
                                        </>
                                    )                        
                                } else {
                                return (
                                    <>
                                        <button className="cloudflareConfigBtn clousflareConfigActive" onClick={() => gatherOptions('6h')}>6 Stunden</button>                                                                  
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('12h')}>12 Stunden</button>                                                                  
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('1d')}>1 Tag</button>                                                                  
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('1w')}>1 Woche</button>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('3w')}>3 Wochen</button>
                                        <button className="cloudflareConfigBtn" onClick={() => gatherOptions('all')}>Gesamt</button>
                                    </>
                                )
                                }
                            })()}
                        </div>
                </div>  
            
            
            <div className="graphContainerConfig--DeleteWrap">
                <button
                    className="productWidgetHeader--DeleteBtn"
                    onClick={() => props.handleDelete(props.id)}
                >
                    <FontAwesomeIcon icon="fa-solid fa-trash" /> Widget entfernen
                </button>
            </div>
            </div>
        </>
        )}  
    


    /***
     *     __   __            __   ___ ___       __       
     *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
     *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
     *                                                    
     */
    return(
        <>
            {configMenuStatus ?  <FillConfigMenu /> : null}
            <div className="productWidget--HeaderWrap drag-handle">
                <div className="productWidgetHeader--Title">Performancegraphen - Synthetisches Monitoring</div>
                <button
                    id={"tooltip-deleteAll"}
                    className="graphContainer--DeleteAllBtn"
                    onClick={() => props.handleDeleteAllGraphs()}
                >
                    <FontAwesomeIcon icon="fa-solid fa-trash" />
                </button>
                <Tooltip    
                    className="tooltipStyles"
                    anchorSelect={"#tooltip-deleteAll"} 
                    html={"Alle Graphen entfernen"} 
                    place="bottom" 
                    style={{ backgroundColor: "var(--hover-color)", color: "var(--text-color)", zIndex: "500" }}
                />
                <button
                    id={"tooltip-refreshAll"}
                    className="graphContainer--RefreshAllBtn"
                    onClick={() => refreshAllGraphs()}
                >
                    <FontAwesomeIcon icon="fa-solid fa-arrows-rotate" />
                </button>
                <Tooltip    
                    className="tooltipStyles"
                    anchorSelect={"#tooltip-refreshAll"} 
                    html={"Alle Graphen neu laden"} 
                    place="bottom" 
                    style={{ backgroundColor: "var(--hover-color)", color: "var(--text-color)", zIndex: "500" }}
                />                
                <button
                    className="graphContainer--MenuBtn"
                    onClick={() => toggleConfigMenuStatus()}
                >
                    <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                </button>
            </div>
            <div className="productWidget--ContentWrap">
            {
                (() => {
                    if (isLoaded === false)
                        return(
                            <div className="productWidget--LoadingWrap">
                                <p className="productWidget--LoadingWrapText">Lade Daten</p>
                                <p><PropagateLoader color={'#6f7277'} size={4} /></p>
                            </div> 
                        )
                    if (hasError === true)
                        return <div className="productWidget--ErrorWrap">Error</div>
                    if (drawnGraphs.length <= 0){
                        return <div className="productWidget--ErrorWrap">Zur Anzeige einen synthetischen Test in der Produktübersicht auswählen</div>
                    } else 
                        return(
                            drawnGraphs.map(originElement =>(
                                <SingleGraph 
                                    key={originElement.testID}
                                    testID={originElement.testID}
                                    productName={originElement.productName}
                                    testName={originElement.testName}
                                    productID={originElement.productID}
                                    handleDeleteGraph={props.handleDeleteGraph}
                                    handleToggleSynmonAnalyse={props.handleToggleSynmonAnalyse}
                                    handleNotify={props.handleNotify}
                                    thresholds={originElement.thresholds}
                                    showPrioFlags={showPrioFlags}
                                    showDeploymentFlags={showDeploymentFlags}
                                    receiveIDToken={props.receiveIDToken}
                                    handleGraphDefaultTimerange={props.handleGraphDefaultTimerange}
                                    userName={props.userName}
                                    receiveAccessToken={props.receiveAccessToken}
                                />
                            ))                            
                        )
                })()
            }
            </div>
        </>    
    )

}

export default memo(GraphContainer) 



/*
                        <div className="graphContainerConfig--OptionWrap">
                            <div className="productWidgetConfigContent--Title">Prio Störungen einblenden: </div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleShowPrioFlags()} checked={showPrioFlags ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>
                        <div className="graphContainerConfig--OptionWrap">
                            <div className="productWidgetConfigContent--Title">Deplyment Infos einblenden: </div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleShowDeploymentFlags()} checked={showDeploymentFlags ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>
*/