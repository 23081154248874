import React, {useCallback, memo} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Tooltip } from 'react-tooltip';
import { cloudflareEventsAPI } from "../http-common";                                                    // Deconstruct der benötigten API
import axios from "axios";

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import '../styles/SingleGraph.css';

// Globale Highcharts Optionen
Highcharts.setOptions({
  global: {
    useUTC: false
  },
  lang: {
    decimalPoint: ',',
    thousandsSep: '.',
    loading: 'Daten werden geladen...',
    months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    exportButtonTitle: "Exportieren",
    printButtonTitle: "Drucken",
    rangeSelectorFrom: "Von",
    rangeSelectorTo: "Bis",
    rangeSelectorZoom: "Zeitraum",
    downloadPNG: 'Download als PNG-Bild',
    downloadJPEG: 'Download als JPEG-Bild',
    downloadPDF: 'Download als PDF-Dokument',
    downloadSVG: 'Download als SVG-Bild',
    resetZoom: "Zoom zurÃ¼cksetzen",
    resetZoomTitle: "Zoom zurÃ¼cksetzen"
  }
});

const MiniGraph = (props) => {

    const endpointURL = "/"+ props.productID +"/tests/"+ props.testID +"/performance?grouped=true"
        
    /***
     *     __  ___      ___  ___  __  
     *    /__`  |   /\   |  |__  /__` 
     *    .__/  |  /~~\  |  |___ .__/ 
     *                                
     */
    const [isLoaded, setIsLoaded] = React.useState(false);                                       // Indikator ob Daten geladen wurden.       
    const [hasError, setHasError] = React.useState(false);                                       // Indikator ob Fehler vorliegen
    const [sumBlocked, setSumBlocked] = React.useState("-");                                      
    const [sumManaged, setSumManaged] = React.useState("-");                                      
    const [hasNoData, setHasNoData] = React.useState(false);
    const [options, setOptions] = React.useState(null);                                          // Options holds the options for the Highcharts
    
    
    /***
     *          __   ___     ___  ___  ___  ___  __  ___  __  
     *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__` 
     *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/ 
     *                                                        
     */
    
    React.useEffect(() => {
      setIsLoaded(false);        
      
      const fetchRecords = async () => {

        const idToken = props.receiveIDToken();
        const headers = { 'Authorization': idToken };
        const params = "timerange="+props.timerange+"&zoneTag="+props.zoneTag+"&forceRefresh=true"
    
          //const res = await cloudflareEventsAPI.get(params, {headers})
          //const res = await cloudflareEventsAPI.get("?timerange="+props.timerange+"&zoneTag="+props.zoneTag+"&forceRefresh=true", {headers})
          
          const res = await axios.get(cloudflareEventsAPI, {
            params: { timerange:props.timerange,
                      zoneTag:props.zoneTag,
                      forceRefresh:true
                    },
            headers:{
              Authorization: idToken
            }
          })
          

          .catch(function (error) {
            //console.log("Fetch error Cloudflare Graph events" +error)
            let obj = {
              data:  []
            };
            return (obj)
          })
          
          const result = {
              data: res.data.series
          };

          if(res.data.sum !== undefined){
            setSumBlocked(res.data.sum.blocked)
            setSumManaged(res.data.sum.managed)
          }
          
          var arrayLength = result['data'].length
          if(arrayLength <= 0){
            setHasNoData(true)
          }

          const graphTitel = null;
          const subTitel = null;            
          const graphEinheit = '';
          const PlotLabelColor = '#ccc';

          /// Setting the Highstock options variable
          setOptions({
            global: {
              useUTC: false
            },

            title: {
              text: null,
              style: {
                  color: 'white',
                  fontWeight: 'bold'
              }
            },

            subtitle: {
              text: null,
              style: {
                  color: 'white',
              }
            },

            scrollbar: {
              barBackgroundColor: 'var(--subtext-color)',
              barBorderRadius: 5,
              barBorderWidth: 0,
              buttonBackgroundColor: 'var(--subtext-color)',
              buttonBorderWidth: 0,
              buttonBorderRadius: 5,
              trackBackgroundColor: 'var(--hover-color)',
              trackBorderWidth: 0,
              trackBorderRadius: 6,
              trackBorderColor: '#CCC'
            },

            chart: {
              zoomType: 'x',
              backgroundColor: 'transparent',
              reflow: true              
            },

            yAxis: [{
              opposite:false,															// Linke Seite für die Label festlegen
              labels: {
                  enabled: false,
                  formatter: function() {
                      return this.value + ' ' + graphEinheit;							// übergebene Einheit
                  }
              },

              title: {
                  text: null
              },
            }],

            xAxis: [{
              gridLineWidth: 0,
              minorGridLineWidth: 0,
            }],
            navigator: {
              enabled: false
            },
            scrollbar: { 
                enabled: false 
            },            

            rangeSelector: {
              enabled: false
            },    

            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: false
                    },
                }
            },

            series: [
                {
                    name : '',
                    turboThreshold: 11000,
                    data: result.data,
                    id: 'dataseries',

                    zones: [{															// Festlegen in welchen Bereichen Alarmiert wird
                      //value: minCrit,														// Bei y-Wert bis minCrit = crit (rot)
                      //color: '#df002e'
                    },{
                        //value: warn,														// Bei y-Wert bis warn = warnung (gelb)
                        //color: '#84bc34'
                    }, {
                        //value: crit,														// Bei y-Wert ab crit = kritisch (rot)
                        //color: '#e6be00'
                    }, {
                        color: '#df002e'													// sonst (grün)
                    }],

                  dataGrouping: {
                    dateTimeLabelFormats: {
                        millisecond: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        second: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        minute: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        hour: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        day: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        week: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        month: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        year: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M']
                    }
                },

                marker: {
                  symbol: 'circle',
                  states: {
                      hover: {
                          fillColor: 'white',
                          lineColor: 'white',
                          lineWidth: 3
                      }
                  }
                },

                shadow: true,
                tooltip: {
                  xDateFormat: '%A, %e. %B, %Y %H:%M:%S',
                  pointFormat: 'Blocked: <b>{point.y}</b>',		// Tooltip um Errorcode erweitern (Wird nur in größter Zoomstfe angezeigt)
                  valueSuffix: ' ' + graphEinheit,
                  valueDecimals: 2,
                  backgroundColor: '#FCFFC5',
                  borderColor: 'black',
                  borderRadius: 10,
                  borderWidth: 60
                },               

                }
            ],

            credits: {
                enabled: false
            },

            accessibility: {
              enabled: false
            },

            exporting: {
                enabled: false
            }
          });

          setIsLoaded(true); 
      }
      
      fetchRecords().catch(function (error) {
        if (error.response) {
          setHasError(true)
          console.log("ERROR! Request made and server responded")
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          setHasError(true)
          console.log("ERROR! The request was made but no response was received")
          console.log(error.request);
        } else {
          setHasError(true)
          //console.log("ERROR! Something happened in setting up the request that triggered an Error")
          //console.log('Error', error.message);
        }
      })
       
    // Cleanup function on component dismount
    return () => {
      
    };

  }, []);
    
  /**
   * Funktion um den Resize des Parent DIV des Graphen zu detecten
   
  React.useEffect(() => {
      const observer = new ResizeObserver(([entry]) => {
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.height);
        onLayoutChange();
      });
      observer.observe(ref.current);
      onLayoutChange();
      return () => {
        observer.disconnect();
      };
    }, []);      
      
    /***
     *     ___            __  ___    __        __  
     *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
     *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
     *                                             
     */
    const onLayoutChange = () => {
        //console.log("Layout changed!")
        for (let i = 0; i < Highcharts.charts.length; i += 1) {
          if (Highcharts.charts[i] !== undefined) {
            Highcharts.charts[i].reflow(); // here is the magic to update charts' looking
          }
        }
    };
   
    const openNewWebtab = (url) => {
      console.log("Open: " + url)
      window.open(url, '_blank').focus();
  }

    /***
     *     __   __            __   ___ ___       __       
     *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
     *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
     *                                                    
     */

    return(
      <>
        <div className="cloudflareGraphWidgetContainer">
            <div className="cloudflareGraphContainerHeader">
                <div className="cloudflareGraphContainerHeaderCaption">{props.name}</div>
                <div className="cloudflareGraphContainerHeaderSUM">
                    <div title="Blocked" className="cloudflareSUM"><FontAwesomeIcon icon="fa-solid fa-ban" /> {sumBlocked}</div>
                    <div title="Managed" className="cloudflareSUM"><FontAwesomeIcon icon="fa-solid fa-shield-halved" /> {sumManaged}</div>
                </div>
                <div title="In Cloudflare anzeigen" id="cloudflareWidgetRefreshBTN" className="cloudflareGraphContainerHeaderBTN" onClick={() => openNewWebtab(props.link)}><FontAwesomeIcon icon="fa-solid fa-up-right-from-square" /></div>

            </div>
              <div className="cloudflareGraphContainerContent">
                {
                      (() => {
                          if (hasError === true)
                              return <div className="cloudflare--ErrorWrap">Fehler beim Laden der Graphdaten.</div>
                          else if (hasNoData === true)
                              return <div className="cloudflare--ErrorWrap">Im gewählten Zeitraum gibt es keine Events</div>
                          else if (isLoaded === false)
                              return(
                                <div className="productWidget--LoadingWrap">
                                    <p className="productWidget--LoadingWrapText">Lade Daten</p>
                                    <p><PropagateLoader color={'#6f7277'} size={4} /></p>
                                </div> 
                              )
                          else 
                              return(
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  constructorType={'stockChart'}
                                  options={options}
                                  allowChartUpdate={true}
                                  containerProps={{ style: { height: "100%" } }}
                                />
                              )
                      })()
                  }              
              </div>
          </div>
      </> 
            




          
 
    )

}

export default memo(MiniGraph) 