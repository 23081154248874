import React, { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Tooltip } from "react-tooltip";
import "../styles/MauiWidget.css";
import "react-tooltip/dist/react-tooltip.css";

import { mauiServices } from "../http-common";

import { useTable, useSortBy, useFilters } from "react-table";

/**
 * Tabelle für die Downtimeauswahl
 * @returns
 */
function Table({ data }) {
  /**
   * Input-Element für die Filterung der Tabelle
   *
   * @param {*} column
   * @returns
   */
  const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;
    return (
      <div className="maui-Table-SearchWrapper">
        <input
          className="maui-Table-SearchInput"
          value={filterValue || ""}
          onChange={(e) => setFilter(e.target.value)}
          onClick={(e) => e.stopPropagation()} // Hier wird das Klickereignis gestoppt
          placeholder="Suche..."
        />
        <div
          className="maui-Table-SearchReset"
          onClick={(e) => {
            e.stopPropagation(); // Hier wird das Klickereignis gestoppt
            setFilter("");
          }}
        >
          <FontAwesomeIcon icon="fa-solid fa-arrow-rotate-left" />
        </div>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Backend",
        accessor: "backend",
        Filter: ColumnFilter,
        sortType: (rowA, rowB, columnId) => {
          const valueA = rowA.values[columnId].toLowerCase();
          const valueB = rowB.values[columnId].toLowerCase();
          return valueA.localeCompare(valueB);
        },
      },
      {
        Header: "Purpose",
        accessor: "purpose",
        Filter: ColumnFilter,
        sortType: (rowA, rowB, columnId) => {
          const valueA = rowA.values[columnId].toLowerCase();
          const valueB = rowB.values[columnId].toLowerCase();
          return valueA.localeCompare(valueB);
        },
      },
      {
        Header: "Contact",
        accessor: "contact",
        Filter: ColumnFilter,
        sortType: (rowA, rowB, columnId) => {
          const valueA = rowA.values[columnId].toLowerCase();
          const valueB = rowB.values[columnId].toLowerCase();
          return valueA.localeCompare(valueB);
        },
      },
      {
        Header: "Technology",
        accessor: "technology",
        Filter: ColumnFilter,
        sortType: (rowA, rowB, columnId) => {
          const valueA = rowA.values[columnId].toLowerCase();
          const valueB = rowB.values[columnId].toLowerCase();
          return valueA.localeCompare(valueB);
        },
      },
      {
        Header: "Server",
        accessor: "server",
        Filter: ColumnFilter,
        sortType: (rowA, rowB, columnId) => {
          const valueA = rowA.values[columnId].toLowerCase();
          const valueB = rowB.values[columnId].toLowerCase();
          return valueA.localeCompare(valueB);
        },
      },
      {
        Header: "Port",
        accessor: "port",
        Filter: ColumnFilter,
        sortType: (rowA, rowB, columnId) => {
          const valueA = rowA.values[columnId].toLowerCase();
          const valueB = rowB.values[columnId].toLowerCase();
          return valueA.localeCompare(valueB);
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: ColumnFilter,
        sortType: (rowA, rowB, columnId) => {
          const valueA = rowA.values[columnId].toLowerCase();
          const valueB = rowB.values[columnId].toLowerCase();
          return valueA.localeCompare(valueB);
        },
      },
      {
        Header: "Test Duration",
        accessor: "testDuration",
        Filter: ColumnFilter,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useFilters, useSortBy);

  return (
    <table className="maui-table" {...getTableProps()}>
      <thead className="maui-table-head">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className="maui-tableHead"
                {...column.getHeaderProps(column.getSortByToggleProps())} // Hinzufügen der Sortierfunktion
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FontAwesomeIcon
                        className="mauiWidget-arrow"
                        icon="fa-solid fa-arrow-down"
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="mauiWidget-arrow"
                        icon="fa-solid fa-arrow-up"
                      />
                    )
                  ) : (
                    ""
                  )}
                </span>
                <div>{column.canFilter ? column.render("Filter") : null}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td className="maui-tableCell" {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const MauiWidget = (props) => {
  /***
   *     __  ___      ___  ___  __
   *    /__`  |   /\   |  |__  /__`
   *    .__/  |  /~~\  |  |___ .__/
   *
   */

  const [configMenuStatus, setConfigMenuStatus] = React.useState(false);

  const [widgetOptions, setWidgetOptions] = React.useState(() => {});

  const [optionHasChanged, setOptionHasChanged] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false); // Indikator ob Daten geladen wurden.
  const [hasError, setHasError] = React.useState(false); // Indikator ob Fehler vorliegen
  const [mauiDataState, setMauiDataState] = React.useState();


  const [initialLoad, setInitialLoad] = React.useState(true); // Neuer Zustand für das erste Laden
  
  /***
   *          __   ___     ___  ___  ___  ___  __  ___  __
   *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__`
   *    |__/ .__/ |___    |___ |    |    |___ \__,  |  .__/
   *
   */

  React.useEffect(() => {
    if (optionHasChanged === true) {
      //console.log("Option has changed in Productoverview")
      props.syncOptionsArray(props.id, widgetOptions);
      setOptionHasChanged(false);
    } else {
      //console.log("No Option has been changed in Productoverview")
    }
  }, [widgetOptions]);

  /***
   *     ___            __  ___    __        __
   *    |__  |  | |\ | /  `  |  | /  \ |\ | /__`
   *    |    \__/ | \| \__,  |  | \__/ | \| .__/
   *
   */

  const toggleConfigMenuStatus = () => {
    setConfigMenuStatus(!configMenuStatus);
  };

React.useEffect(() => {

  setIsLoaded(false)
  const fetchData = async () => {

    const idToken = props.receiveIDToken();
    const headers = { 'Authorization': idToken };

    const res = await mauiServices.get("", {headers});

    const result = {
      status: res.status + "-" + res.statusText,
      headers: res.headers,
      data: res.data,
    };

    if(res.data.status === 200){
      setMauiDataState(result.data.message);
      setIsLoaded(true);
      setInitialLoad(false); 
    } else {
      setIsLoaded(true);
      setHasError(true);
      setInitialLoad(false); 
    }

  };

  // call the function once use effect run
  fetchData().catch((error) => {
    const errorMsg = error.name + ": " + error.message;
    props.handleNotify({ type: "alert", message: errorMsg });
  });
}, [props]);


  /***********************************************************************************
   * DOM für das Konfigurationsmenü generieren
   ***********************************************************************************/
  const FillConfigMenu = () => {
    //console.log(widgetOptions.visibleTests)
    return (
      <div className="productWidget--configMenuWrap">
        <div className="productWidget--HeaderWrap drag-handle">
          <div className="productWidgetHeader--Title">
            Maui Widget - Konfiguration
          </div>
          <button
            className="productWidgetHeader--MenuBtn"
            onClick={() => toggleConfigMenuStatus()}
          >
            <FontAwesomeIcon icon="fa-regular fa-rectangle-xmark" />
          </button>
        </div>
        {/* Auskommentiert, solange keine Optionen in diesem Widget benötigt werden.
        <div className="productWidgetConfig--ContentWrap">
          <div className="productWidgetConfigContent--HeaderWrap">
            <div className="productWidgetConfigContent--HeaderTitle">
              Widget Optionen
            </div>
            <div className="productWidgetConfigContent--LineBreak"></div>
            <div className="productWidgetConfigContent--HeaderSubTitle"></div>
          </div>

          <div className="productWidgetConfigContent--ContentWrap"></div>

          <div className="productWidgetConfigContent--ProductContentWrap"></div>
        </div>
        */}
        <div className="productWidgetConfig--DeleteWrap">
          <button
            className="productWidgetHeader--DeleteBtn"
            onClick={() => props.handleDelete(props.id)}
          >
            <FontAwesomeIcon icon="fa-solid fa-trash" /> Widget entfernen
          </button>
        </div>
      </div>
    );
  };

  const openNewWebtab = (url) => {
    console.log("Open: " + url);
    window.open(url, "_blank").focus();
  };

  /***
   *     __   __            __   ___ ___       __
   *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ |
   *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \|
   *
   */

  return (
    <>
      {configMenuStatus ? <FillConfigMenu /> : null}
      <div className="productWidget--HeaderWrap drag-handle">
        <div className="mauiWidgetHeader--Title">Maui Services</div>
        <button
          className="productWidgetHeader--MenuBtn"
          onClick={() => toggleConfigMenuStatus()}
        >
          <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
        </button>
      </div>
      <div className="mauiWidget--ContentWrap">
        {(() => {
          if (isLoaded === false && initialLoad)
            return (
              <>
                <div className="productWidget--LoadingWrap">
                  <p className="productWidget--LoadingWrapText">Lade Daten</p>
                  <p>
                    <PropagateLoader color={"#6f7277"} size={4} />
                  </p>
                </div>
              </>
            );
          if (hasError === true)
            return <div className="productWidget--ErrorWrap">Die Maui Daten konnten nicht geladen werden.</div>;
          else
            return (
              <div className="mauiWidget-wrapper">
                <Table data={mauiDataState} />
              </div>
            );
        })()}
      </div>
    </>
  );
};

export default memo(MauiWidget);
