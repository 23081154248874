import React, {memo, useRef} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Tooltip } from 'react-tooltip';
import '../styles/ProductOverviewWidget.css';
import 'react-tooltip/dist/react-tooltip.css';

const ProductOverviewWidget = (props) => {
       
    //console.log(props)

    /***
     *     __  ___      ___  ___  __  
     *    /__`  |   /\   |  |__  /__` 
     *    .__/  |  /~~\  |  |___ .__/ 
     *                                
     */

    /*
    const [scrollPosition, setScrollPosition] = React.useState(0);
    const myRef = useRef(null);
 
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };
    
    React.useEffect(() => {
        if (myRef.current) {
          myRef.current.scrollTop = scrollPosition;
        }
      }, [scrollPosition]);
*/   
    const [configMenuStatus, setConfigMenuStatus] = React.useState(false);   
    const [productsWithErrors, setProductsWithErrors] = React.useState(0)
    const [toggleAllProductStatus, setToggleAllProductStatus] = React.useState(false)
    const widgetOptionsCopy = useRef({});
    const [widgetOptions, setWidgetOptions] = React.useState(() => {
        
        if(props.productData !== undefined && props.productData.length > 0){

            let usedIndex = -1

            props.savedOptionsArray.forEach(function(widget, index) {
                
                if(widget.i !== undefined){
                    let string = widget.i
                    let subBefore= string.substring(0, string.indexOf('_'));
                    
                    if(subBefore === "productoverview"){
                        usedIndex = index;
                    } 
                }
            })
            

            let tmpShowErrorOnly = false
            let tmpShowAWS = false
            let tmpShowSynthetic = true
            let tmpShowSyntheticGIT = true
            let tmpShowCheckMK = true
            let tmpShowGrafana = false
            let tmpShowGithub = false
            let tmpShowSonarcloud = false
            let tmpShowAtlassian = false
            let tmpShowDocker = false
            let tmpErrorSort = false

            if(props.savedOptionsArray[usedIndex].optionShowErrorOnly !== undefined){
                tmpShowErrorOnly = props.savedOptionsArray[usedIndex].optionShowErrorOnly
            } 

            if(props.savedOptionsArray[usedIndex].optionShowAWS !== undefined){
                tmpShowAWS = props.savedOptionsArray[usedIndex].optionShowAWS
            } 

            if(props.savedOptionsArray[usedIndex].optionShowSynthetic !== undefined){
                tmpShowSynthetic = props.savedOptionsArray[usedIndex].optionShowSynthetic
            } 
            
            if(props.savedOptionsArray[usedIndex].optionShowSyntheticGIT !== undefined){
                tmpShowSyntheticGIT = props.savedOptionsArray[usedIndex].optionShowSyntheticGIT
            } 

            if(props.savedOptionsArray[usedIndex].optionShowCheckMK !== undefined){
                tmpShowCheckMK = props.savedOptionsArray[usedIndex].optionShowCheckMK
            } 
            
            if(props.savedOptionsArray[usedIndex].optionShowGrafana !== undefined){
                tmpShowGrafana = props.savedOptionsArray[usedIndex].optionShowGrafana
            } 
            
            if(props.savedOptionsArray[usedIndex].optionShowGithub !== undefined){
                tmpShowGithub = props.savedOptionsArray[usedIndex].optionShowGithub
            } 
            
            if(props.savedOptionsArray[usedIndex].optionShowSonarcloud !== undefined){
                tmpShowSonarcloud = props.savedOptionsArray[usedIndex].optionShowSonarcloud
            } 
            
            if(props.savedOptionsArray[usedIndex].optionShowAtlassian !== undefined){
                tmpShowAtlassian = props.savedOptionsArray[usedIndex].optionShowAtlassian
            } 
            
            if(props.savedOptionsArray[usedIndex].optionShowDocker !== undefined){
                tmpShowDocker = props.savedOptionsArray[usedIndex].optionShowDocker
            } 
            
            if(props.savedOptionsArray[usedIndex].optionErrorSort !== undefined){
                tmpErrorSort = props.savedOptionsArray[usedIndex].optionErrorSort
            }             

            let options = {
                i: props.id,
                
                optionShowErrorOnly: props.savedOptionsArray[usedIndex].optionShowErrorOnly,
                optionShowAWS: props.savedOptionsArray[usedIndex].optionShowAWS,
                optionShowSynthetic: props.savedOptionsArray[usedIndex].optionShowSynthetic,
                optionShowSyntheticGIT: props.savedOptionsArray[usedIndex].optionShowSyntheticGIT,
                optionShowCheckMK: props.savedOptionsArray[usedIndex].optionShowCheckMK,
                optionShowGrafana: props.savedOptionsArray[usedIndex].optionShowGrafana,
                optionShowGithub: props.savedOptionsArray[usedIndex].optionShowGithub,
                optionShowSonarcloud: props.savedOptionsArray[usedIndex].optionShowSonarcloud,
                optionShowAtlassian: props.savedOptionsArray[usedIndex].optionShowAtlassian,
                optionShowDocker: props.savedOptionsArray[usedIndex].optionShowDocker,

                optionErrorSort: props.savedOptionsArray[usedIndex].optionErrorSort,
                visibleTests: []
            };
            
            props.productData.forEach(function(product) {
                
                let testVisibleStatus = false;
                let productVisibleStatus = true;

                if(props.savedOptionsArray[usedIndex].visibleTests !== undefined){
                    const currentProductIndexInOptions = props.savedOptionsArray[usedIndex].visibleTests.findIndex((product2) => product2.key === product._id);

                    if(currentProductIndexInOptions !== -1){
                        testVisibleStatus = props.savedOptionsArray[usedIndex].visibleTests[currentProductIndexInOptions].testVisible
                        productVisibleStatus = props.savedOptionsArray[usedIndex].visibleTests[currentProductIndexInOptions].productVisible
                    } 
                    
                }

                let originOptions = {
                    name: product.displayName,
                    key: product._id,
                    testVisible: testVisibleStatus,
                    productVisible: productVisibleStatus,
                    status: product.status,
                    description: product.description,
                    tests: product.tests  
                };
                
                options.visibleTests.push(originOptions);
                
            });         

            return options;
        } 
    });    

    const [showErrorOnly, setShowErrorOnly] = React.useState(() => {

        let usedIndex = -1

        props.savedOptionsArray.forEach(function(widget, index) {

            if(widget.i !== undefined){
                let string = widget.i
                let subBefore= string.substring(0, string.indexOf('_'));
                
                if(subBefore === "productoverview"){
                    usedIndex = index;
                } 
            }
        })

        if(props.savedOptionsArray[usedIndex].optionShowErrorOnly !== undefined){
            return props.savedOptionsArray[usedIndex].optionShowErrorOnly
        } else {
            return false
        }        
    })              

    const [showAWS, setShowAWS] = React.useState(() => {
        let usedIndex = -1
        
        props.savedOptionsArray.forEach(function(widget, index) {
            
            if(widget.i !== undefined){
                let string = widget.i
                let subBefore= string.substring(0, string.indexOf('_'));
                
                if(subBefore === "productoverview"){
                    usedIndex = index;
                } 
            }
        })

        if(props.savedOptionsArray[usedIndex].optionShowAWS !== undefined){
            return props.savedOptionsArray[usedIndex].optionShowAWS
        } else {
            return true
        }                
    }) 

    const [showSynthetic, setShowSynthetic] = React.useState(() => {
        let usedIndex = -1
        
        props.savedOptionsArray.forEach(function(widget, index) {
            
            if(widget.i !== undefined){
                let string = widget.i
                let subBefore= string.substring(0, string.indexOf('_'));
                
                if(subBefore === "productoverview"){
                    usedIndex = index;
                } 
            }
        })

        if(props.savedOptionsArray[usedIndex].optionShowSynthetic !== undefined){
            return props.savedOptionsArray[usedIndex].optionShowSynthetic
        } else {
            return true
        }                
    })         
    
    const [showSyntheticGIT, setShowSyntheticGIT] = React.useState(() => {
        let usedIndex = -1
        
        props.savedOptionsArray.forEach(function(widget, index) {
            
            if(widget.i !== undefined){
                let string = widget.i
                let subBefore= string.substring(0, string.indexOf('_'));
                
                if(subBefore === "productoverview"){
                    usedIndex = index;
                } 
            }
        })

        if(props.savedOptionsArray[usedIndex].optionShowSyntheticGIT !== undefined){
            return props.savedOptionsArray[usedIndex].optionShowSyntheticGIT
        } else {
            return true
        }                
    })     
    
    const [showCheckMk, setShowCheckMk] = React.useState(() => {
        let usedIndex = -1

        props.savedOptionsArray.forEach(function(widget, index) {

            if(widget.i !== undefined){
                let string = widget.i
                let subBefore= string.substring(0, string.indexOf('_'));
                
                if(subBefore === "productoverview"){
                    usedIndex = index;
                }             
            }
        })

        if(props.savedOptionsArray[usedIndex].optionShowCheckMK !== undefined){
            return props.savedOptionsArray[usedIndex].optionShowCheckMK
        } else {
            return true
        }        
    })          
    
    
    const [showGrafana, setShowGrafana] = React.useState(() => {
        let usedIndex = -1

        props.savedOptionsArray.forEach(function(widget, index) {
            if(widget.i !== undefined){
                let string = widget.i
                let subBefore= string.substring(0, string.indexOf('_'));
                
                if(subBefore === "productoverview"){
                    usedIndex = index;
                }             
            }
        })

        if(props.savedOptionsArray[usedIndex].optionShowGrafana !== undefined){
            return props.savedOptionsArray[usedIndex].optionShowGrafana
        } else {
            return false
        }              
    })         
    


    const [showGithub, setShowGithub] = React.useState(() => {
        let usedIndex = -1

        props.savedOptionsArray.forEach(function(widget, index) {

            if(widget.i !== undefined){
                let string = widget.i
                let subBefore= string.substring(0, string.indexOf('_'));
                
                if(subBefore === "productoverview"){
                    usedIndex = index;
                } 
            }
        })

        if(props.savedOptionsArray[usedIndex].optionShowGithub !== undefined){
            return props.savedOptionsArray[usedIndex].optionShowGithub
        } else {
            return false
        }              
    }) 

    const [showSonarcloud, setShowSonarcloud] = React.useState(() => {
        let usedIndex = -1

        props.savedOptionsArray.forEach(function(widget, index) {

            if(widget.i !== undefined){
                let string = widget.i
                let subBefore= string.substring(0, string.indexOf('_'));
                
                if(subBefore === "productoverview"){
                    usedIndex = index;
                }                 
            }
        })

        if(props.savedOptionsArray[usedIndex].optionShowSonarcloud !== undefined){
            return props.savedOptionsArray[usedIndex].optionShowSonarcloud
        } else {
            return false
        }              
    }) 

    const [showAtlassian, setShowAtlassian] = React.useState(() => {
        let usedIndex = -1

        props.savedOptionsArray.forEach(function(widget, index) {

            if(widget.i !== undefined){
                let string = widget.i
                let subBefore= string.substring(0, string.indexOf('_'));
                
                if(subBefore === "productoverview"){
                    usedIndex = index;
                }                 
            }
        })

        if(props.savedOptionsArray[usedIndex].optionShowAtlassian !== undefined){
            return props.savedOptionsArray[usedIndex].optionShowAtlassian
        } else {
            return false
        }              
    }) 

    const [showDocker, setShowDocker] = React.useState(() => {
        let usedIndex = -1

        props.savedOptionsArray.forEach(function(widget, index) {

            if(widget.i !== undefined){
                let string = widget.i
                let subBefore= string.substring(0, string.indexOf('_'));
                
                if(subBefore === "productoverview"){
                    usedIndex = index;
                }                 
            }
        })

        if(props.savedOptionsArray[usedIndex].optionShowDocker !== undefined){
            return props.savedOptionsArray[usedIndex].optionShowDocker
        } else {
            return false
        }              
    })     
    
    const [errorSort, setErrorSort] = React.useState(() => {
        let usedIndex = -1

        props.savedOptionsArray.forEach(function(widget, index) {

            if(widget.i !== undefined){
                let string = widget.i
                let subBefore= string.substring(0, string.indexOf('_'));
                
                if(subBefore === "productoverview"){
                    usedIndex = index;
                }             
            }
        })

        if(props.savedOptionsArray[usedIndex].optionErrorSort !== undefined){
            return props.savedOptionsArray[usedIndex].optionErrorSort
        } else {
            return false
        }           
    })                                     
    const [optionHasChanged, setOptionHasChanged] = React.useState(false)                                 

    const [isLoaded, setIsLoaded] = React.useState(false);                                       // Indikator ob Daten geladen wurden.       
    const [hasError, setHasError] = React.useState(false);                                      // Indikator ob Fehler vorliegen



    /***
     *          __   ___     ___  ___  ___  ___  __  ___  __  
     *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__` 
     *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/ 
     *                                                        
     */

    /**
     * Hilfsvriable befüllen, damit in den Optionen die Reihenfolge der Produkte nicht beim re-render 
     * verändert wird
     */
    React.useEffect(() => {
        widgetOptionsCopy.current = widgetOptions;
    },[])


    // Wenn in den Props neue Daten vorliegen, wird der visibleTest State neu erstellt, um die Komponenten neu zu rendern
    React.useEffect(() => {
        
        let test = [];
        let countProducts = 0; 

        //console.log(props.productData)
        props.productData.forEach(function(product) {

            const currentProductIndex = widgetOptions.visibleTests.findIndex((product2) => product2.key === product._id);

            let originProduct = {
                name: product.displayName,
                key: product._id,
                testVisible: widgetOptions.visibleTests[currentProductIndex]['testVisible'],
                productVisible: widgetOptions.visibleTests[currentProductIndex]['productVisible'],
                status: product.status,  
                description: product.description,
                tests: product.tests                
            };

            test.push(originProduct);
        })
        

        // Check wieviele Produkte im error only angezeigt werden sollen
        widgetOptions.visibleTests.forEach(function(product){            

            const currentProductIndex = props.productData.findIndex((product2) => product2._id === product.key);

            if(currentProductIndex !== -1){
                let hasAWS = false
                let hasSynthetic = false
                let hasSyntheticGIT = false
                let hasCheckMK = false
                let hasGrafana = false
                let showProduct = false
                let hasGithub = false
                let hasSonarcloud = false
                let hasAtlassian = false
                let hasDocker = false

                const foundAWS = product.tests.find(testsItem => testsItem.origin === "AWS");
                if (foundAWS){
                    hasAWS = true
                }

                const foundSynthetic = product.tests.find(testsItem => testsItem.origin === "synthetic");
                if (foundSynthetic){
                    hasSynthetic = true
                }

                const foundSyntheticGIT = product.tests.find(testsItem => testsItem.origin === "syntheticGIT");
                if (foundSyntheticGIT){
                    hasSyntheticGIT = true
                }

                const foundCheckMK = product.tests.find(testsItem => testsItem.origin === "checkmk");
                if (foundCheckMK){
                    hasCheckMK = true
                }        

                const foundGrafana = product.tests.find(testsItem => testsItem.origin === "grafana");
                if (foundGrafana){
                    hasGrafana = true
                }        

                const foundGithub = product.tests.find(testsItem => testsItem.origin === "github");
                if (foundGithub){
                    hasGithub = true
                }        
                
                const foundSonarcloud = product.tests.find(testsItem => testsItem.origin === "sonarcloud");
                if (foundSonarcloud){
                    hasSonarcloud = true
                }                

                const foundAtlassian = product.tests.find(testsItem => testsItem.origin === "atlassian");
                if (foundAtlassian){
                    hasAtlassian = true
                }          

                const foundDocker = product.tests.find(testsItem => testsItem.origin === "docker");
                if (foundDocker){
                    hasDocker = true
                }          
                
                if(showSynthetic === true && hasSynthetic === true){
                    showProduct = true
                } else if(showSyntheticGIT === true && hasSyntheticGIT === true){
                    showProduct = true
                } else if(showCheckMk === true && hasCheckMK === true){
                    showProduct = true
                } else if(showGrafana === true && hasGrafana === true){
                    showProduct = true
                } else if(showGithub === true && hasGithub === true){
                    showProduct = true
                } else if(showSonarcloud === true && hasSonarcloud === true){
                    showProduct = true
                } else if(showAtlassian === true && hasAtlassian === true){
                    showProduct = true
                } else if(showDocker === true && hasDocker === true){
                    showProduct = true
                } else if(showAWS === true && hasAWS === true){
                    showProduct = true
                } 
            
            
                if(props.productData[currentProductIndex].status !== "OK" && showProduct === true){
                    countProducts = countProducts + 1;   
                    setProductsWithErrors(countProducts)
                }  else {
                    //console.log(countProducts)
                    if(countProducts === 0){
                        setProductsWithErrors(countProducts)
                    }
                }              
            }
        })




        //console.log("test array: ")
        //console.log(test)

        if(errorSort){

            const sortOrder = ["ERROR", "WARN", "DOWN", "OK"];
            let strAscending = test.sort((a, b) => sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status));

            strAscending.forEach((product) => {product.tests.sort(
                (a, b) => sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status));
            });

            setWidgetOptions({ ...widgetOptions, visibleTests: strAscending });
            setIsLoaded(true)  

        } else {

            setWidgetOptions({ ...widgetOptions, visibleTests: test });
            setIsLoaded(true)

        }       
        
      }, [props, showErrorOnly, errorSort]);


      React.useEffect(() => {
        
        if(optionHasChanged === true){
            //console.log("Option has changed in Productoverview")
            props.syncOptionsArray(props.id, widgetOptions)
            setOptionHasChanged(false)
        } else {
            //console.log("No Option has been changed in Productoverview")
        }
                
      }, [widgetOptions]);


    /***
     *     ___            __  ___    __        __  
     *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
     *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
     *                                             
     */

     
    const toggleConfigMenuStatus = () => {
        setConfigMenuStatus(!configMenuStatus)
    }

    const handleClickDummy = (Message) => {
        props.handleNotify({type: 'success', message: Message})
    };

    const handleAddGraph = (test, product, displayName, productName, type) => {
        console.log("handleFunction displayname: " + displayName)
        console.log("handleFunction test: " + test)
        if(type !== 'all'){
            props.handleAddGraph(test, product, displayName, productName, type);
        } else {
            props.handleAddGraph(test, product, displayName, productName, type);
        }
        
    };    

    const toggleErrorOnly = () => {
        setWidgetOptions({ ...widgetOptions, optionShowErrorOnly: !widgetOptions.optionShowErrorOnly });
        setShowErrorOnly(!showErrorOnly)
        setOptionHasChanged(true)
        //console.log("Show Error Only: " + showErrorOnly)
    }

    const toggleShowAWS = () => {
        setWidgetOptions({ ...widgetOptions, optionShowAWS: !widgetOptions.optionShowAWS });
        setShowAWS(!showAWS)
        setOptionHasChanged(true)
        //console.log("Show Error Only: " + showErrorOnly)
    }

    const toggleShowSynthetic = () => {
        setWidgetOptions({ ...widgetOptions, optionShowSynthetic: !widgetOptions.optionShowSynthetic });
        setShowSynthetic(!showSynthetic)
        setOptionHasChanged(true)
        //console.log("Show Error Only: " + showErrorOnly)
    }
    
    const toggleShowSyntheticGIT = () => {
        setWidgetOptions({ ...widgetOptions, optionShowSyntheticGIT: !widgetOptions.optionShowSyntheticGIT });
        setShowSyntheticGIT(!showSyntheticGIT)
        setOptionHasChanged(true)
        //console.log("Show Error Only: " + showErrorOnly)
    }

    const toggleShowCheckMK = () => {
        setWidgetOptions({ ...widgetOptions, optionShowCheckMK: !showCheckMk });
        setShowCheckMk(!showCheckMk)
        setOptionHasChanged(true)
        //console.log("Show Error Only: " + showCheckMk)
    }
    
    const toggleGrafana = () => {
        setWidgetOptions({ ...widgetOptions, optionShowGrafana: !showGrafana });
        setShowGrafana(!showGrafana)
        setOptionHasChanged(true)
        //console.log("Show Grafana: " + showGrafana)
    }  

    const toggleGithub = () => {
        setWidgetOptions({ ...widgetOptions, optionShowGithub: !showGithub });
        setShowGithub(!showGithub)
        setOptionHasChanged(true)
    }  

    const toggleSonarcloud = () => {
        setWidgetOptions({ ...widgetOptions, optionShowSonarcloud: !showSonarcloud });
        setShowSonarcloud(!showSonarcloud)
        setOptionHasChanged(true)
    }  
    
    const toggleAtlassian = () => {
        setWidgetOptions({ ...widgetOptions, optionShowAtlassian: !showAtlassian });
        setShowAtlassian(!showAtlassian)
        setOptionHasChanged(true)
    }  
    
    const toggleDocker = () => {
        setWidgetOptions({ ...widgetOptions, optionShowDocker: !showDocker });
        setShowDocker(!showDocker)
        setOptionHasChanged(true)
    }      

    
    const toggleErrorSort = () => {        
        setWidgetOptions({ ...widgetOptions, optionErrorSort: !errorSort });
        setErrorSort(!errorSort) 
        setOptionHasChanged(true) 
    }
 
    /***********************************************************************************
     * DOM für das Konfigurationsmenü generieren 
     ***********************************************************************************/
    const FillConfigMenu = () => {
        //console.log(widgetOptions.visibleTests)
        return(
            <div className="productWidget--configMenuWrap">
                <div className="productWidget--HeaderWrap drag-handle">
                    <div className="productWidgetHeader--Title">Produktübersicht - Konfiguration</div>
                    <button
                        className="productWidgetHeader--MenuBtn"
                        onClick={() => toggleConfigMenuStatus()}
                    >
                        <FontAwesomeIcon icon="fa-regular fa-rectangle-xmark" />
                    </button>
                </div>
                <div className="productWidgetConfig--ContentWrap">

                    <div className="productWidgetConfigContent--HeaderWrap">
                        <div className="productWidgetConfigContent--HeaderTitle">Widget Optionen</div>
                        <div className="productWidgetConfigContent--LineBreak"></div>
                        <div className="productWidgetConfigContent--HeaderSubTitle"></div>
                    </div>
                    
                    <div className="productWidgetConfigContent--ContentWrap">
                        <div className="productWidgetConfigContent--OptionWrap">
                            <div className="productWidgetConfigContent--Title">Alarme automatisch sortieren</div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleErrorSort()} checked={errorSort ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>
                        <div className="productWidgetConfigContent--OptionWrap">
                            <div className="productWidgetConfigContent--Title">Status OK ausblenden (Bad only)</div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleErrorOnly()} checked={showErrorOnly ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>                        
                        <div className="productWidgetConfigContent--OptionWrap">
                            <div className="productWidgetConfigContent--Title">AWS Alarme anzeigen</div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleShowAWS()} checked={showAWS ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>                                                                        
                        <div className="productWidgetConfigContent--OptionWrap">
                            <div className="productWidgetConfigContent--Title">Synthetische Test anzeigen</div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleShowSynthetic()} checked={showSynthetic ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>   
                        <div className="productWidgetConfigContent--OptionWrap">
                            <div className="productWidgetConfigContent--Title">Synthetische GIT Test anzeigen</div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleShowSyntheticGIT()} checked={showSyntheticGIT ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>                                                                        
                        <div className="productWidgetConfigContent--OptionWrap">
                            <div className="productWidgetConfigContent--Title">CheckMK Alarme anzeigen</div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleShowCheckMK()} checked={showCheckMk ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>  
                        <div className="productWidgetConfigContent--OptionWrap">
                            <div className="productWidgetConfigContent--Title">Grafana Alarme anzeigen</div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleGrafana()} checked={showGrafana ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>                                                      
                        <div className="productWidgetConfigContent--OptionWrap">
                            <div className="productWidgetConfigContent--Title">Github Alarme anzeigen</div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleGithub()} checked={showGithub ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>
                        <div className="productWidgetConfigContent--OptionWrap">
                            <div className="productWidgetConfigContent--Title">Sonarcloud Alarme anzeigen</div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleSonarcloud()} checked={showSonarcloud ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>
                        <div className="productWidgetConfigContent--OptionWrap">
                            <div className="productWidgetConfigContent--Title">Atlassian Alarme anzeigen</div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleAtlassian()} checked={showAtlassian ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>
                        <div className="productWidgetConfigContent--OptionWrap">
                            <div className="productWidgetConfigContent--Title">Docker Alarme anzeigen</div>
                            <div className="productWidgetConfigContent--Checkbox">
                                <label className="switch productWidgetConfigContent--Switch">
                                    <input type="checkbox" onChange={() => toggleDocker()} checked={showDocker ? "checked" : ""}></input>
                                    <span className="slider round"></span>
                                </label> 
                            </div>
                        </div>                                                                        
                    </div>

                    <div className="productWidgetConfigContent--HeaderWrap">
                        <div className="productWidgetConfigContent--HeaderTitle">Produktauswahl</div>
                        {toggleAllProductStatus ? 
                                    <>
                                    <div className="productWidgetConfigContent--HeaderIcon" onClick={() => toggleProductVisibility("off")}><FontAwesomeIcon icon="fa-regular fa-eye" /></div> 
                                    </>
                                : 
                                    <>
                                    <div className="productWidgetConfigContent--HeaderIcon" onClick={() => toggleProductVisibility("on")}><FontAwesomeIcon icon="fa-regular fa-eye-slash" /></div> 
                                    </>
                                }
                        
                        
                        <div className="productWidgetConfigContent--LineBreak"></div>
                        <div className="productWidgetConfigContent--HeaderSubTitle">Nur hier ausgewählte Produkte werden in der Übersicht dargestellt</div>
                    </div>
                    
                    <div className="productWidgetConfigContent--ProductContentWrap">
                        {widgetOptions.visibleTests.map(originElement =>(
                            <div key={originElement.key} className="productWidgetConfigContent--ProductWrap" onClick={() => toggleProductVisibility(originElement.key)}>
                                
                                {originElement.productVisible ? 
                                    <>
                                    <div className="productWidgetConfigContent--Title">{originElement.name}</div>
                                    <div className="productWidgetConfigContent--Icon"><FontAwesomeIcon icon="fa-regular fa-eye" /></div> 
                                    </>
                                : 
                                    <>
                                    <div className="productWidgetConfigContent--Title productWidgetConfigContent--subtextColor">{originElement.name}</div>
                                    <div className="productWidgetConfigContent--Icon productWidgetConfigContent--subtextColor"><FontAwesomeIcon icon="fa-regular fa-eye-slash" /></div>
                                    </>
                                }
                                
                            </div>                
                        ))}

                    </div>                    

                </div>
                <div className="productWidgetConfig--DeleteWrap">
                    <button
                        className="productWidgetHeader--DeleteBtn"
                        onClick={() => props.handleDelete(props.id)}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-trash" /> Widget entfernen
                    </button>
                </div>
            </div>
        )
    }


    /*************************************************************************************
     * DOM für das Konfigurationsmenü generieren 
    *************************************************************************************/
    const FillProductContent = (product) => {
        //console.log(product)
        
        let productErorOnlyClass = "productWidget-productWrap";

        let hasAWS = false
        let hasSynthetic = false
        let hasSyntheticGIT = false
        let hasCheckMK = false
        let hasGrafana = false
        let showProduct = false
        let hasGithub = false
        let hasSonarcloud = false
        let hasAtlassian = false
        let hasDocker = false

        const foundAWS = product.product.tests.find(testsItem => testsItem.origin === "AWS");
        if (foundAWS){
            hasAWS = true
        }

        const foundSynthetic = product.product.tests.find(testsItem => testsItem.origin === "synthetic");
        if (foundSynthetic){
            hasSynthetic = true
        }

        const foundSyntheticGIT = product.product.tests.find(testsItem => testsItem.origin === "syntheticGIT");
        if (foundSyntheticGIT){
            hasSyntheticGIT = true
        }

        const foundCheckMK = product.product.tests.find(testsItem => testsItem.origin === "checkmk");
        if (foundCheckMK){
            hasCheckMK = true
        }        

        const foundGrafana = product.product.tests.find(testsItem => testsItem.origin === "grafana");
        if (foundGrafana){
            hasGrafana = true
        }        

        const foundGithub = product.product.tests.find(testsItem => testsItem.origin === "github");
        if (foundGithub){
            hasGithub = true
        }        
        
        const foundSonarcloud = product.product.tests.find(testsItem => testsItem.origin === "sonarcloud");
        if (foundSonarcloud){
            hasSonarcloud = true
        }                

        const foundAtlassian = product.product.tests.find(testsItem => testsItem.origin === "atlassian");
        if (foundAtlassian){
            hasAtlassian = true
        }          

        const foundDocker = product.product.tests.find(testsItem => testsItem.origin === "docker");
        if (foundDocker){
            hasDocker = true
        }          
        
        if(showSynthetic === true && hasSynthetic === true){
            showProduct = true
        } else if(showSyntheticGIT === true && hasSyntheticGIT === true){
            showProduct = true
        } else if(showCheckMk === true && hasCheckMK === true){
            showProduct = true
        } else if(showGrafana === true && hasGrafana === true){
            showProduct = true
        } else if(showGithub === true && hasGithub === true){
            showProduct = true
        } else if(showSonarcloud === true && hasSonarcloud === true){
            showProduct = true
        } else if(showAtlassian === true && hasAtlassian === true){
            showProduct = true
        } else if(showDocker === true && hasDocker === true){
            showProduct = true
        } else if(showAWS === true && hasAWS === true){
            showProduct = true
        } 

        // TODO weiter neue Origins einbauen. States erstellen, in Options einbinden und hier abfragen

        if(showProduct === false){           
            productErorOnlyClass = "productWidget-productWrap productWidgetProduct-Hidden"
        } else if(showErrorOnly === true && product.product.status === "OK"){
            productErorOnlyClass = "productWidget-productWrap productWidgetProduct-Hidden"
        } else {
            productErorOnlyClass = "productWidget-productWrap"
        }
        
        const productStatusColorClass = "productWidgetProduct-Status productWidgetProduct-Status-" + product.product.status 
        const productName = product.product.name
        const productDescription = product.product.description
        return(
            <>
                <div className={productErorOnlyClass}>
                    <div id={"productWidget-productBtnWrap"} className="productWidget-productBtnWrap" onClick={() => toggleProductTests(product.product.key)}>
                    {/*
                    <Tooltip    
                        className="tooltipStyles"
                        anchorSelect={"#productWidget-productBtnWrap"} 
                        html={productDescription} 
                        place="bottom" 
                        style={{ backgroundColor: "var(--hover-color)", color: "var(--text-color)" }}
                    /> */} 
                        <div className={productStatusColorClass}></div>
                        <div className="productWidgetProduct-Name">{product.product.name}</div>

                        {widgetOptions.visibleTests[productName] ? <div className="productWidgetProduct-TestBtn"><FontAwesomeIcon icon="fa-solid fa-chevron-up" /></div> 
                        : <div className="productWidgetProduct-TestBtn"><FontAwesomeIcon icon="fa-solid fa-chevron-down" /></div>}
                    </div>

                    <div className="break"></div>

                    {product.product.testVisible ? <FillTestContent key={"testContent-" + product.product.key} product={product} /> : null}
                    
                </div>
            </>
        )
    }    

    /*************************************************************************************
     * Befüllen der Testläufe eines Produkts
     * @param {*} product 
     * @returns 
     ************************************************************************************/
    const FillTestContent = (product) => {
        
        //console.log(product)
        // Ermitteln welche Origins bei den Test verwendet werden, und die Test entsprechenden Object unterordnen
        //console.log(product.product.product.tests)
        const tests = product.product.product.tests
        
        let sortedTests = []
        let counter = 0
        
        // Erstellen der Origins im Array
        tests.forEach(function(test) {
            
            let originTests = {
                origin: test.origin,
                key: counter,
                productId: test.productId,
                includedTests: []
            };
            
            // Alle Objekte prüfen ob das Origin bereits vorhanden ist
            const found = sortedTests.find(sortedTestsItem => sortedTestsItem.origin === test.origin);
            if (!found){
                sortedTests.push(originTests);
                counter = counter + 1;
            } 

        }); 


        // Zuweisen der Test zu den entsprechenden Origins
        tests.forEach(function(test) {
            
            sortedTests.forEach(function(sortedTests) {

                if(test.origin === sortedTests.origin){
                    sortedTests.includedTests = [...sortedTests.includedTests, test]   
                } 

            })
            
        }); 

        return(
            <>
                <div key={tests._id} className="productWidgetProduct-TestWrap">
                    
                    {sortedTests.map(originElement =>(  
                        <div key={"productWidgetProduct-" + originElement.key}>
                            <div key={originElement.key} className="productWidgetTests--HeaderWrap">
                                <div className="productWidgetTestsHeader--Title">{originElement.origin}</div>
                                
                                <div id={"tooltip-openAll"} className={"productWidgetTestsHeader--Btn productWidgetTestsHeader--Btn-" + originElement.origin} onClick={() => props.handleAddAllGraphs(originElement.key, originElement.productId)}><FontAwesomeIcon icon="fa-solid fa-chart-line" /></div>
                                <Tooltip    
                                    className="tooltipStyles"
                                    anchorSelect={"#tooltip-openAll"} 
                                    html={"<b>Alle Graphen anzeigen</b>"} 
                                    place="left" 
                                    style={{ backgroundColor: "var(--hover-color)", color: "var(--text-color)", zIndex: "500" }}
                                />                           
                            </div>
                            <div key={"productWidgetTestsHeader--Line" + originElement.key} className="productWidgetTestsHeader--Line"></div>
                            

                            {originElement.includedTests.map(test =>{
                                
                                if(showErrorOnly === true && test.status !== "OK"){

                                    if(test.origin === "synthetic" || test.origin === "syntheticGIT"){
                                        return(
                                        <div id={"tooltip-" + test._id} key={"productWidgetTest-" + test._id} className="productWidgetTest--Container" onClick={() => handleAddGraph(test._id, originElement.productId, test.displayName, product.product.product.name, 'single')}>
                                        <Tooltip    
                                            className="tooltipStyles"
                                            anchorSelect={"#tooltip-" + test._id} 
                                            html={"<b>" + test.description + "</b><br /><br />Durchschnittsdauer: " + test.avgDuration + " sek. <br />Status der letzten 3 Durchläufe: " + test.countOk + "/3 OK<br /><br /><b>Owner:</b> " + test.owner} 
                                            place="bottom" 
                                            style={{ backgroundColor: "var(--hover-color)", color: "var(--text-color)", zIndex: "500" }}
                                        />
                                            <div className="productWidgetTest--Name">{test.displayName}</div>                                    
                                            <div className="roductWidgetTest--Symbol"></div>
                                            <div className={"productWidgetTest--Status productWidgetProduct-Status-" + test.status}></div>
                                        </div>
                                        )
                                    } else {
                                        return(
                                            <div id={"tooltip-" + test._id} key={"productWidgetTest-" + test._id} className="productWidgetTest--Container" onClick={() => openNewWebtab(test.url)}>
                                            <Tooltip    
                                                className="tooltipStyles"
                                                anchorSelect={"#tooltip-" + test._id} 
                                                html={"Owner: " + test.owner} 
                                                place="bottom" 
                                                style={{ backgroundColor: "var(--hover-color)", color: "var(--text-color)", zIndex: "500" }}
                                            />
                                                <div className="productWidgetTest--Name">{test.displayName}</div>                                    
                                                <div className="roductWidgetTest--Symbol"></div>
                                                <div className={"productWidgetTest--Status productWidgetProduct-Status-" + test.status}></div>
                                            </div>
                                            )                                    
                                    }

                                } else if (showErrorOnly === false) {

                                    if(test.origin === "synthetic" || test.origin === "syntheticGIT"){
                                        return(
                                        <div id={"tooltip-" + test._id} key={"productWidgetTest-" + test._id} className="productWidgetTest--Container" onClick={() => handleAddGraph(test._id, originElement.productId, test.displayName, product.product.product.name, 'single')}>
                                        <Tooltip    
                                            className="tooltipStyles"
                                            anchorSelect={"#tooltip-" + test._id} 
                                            html={"<b>" + test.description + "</b><br /><br />Durchschnittsdauer: " + test.avgDuration + " sek. <br />Status der letzten 3 Durchläufe: " + test.countOk + "/3 OK<br /><br /><b>Owner:</b> " + test.owner } 
                                            place="bottom" 
                                            style={{ backgroundColor: "var(--hover-color)", color: "var(--text-color)", zIndex: "500" }}
                                        />
                                            <div className="productWidgetTest--Name">{test.displayName}</div>                                    
                                            <div className="roductWidgetTest--Symbol"></div>
                                            <div className={"productWidgetTest--Status productWidgetProduct-Status-" + test.status}></div>
                                        </div>
                                        )
                                    } else {
                                        
                                        return(
                                            <div id={"tooltip-" + test._id} key={"productWidgetTest-" + test._id} className="productWidgetTest--Container" onClick={() => openNewWebtab(test.url)}>
                                            <Tooltip    
                                                className="tooltipStyles"
                                                anchorSelect={"#tooltip-" + test._id} 
                                                html={"Owner: " + test.owner} 
                                                place="bottom" 
                                                style={{ backgroundColor: "var(--hover-color)", color: "var(--text-color)", zIndex: "500" }}
                                            />
                                                <div className="productWidgetTest--Name">{test.displayName}</div>                                    
                                                <div className="roductWidgetTest--Symbol"></div>
                                                <div className={"productWidgetTest--Status productWidgetProduct-Status-" + test.status}></div>
                                            </div>
                                            )                                    
                                    }

                                }
                                
                            })}

                            <div key={"productWidgetTests--Spacer" + originElement.key} className="productWidgetTests--Spacer"></div>
                        </div>
                    ))}

                    
                </div>
            </>
        )
    }

    const openNewWebtab = (url) => {

        if(url !== undefined && url !== ''){
            window.open(url, '_blank').focus();
        } else {
            props.handleNotify({type: 'warning', message: 'Für diesen Test ist keine Verlinkung hinterlegt.'})
        }
        
    }


    /**
     * Anzeigen der Test Wrapper zum zugehörigen Produkt
     * @param {*} product 
     */
    const toggleProductTests = (productID) => {
        // 1. Find the product with the provided id
        const currentProductIndex = widgetOptions.visibleTests.findIndex((product) => product.key === productID);
        // 2. Mark the testVisible as true or false
        const productValue = !widgetOptions.visibleTests[currentProductIndex]['testVisible']
        const updatedVisibleTests = {...widgetOptions.visibleTests[currentProductIndex], testVisible: productValue};
        // 3. Update the visibleTests
        const newVisibleTests = [...widgetOptions.visibleTests];
        newVisibleTests[currentProductIndex] = updatedVisibleTests;
        
        setWidgetOptions({ ...widgetOptions, visibleTests: newVisibleTests }); 
        setOptionHasChanged(true)            
    }


    /**
     * Funktion zum Ein und Ausblenden von einzelnen Produkten
     * @param {*} product
     */


    const toggleProductVisibility = (productID) => {
        
        if(productID === "on"){
            setToggleAllProductStatus(true)
            //console.log("Turn all off")

            let newProducts = [];
            widgetOptions.visibleTests.forEach(function(product) {                
                const updatedProduct = {...product, productVisible: false}; 
                newProducts.push(updatedProduct);                              
            }); 
            
            setWidgetOptions({ ...widgetOptions, visibleTests: newProducts });                       
            setOptionHasChanged(true)


        } else if(productID === "off"){
            setToggleAllProductStatus(false)
            //console.log("Turn all on")

            let newProducts = [];
            widgetOptions.visibleTests.forEach(function(product) {                
                const updatedProduct = {...product, productVisible: true}; 
                newProducts.push(updatedProduct);                              
            }); 
            
            setWidgetOptions({ ...widgetOptions, visibleTests: newProducts });                       
            setOptionHasChanged(true)

        } else {
            // 1. Find the product with the provided id
            const currentProductIndex = widgetOptions.visibleTests.findIndex((product) => product.key === productID);
            // 2. Mark the testVisible as true or false
            const productValue = !widgetOptions.visibleTests[currentProductIndex]['productVisible']
            const updatedVisibleTests = {...widgetOptions.visibleTests[currentProductIndex], productVisible: productValue};
            // 3. Update the visibleTests
            const newVisibleTests = [...widgetOptions.visibleTests];
            newVisibleTests[currentProductIndex] = updatedVisibleTests;
             
            setWidgetOptions({ ...widgetOptions, visibleTests: newVisibleTests });                                     
            setOptionHasChanged(true)
        }
    
    }    




    /***
     *     __   __            __   ___ ___       __       
     *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
     *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
     *                                                    
     */

    return(
        <>
            {configMenuStatus ?  <FillConfigMenu /> : null}
            <div className="productWidget--HeaderWrap drag-handle">
                <div className="productWidgetHeader--Title">Produktübersicht</div>
                <button
                    className="productWidgetHeader--MenuBtn"
                    onClick={() => toggleConfigMenuStatus()}
                >
                    <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                </button>
            </div>
            <div className="productWidget--ContentWrap">

            {
                (() => {
                    if (isLoaded === false)
                        return(
                            <div className="productWidget--LoadingWrap">
                                <p className="productWidget--LoadingWrapText">Lade Daten</p>
                                <p><PropagateLoader color={'#6f7277'} size={4} /></p>
                            </div> 
                        )

                    if (isLoaded === true){
                        let somethingVisible = false;
                        widgetOptions.visibleTests.forEach(function(product) {                
                            if(product.productVisible === true){
                                somethingVisible = true;
                            }
                        });
                        if(somethingVisible !== true){
                            return <div className="productWidget--ErrorWrap">Es sind keine Produkte ausgewählt</div>    
                        }                        
                    }
                                        
                    if (isLoaded === true && productsWithErrors === 0 && showErrorOnly === true)
                        return <div className="productWidget--ErrorWrap">Bei den ausgewählten Produkten sind <br />z.Zt. keine Alarme vorhanden</div>
                    if (hasError === true)
                        return <div className="productWidget--ErrorWrap">Error</div>
                    else 
                        return(
                            widgetOptions.visibleTests.map((position) => {
                                if(position.productVisible === true){

                                    return(
                                        <FillProductContent 
                                            key={position.key}   
                                            id={position.key} 
                                            product={position} 
                                        />
                                        // TODO ADD +1
                                    )                            
                                } else {
                                    return(
                                        <div key={position.key} ></div>
                                    )
                                }
                            })
                        )
                })()
            }


            </div>

        </>
    )

}

export default memo(ProductOverviewWidget)
