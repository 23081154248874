import html2canvas from "html2canvas";

const exportAsImage = async (element, imageFileName) => {
  const html = document.getElementsByTagName("html")[0];
  const body = document.getElementsByTagName("body")[0];
  let htmlWidth = html.clientWidth;
  let bodyWidth = body.clientWidth;

  const newWidth = element.scrollWidth - element.clientWidth;

  if (newWidth > element.clientWidth) {
    htmlWidth += newWidth;
    bodyWidth += newWidth;
  }

  html.style.width = htmlWidth + "px";
  body.style.width = bodyWidth + "px";
  
  const canvas = await html2canvas(element);

  // Create a new canvas with the desired width
  const newCanvas = document.createElement('canvas');
  newCanvas.width = 1024;
  newCanvas.height = 350;
  //newCanvas.height = canvas.height * (1024 / canvas.width);

  // Draw the original canvas onto the new canvas
  const ctx = newCanvas.getContext('2d');
  ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newCanvas.width, newCanvas.height);

  let img = new Image();
  
  // Get the base64-encoded string from the new canvas
  img.src = await newCanvas.toDataURL("image/jpeg", .9)
  //downloadImage(img.src, "test");
  return img

};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

export default exportAsImage;