import React, { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../styles/DowntimeAdmin.css";

import { productStaticAPI, downtimeOverviewAPI } from "../http-common";
import { useFilters, useTable, useRowSelect } from "react-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { random } from "lodash";

import Popup from "reactjs-popup";

import "reactjs-popup/dist/index.css";

registerLocale("de", de);

/*
 ___       _         _  _              _ __                                        _        
|_ _| ___ | |_  ___ | || | ___  _ _   | / / ___  _ _ _  ___  ___  _ _  ___  _ _  _| |_  ___ 
 | | [_] || . \/ ._]| || |/ ._]| ' |  |  \ / . \| ' ' || . \/ . \| ' |/ ._]| ' |  | |  / ._]
 |_| [___||___/\___.|_||_|\___.|_|_|  |_\_\\___/|_|_|_||  _/\___/|_|_|\___.|_|_|  |_|  \___.
                                                       |_|                                  

*/

/**
 * Tabelle fuer die Downtimeauswahl
 * @param {*} param0
 * @returns
 */
function Table({ columns, data, onRowSelect }) {
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    selectedFlatRows,
    state: { selectedRowIds },
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useRowSelect
  );

  /**
   * Die Selectierten Reihen verwalten
   */

  const selectedRowsData = React.useMemo(() => {
    return Object.keys(selectedRowIds)
      .filter((key) => selectedRowIds[key])
      .map((key) => data[parseInt(key)]);
  }, [selectedRowIds, data]);


  React.useEffect(() => {
    onRowSelect(selectedRowsData);
  }, [onRowSelect, selectedRowsData]);

  return (
    <>
      <table className="downtimeAdminContent--ConfigTable" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="downtimeAdminContent--ConfigTable--TableHead"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                  <div className="downtimeAdminContent--ConfigTable--Filter">
                    {column.canFilter ? column.render("Filter") : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="tbody-tbody" {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row); // This line is necessary to prepare the rows and get the row props from react-table dynamically
            // Each row can be rendered directly as a string using the react-table render method
            return (
              <tr
                {...row.getRowProps()}
                className={
                  row.isSelected
                    ? "selected-row"
                    : "downtimeAdminContent--ConfigTable--tr"
                }
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      //className="downtimeAdminContent--ConfigTable--td"
                      className="downtimeAdminContent--ConfigTable--td"
                      {...cell.getCellProps()}
                    >
                      <div className="downtimeAdminContent--ConfigTable--td-Content">
                        {cell.render("Cell")}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

/*
___                     _    _                ___    _  _    _             
| . \ ___  _ _ _  _ _  _| |_ [_] _ _ _  ___   | __] _| |[_] _| |_  ___  _ _ 
| | |/ . \| | | || ' |  | |  | || ' ' |/ ._]  | _] / . || |  | |  / . \| '_]
|___/\___/|__/_/ |_|_|  |_|  |_||_|_|_|\___.  |___]\___||_|  |_|  \___/|_|  
 */

const DowntimeAdmin = (props) => {
  /***
   *     __  ___      ___  ___  __
   *    /__`  |   /\   |  |__  /__`
   *    .__/  |  /~~\  |  |___ .__/
   *
   */

  const [data, setData] = React.useState({ data: [] });
  const [downtimeCreateVisible, setdowntimeCreateVisible] =
    React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [activeDowntimes, setActiveDowntimes] = React.useState({ data: [] });
  const [downtimeCreate, setDowntimeCreate] = React.useState();
  const [editDowntime, setEditDowntime] = React.useState();

  const [showDelete, setShowDelete] = React.useState(false);
  const [toDelete, setToDelete] = React.useState();

  /***
   *          __   ___     ___  ___  ___  ___  __  ___  __
   *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__`
   *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/
   *
   */

  /**
   * Abrufen der Produkt Informationen
   */
  React.useEffect(() => {
    const fetchData = async () => {
      const idToken = props.receiveIDToken();
      const headers = { Authorization: idToken };
      const res = await productStaticAPI.get("", { headers });
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };

      const productData = { data: [] };
      result.data.forEach((product) => {
        product.tests.forEach((test) => {
          const newTestData = {};

          newTestData.productID = product._id;
          newTestData.productDisplayname = product.displayName;
          newTestData.testID = test._id;
          newTestData.testDisplayname = test.displayName;
          newTestData.origin = test.origin;

          productData.data.push(newTestData);
        });
      });

      setData(productData);
    };

    // call the function once use effect run
    fetchData().catch((error) => {
      const errorMsg = error.name + ": " + error.message;
      props.handleNotify({ type: "alert", message: errorMsg });
    });
  }, []);

  /**
   * Abrufen der Downtimes
   */
  React.useEffect(() => {
    const fetchData = async () => {
      const idToken = props.receiveIDToken();
      const headers = { Authorization: idToken };
      const res = await downtimeOverviewAPI.get("", { headers });
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };

      setActiveDowntimes(result);
    };

    // call the function once use effect run
    fetchData().catch((error) => {
      const errorMsg = error.name + ": " + error.message;
      props.handleNotify({ type: "alert", message: errorMsg });
    });
  }, [downtimeCreate]);

  /***
   *     ___            __  ___    __        __
   *    |__  |  | |\ | /  `  |  | /  \ |\ | /__`
   *    |    \__/ | \| \__,  |  | \__/ | \| .__/
   *
   */

  /*
 ___  _              _    _               _ __                                        _        
|  _]| |_  ___  ___ | |__| |_  ___ __    | / / ___  _ _ _  ___  ___  _ _  ___  _ _  _| |_  ___ 
| [__| . |/ ._]/ | '| / /| . \/ . \\ \/  |  \ / . \| ' ' || . \/ . \| ' |/ ._]| ' |  | |  / ._]
`___/|_|_|\___.\_|_.|_\_\|___/\___//\_\  |_\_\\___/|_|_|_||  _/\___/|_|_|\___.|_|_|  |_|  \___.
                                                          |_|                                  
*/
  /**
   * Checkbox Element für die erste Tabellen SPalte
   */
  const Checkbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  });

  /*
 ___  _  _    _                _ __                                        _        
| __][_]| | _| |_  ___  _ _   | / / ___  _ _ _  ___  ___  _ _  ___  _ _  _| |_  ___ 
| _] | || |  | |  / ._]| '_]  |  \ / . \| ' ' || . \/ . \| ' |/ ._]| ' |  | |  / ._]
|_|  |_||_|  |_|  \___.|_|    |_\_\\___/|_|_|_||  _/\___/|_|_|\___.|_|_|  |_|  \___.
                                               |_|                                  
*/

  /**
   * Input-Element fuer die Filterung der Tabelle
   *
   * @param {*} column
   * @returns
   */
  const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;

    return (
      <>
        <input
          className="downtimeAdminContent--ConfigTable--SearchInput"
          value={filterValue || ""}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Suche..."
        />
        <div
          className="downtimeAdminContent--ConfigTable--SearchReset"
          onClick={() => setFilter("")}
        >
          <FontAwesomeIcon icon="fa-solid fa-arrow-rotate-left" />
        </div>
      </>
    );
  };

  /*
 ___       _                     ___       ___  _       _    _    _           
|  _] ___ | | _ _  _ _ _  _ _   | . \ ___ | |  [_] _ _ [_] _| |_ [_] ___  _ _ 
| [__/ . \| || | || ' ' || ' |  | | |/ ._]| |- | || ' || |  | |  | |/ . \| ' |
`___/\___/|_| \__||_|_|_||_|_|  |___/\___.|_|  |_||_|_||_|  |_|  |_|\___/|_|_|
                                                                              
*/
  /**
   * Column definition fuer die Tabelle
   */
  const columns = React.useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <>
            <div className="columnDummy"></div>
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          </>
        ),
        Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
      },
      {
        Header: "Product",
        accessor: "productDisplayname",
        Filter: ColumnFilter,
        Cell: ({ row, value }) => (
          <div onClick={() => row.toggleRowSelected()}>{value}</div>
        ),
      },
      {
        Header: "Testname",
        accessor: "testDisplayname",
        Filter: ColumnFilter,
        Cell: ({ row, value }) => (
          <div onClick={() => row.toggleRowSelected()}>{value}</div>
        ),
      },
      {
        Header: "Origin",
        accessor: "origin",
        Filter: ColumnFilter,
        Cell: ({ row, value }) => (
          <div onClick={() => row.toggleRowSelected()}>{value}</div>
        ),
      },
    ],
    []
  );

  /**
   * Hilfsfunktion, welche den State Downtimes setzt, damit dieser in der Edit Funktion verfügbar ist
   * und gleichzeitig den Erstellen/Editieren Dialog öffnet
   * @param {*} downtime
   */
  function prepareEditDowntimes(downtime) {
    setEditDowntime(downtime);
    setdowntimeCreateVisible(true);
  }

  /*
 ___                   _           ___                     _    _                _ __                                        _        
|  _] _ _  ___  ___  _| |_  ___   | . \ ___  _ _ _  _ _  _| |_ [_] _ _ _  ___   | / / ___  _ _ _  ___  ___  _ _  ___  _ _  _| |_  ___ 
| [__| '_]/ ._][_] |  | |  / ._]  | | |/ . \| | | || ' |  | |  | || ' ' |/ ._]  |  \ / . \| ' ' || . \/ . \| ' |/ ._]| ' |  | |  / ._]
`___/|_|  \___.[___|  |_|  \___.  |___/\___/|__/_/ |_|_|  |_|  |_||_|_|_|\___.  |_\_\\___/|_|_|_||  _/\___/|_|_|\___.|_|_|  |_|  \___.
                                                                                                 |_|                                  
*/

  const DowntimeCreate = ({ selectedRows }) => {
    const [startDate, setStartDate] = React.useState(
      editDowntime ? new Date(editDowntime.start_time) : new Date()
    );
    const [endDate, setEndDate] = React.useState(
      editDowntime ? new Date(editDowntime.end_time) : new Date()
    );

    let editId = false;
    let headerText = "Downtime Erstellen";
    let buttonText = "Erstellen";

    //Prüfen ob das Fenster zum editieren geöffnet wurde und wenn ja entsprechend die INformationen vorbefüllen
    if (editDowntime) {
      const testList = editDowntime.tests.map((test) => {
        const objectToFind = data.data.find((obj) => obj.testID === test);
        return objectToFind ? objectToFind : null;
      });
      editId = editDowntime._id;
      selectedRows = testList;
      headerText = "Downtime Editieren";
      buttonText = "Editieren";
    }

    /**
     * Komponente für den Datepicker
     * @param {*} param0
     * @returns
     */
    const CombiDatepicker = ({
      startDate,
      setStartDate,
      endDate,
      setEndDate,
    }) => {
      return (
        <>
          <div className="createDowntime--Datepicker">
            <div className="createDowntime--Datepicker--Name">
              Startzeit Downtime:
            </div>
            <div className="createDowntime--Datepicker--Element">
              <DatePicker
                showTimeSelect
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd.MM.yyyy HH:mm:ss"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale="de"
              />
            </div>
          </div>
          <div className="createDowntime--Datepicker">
            <div className="createDowntime--Datepicker--Name">
              Endzeit Downtime:
            </div>
            <div className="createDowntime--Datepicker--Element">
              <DatePicker
                showTimeSelect
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="dd.MM.yyyy HH:mm:ss"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale="de"
              />
            </div>
          </div>
        </>
      );
    };

    /**
     * Funktion, welche die eingegebenen Daten verarbeitet und ein Downtime erstellt/editiert
     * @param {*} startDate
     * @param {*} endDate
     * @param {*} selectedRows
     * @param {*} textAreaValue
     * @param {*} editId
     */
    function createDowntime(
      startDate,
      endDate,
      selectedRows,
      textAreaValue,
      editId
    ) {
      let downtime = {};
      let tests = [];

      const startDateISO = new Date(startDate);
      const endDateISO = new Date(endDate);

      selectedRows.forEach((element) => {
        tests.push(element.testID);
      });

      downtime.created_by = props.userName;
      downtime.start_time = startDateISO.toISOString();
      downtime.end_time = endDateISO.toISOString();
      downtime.comment = textAreaValue;
      downtime.tests = tests;

      if (textAreaValue.length === 0) {
        props.handleNotify({
          type: "info",
          message: "Bitte Kommentar eingeben!",
        });
      } else {
        if (editId) {
          const idToken = props.receiveIDToken();
          console.log("ID Tocken in DTV: " + idToken);
          const headers = { Authorization: idToken };

          downtimeOverviewAPI
            .put("/" + editId, downtime, { headers })
            .then((response) => {
              props.handleNotify({
                type: "success",
                message: "Downtime Bearbeitet",
              });
              setdowntimeCreateVisible(false);
              setDowntimeCreate(!downtimeCreate);
            })
            .catch((error) => {
              props.handleNotify({
                type: "alert",
                message: "Fehler: " + error,
              });
            });
        } else {
          const idToken = props.receiveIDToken();
          const headers = { Authorization: idToken };
          downtimeOverviewAPI
            .post("/", downtime, { headers })
            .then((response) => {
              props.handleNotify({
                type: "success",
                message: "Downtime Erfolgreich erstellt!",
              });
              setdowntimeCreateVisible(false);
              setDowntimeCreate(!downtimeCreate);
            })
            .catch((error) => {
              props.handleNotify({
                type: "alert",
                message: "Fehler: " + error,
              });
            });
        }
      }
    }

    return (
      <div className="createDowntimeWrap">
        <div className="createDowntime--Content">
          <div className="createDowntime--HelperContent">
            <div className="createDowntime--HeaderWrap">
              <div className="createDowntime--HeaderName">{headerText}</div>
              <div
                className="createDowntime--CloseBtn"
                onClick={() => setdowntimeCreateVisible(false)}
              >
                <FontAwesomeIcon
                  className="createDowntime--CloseBtn"
                  icon="fa-regular fa-rectangle-xmark"
                  onClick={() => setdowntimeCreateVisible(false)}
                />
              </div>
            </div>
            <div className="createDowntime--FormsWrapper">
              <div className="createDowntime--DatepickerWrap">
                <CombiDatepicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              </div>
              <div className="createDowntime--CommentWrap">
                <textarea
                  id="commentTextArea"
                  className="createDowntime--Textarea"
                  placeholder="Downtime Beschreibung...."
                  defaultValue={editDowntime ? editDowntime.comment : ""}
                />
              </div>
            </div>
            <div className="createDowntime--DowntimeList--HeaderWrap">
              <div className="createDowntime--DowntimeList--ElementWrap--ProductName">
                Product
              </div>
              <div className="createDowntime--DowntimeList--ElementWrap--Origin">
                Origin
              </div>
              <div className="createDowntime--DowntimeList--ElementWrap--TestName">
                Testname
              </div>
            </div>
            <div className="createDowntime--DowntimeList">
              {selectedRows.map((row) => (
                <div
                  key={row.productID + row.testID}
                  className="createDowntime--DowntimeList--RowWrap"
                >
                  <div className="createDowntime--DowntimeList--ElementWrap--ProductName">
                    {row.productDisplayname}
                  </div>
                  <div
                    key={row.origin + row.productID}
                    className="createDowntime--DowntimeList--ElementWrap--Origin"
                  >
                    {row.origin}
                  </div>
                  <div
                    key={row.testDisplayname + row.productID}
                    className="createDowntime--DowntimeList--ElementWrap--TestName"
                  >
                    {row.testDisplayname}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="createDowntime--createDowntimeBtn-Wrapper">
            <div
              className="createDowntime--createDowntimeBtn"
              onClick={() =>
                createDowntime(
                  startDate,
                  endDate,
                  selectedRows,
                  document.getElementById("commentTextArea").value,
                  editId
                )
              }
            >
              {buttonText}
            </div>
          </div>
        </div>
      </div>
    );
  };

  /**
   * Funktion zum löschen einer Downtime
   * @param {*} downtimeID
   */
  function deleteDowntime() {
    const idToken = props.receiveIDToken();
    const headers = { Authorization: idToken };
    downtimeOverviewAPI
      .delete("/" + toDelete, { headers })
      .then((response) => {
        props.handleNotify({
          type: "success",
          message: "Downtime Erfolgreich gelöscht!",
        });
      })
      .catch((error) => {
        props.handleNotify({
          type: "error",
          message: "Downtime konnte nicht gelöscht werden",
        });
      });

    setDowntimeCreate(!downtimeCreate);
    setShowDelete(false);
  }

  function deletePrepare(downtime_id) {
    setShowDelete(true);
    setToDelete(downtime_id);
  }

  function deleteAbort() {
    setShowDelete(false);
    setToDelete();
  }

  /**
   * Komponente welche die DOwntimes auflistet
   * @returns
   */
  const ListDowntimes = () => {
    return (
      <>
        {activeDowntimes.data.map((downtime) => (
          <div
            key={downtime._id + random(100)}
            className="activeDowntimeList--ElementContainer"
          >
            <div className="activeDowntimeList--Header">
              {/*
              <div className="activeDowntimeList--Header--TextElement">
                {downtime._id}
              </div>
              */}
              <div className="activeDowntimeList--Header--TextElement">
                Ersteller: {downtime.created_by}
              </div>
              <div className="activeDowntimeList--Header--IconEdit">
                <FontAwesomeIcon
                  icon="fa-regular fa-pen-to-square"
                  onClick={() => {
                    prepareEditDowntimes(downtime);
                  }}
                />
              </div>
              <div className="activeDowntimeList--Header--IconDelete">
                <FontAwesomeIcon
                  icon="fa-solid fa-trash"
                  onClick={() => {
                    //deleteDowntime(downtime._id);
                    deletePrepare(downtime._id);
                  }}
                />
              </div>
            </div>
            <div className="activeDowntimeist--CommentContainer">
              {downtime.comment}
            </div>
            <div className="activeDowntimeList--StartEndeWrap">
              <div className="activeDowntimeList--StartElement">
                Start:{" "}
                {new Date(downtime.start_time).toLocaleString("de-DE", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </div>
              <div className="activeDowntimeList--StartElement">
                Ende:{" "}
                {new Date(downtime.end_time).toLocaleString("de-DE", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </div>
            </div>
            <div className="activeDowntimeList--TestList">
              {downtime.tests.map((test) => {
                const objectToFind = data.data.find(
                  (obj) => obj.testID === test
                );
                return objectToFind ? (
                  <div
                    key={objectToFind.testID}
                    className="activeDowntimeList--TestList--TestLineWrapper"
                  >
                    <div className="activeDowntimeList--TestList--TestLineElement">
                      {objectToFind.productDisplayname}
                    </div>
                    <div className="activeDowntimeList--TestList--TestLineElement">
                      {objectToFind.origin}
                    </div>
                    <div className="activeDowntimeList--TestList--TestLineElement">
                      {objectToFind.testDisplayname}
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          </div>
        ))}
      </>
    );
  };

  /**
   * Funktion, welche das Downtime erstellung Overlay anzeigt
   * @param {*} selectedRows
   */
  function showDowntimeCreate(selectedRows) {
    if (selectedRows.length === 0) {
      props.handleNotify({
        type: "info",
        message: "Es muss mindestens ein Test ausgewählt sein!",
      });
    } else {
      setEditDowntime();
      setdowntimeCreateVisible(true);
    }
  }

  /***
   *     __   __            __   ___ ___       __
   *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ |
   *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \|
   *
   */

  return (
    <>
      <div className="downtimeAdminWrap">
        <div className="downtimeAdminContent">
          <Popup open={showDelete} closeOnDocumentClick onClose={setShowDelete}>
            <div className="modal">
              <div className="header"> Downtime wirklich löschen? </div>
              <div className="actions">
                <button
                  className="button"
                  onClick={() => {
                    deleteDowntime();
                  }}
                >
                  JA
                </button>
                <button
                  className="button"
                  onClick={() => {
                    deleteAbort();
                  }}
                >
                  NEIN
                </button>
              </div>
            </div>
          </Popup>
          {downtimeCreateVisible ? (
            <DowntimeCreate selectedRows={selectedRows} />
          ) : null}
          <div className="downtimeAdminContent--Header">
            <div className="downtimeAdminContent--Header--Name">
              Downtimeverwaltung
            </div>
            <div className="downtimeAdminContent--Header--Close">
              <FontAwesomeIcon
                className="downtimeAdminContent--Header--Close--Icon"
                icon="fa-regular fa-rectangle-xmark"
                onClick={() => props.handleToggleDowntime()}
              />
            </div>
          </div>
          <div className="downtimeAdminContent--Content">
            <div className="downtimeAdminContent--Content--ConfigWrap">
              <Table
                columns={columns}
                data={data.data}
                onRowSelect={(rows) => setSelectedRows(rows)}
              />
            </div>
            <div className="downtimeAdminContent--Content--DisplayWrap">
              <div className="downtimeAdminContent--Content--DisplayHeader">
                Aktive Downtimes
              </div>
              <div className="downtimeAdminContent--Content--DisplayContent">
                <ListDowntimes />
              </div>
              <div className="downtimeAdminContent--Content--CreateBtnWrap">
                <div
                  className="downtimeAdminContent--Content--CreateBtn"
                  onClick={() => {
                    showDowntimeCreate(selectedRows);
                  }}
                >
                  Downtime erstellen
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(DowntimeAdmin);
