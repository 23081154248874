import React, {memo} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropagateLoader from 'react-spinners/PropagateLoader';
import MiniGraph from "./MiniGraph";
import { cloudflareZonesAPI } from "../http-common";                                                    
import { cloudflareEventsAPI } from "../http-common";                                                   
import { Tooltip } from 'react-tooltip';
import '../styles/ProductOverviewWidget.css';
import '../styles/Cloudflare.css';
import 'react-tooltip/dist/react-tooltip.css';

import axios from "axios";

import { mauiServices } from "../http-common";                                                   



const CloudflareWidget = (props) => {
       
    /***
     *     __  ___      ___  ___  __  
     *    /__`  |   /\   |  |__  /__` 
     *    .__/  |  /~~\  |  |___ .__/ 
     *                                
     */

    const [cloudflareZones, setCloudflareZones] = React.useState();
    const [configMenuStatus, setConfigMenuStatus] = React.useState(false);   
    const [widgetOptions, setWidgetOptions] = React.useState(() => {
        
        let usedIndex = -1

        if(props.savedOptionsArray !== undefined){
            props.savedOptionsArray.forEach(function(widget, index) {
                
                if(widget.i !== undefined){
                    let string = widget.i
                    let subBefore= string.substring(0, string.indexOf('_'));
                    
                    if(subBefore === "cloudflarewidget"){
                        usedIndex = index;
                    } 
                }
            })
    
            let defaultTimerange = '30min'
    
            if(props.savedOptionsArray[usedIndex] !== undefined){
                defaultTimerange = props.savedOptionsArray[usedIndex].optionDefaultTimerange
            }
    
            let options = {
                i: props.id,            
                optionDefaultTimerange: defaultTimerange,
            };
    
            return options;
        } else {
            return '7d'
        }

    });    

    const [optionHasChanged, setOptionHasChanged] = React.useState(false)                                 
    const [isLoaded, setIsLoaded] = React.useState(false);                                       // Indikator ob Daten geladen wurden.       
    const [hasError, setHasError] = React.useState(false);                                      // Indikator ob Fehler vorliegen


  /***********************************************************************************************************
  /********************************************* DATENABRUF **************************************************
  /********************************************************************************************************** */  

const fetchZones = async () => {  
    setCloudflareZones([])

    const idToken = props.receiveIDToken();
    const headers = { 'Authorization': idToken };
    await cloudflareZonesAPI.get("", {headers})
    .then(response => {
  
      let obj = response.data;
      setCloudflareZones(obj)
      setIsLoaded(true)
      return obj
  
    })
    .catch(function (error) {
      console.log("Fetch error Zones" + error)
      setHasError(true)
      let obj = [];
      return (obj)
    });  
  }  


    /***
     *          __   ___     ___  ___  ___  ___  __  ___  __  
     *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__` 
     *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/ 
     *                                                        
     */

    React.useEffect(() => {        
        fetchZones();
        //fetchMaui(); 
    }, []);


    React.useEffect(() => {        
        if(optionHasChanged === true){
            //console.log("Option has changed in Cloudflare")
            props.syncOptionsArray(props.id, widgetOptions)
            setOptionHasChanged(false)
            toggleConfigMenuStatus();
            fetchZones();
        } else {
            //console.log("No Option has been changed in Productoverview")
        }                
    }, [widgetOptions]);


    /***
     *     ___            __  ___    __        __  
     *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
     *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
     *                                             
     */

     
    const toggleConfigMenuStatus = () => {
        setConfigMenuStatus(!configMenuStatus)
    }

    const toggleDefaultTimerange = (range) => {
        setOptionHasChanged(true)
        setWidgetOptions({ ...widgetOptions, optionDefaultTimerange: range });
    }

 
    /***********************************************************************************
     * DOM für das Konfigurationsmenü generieren 
     ***********************************************************************************/
    const FillConfigMenu = () => {
        return(
            <div className="productWidget--configMenuWrap">
                <div className="productWidget--HeaderWrap drag-handle">
                    <div className="productWidgetHeader--Title">Cloudflare Widget - Konfiguration</div>
                    <button
                        className="productWidgetHeader--MenuBtn"
                        onClick={() => toggleConfigMenuStatus()}
                    >
                        <FontAwesomeIcon icon="fa-regular fa-rectangle-xmark" />
                    </button>
                </div>
                <div className="productWidgetConfig--ContentWrap">

                    <div className="productWidgetConfigContent--HeaderWrap">
                        <div className="productWidgetConfigContent--HeaderTitle">Default Zeitraum:</div>
                        <div className="productWidgetConfigContent--LineBreak"></div>
                        <div className="productWidgetConfigContent--HeaderSubTitle"></div>
                    </div>
                    
                    <div className="productWidgetConfigContent--ContentWrap">
                        
                        {(() => {
                            if (widgetOptions.optionDefaultTimerange === '7d') {
                            return (
                                <>
                                    <button className="cloudflareConfigBtn clousflareConfigActive" onClick={() => toggleDefaultTimerange('7d')}>7 Tage</button>                                                                  
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('24h')}>24 Stunden</button>                                                                  
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('6h')}>6 Stunden</button>                                                                  
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('30m')}>30 Minuten</button>
                                </>
                            )
                            } else if (widgetOptions.optionDefaultTimerange === '24h') {
                            return (
                                <>
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('7d')}>7 Tage</button>                                                                  
                                    <button className="cloudflareConfigBtn clousflareConfigActive" onClick={() => toggleDefaultTimerange('24h')}>24 Stunden</button>                                                                  
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('6h')}>6 Stunden</button>                                                                  
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('30m')}>30 Minuten</button>
                                </>
                            )
                            } else if (widgetOptions.optionDefaultTimerange === '6h') {
                            return (
                                <>
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('7d')}>7 Tage</button>                                                                  
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('24h')}>24 Stunden</button>                                                                  
                                    <button className="cloudflareConfigBtn clousflareConfigActive" onClick={() => toggleDefaultTimerange('6h')}>6 Stunden</button>                                                                  
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('30m')}>30 Minuten</button>
                                </>
                            )
                            } else if (widgetOptions.optionDefaultTimerange === '30m') {
                            return (
                                <>
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('7d')}>7 Tage</button>                                                                  
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('24h')}>24 Stunden</button>                                                                  
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('6h')}>6 Stunden</button>                                                                  
                                    <button className="cloudflareConfigBtn clousflareConfigActive" onClick={() => toggleDefaultTimerange('30m')}>30 Minuten</button>
                                </>
                            )                        
                            } else {
                            return (
                                <>
                                    <button className="cloudflareConfigBtn clousflareConfigActive" onClick={() => toggleDefaultTimerange('7d')}>7 Tage</button>                                                                  
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('24h')}>24 Stunden</button>                                                                  
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('6h')}>6 Stunden</button>                                                                  
                                    <button className="cloudflareConfigBtn" onClick={() => toggleDefaultTimerange('30m')}>30 Minuten</button>
                                </>
                            )
                            }
                        })()}
                                                                                            
                    </div>

                </div>
                <div className="productWidgetConfig--DeleteWrap">
                    <button
                        className="productWidgetHeader--DeleteBtn"
                        onClick={() => props.handleDelete(props.id)}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-trash" /> Widget entfernen
                    </button>
                </div>
            </div>
        )
    }


    /***
     *     __   __            __   ___ ___       __       
     *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
     *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
     *                                                    
     */

    return(
        <>
            {configMenuStatus ?  <FillConfigMenu /> : null}
            <div className="productWidget--HeaderWrap drag-handle">
                <div className="productWidgetHeader--Title">Cloudflare Firewall - {widgetOptions.optionDefaultTimerange}</div>
                <button
                    className="cloudflareWidgetHeader--RefreshBtn"
                    onClick={() => fetchZones()}
                >
                    <FontAwesomeIcon icon="fa-solid fa-arrows-rotate" />
                </button>                
                <button
                    className="productWidgetHeader--MenuBtn"
                    onClick={() => toggleConfigMenuStatus()}
                >
                    <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                </button>
            </div>
            <div className="cloudflareWidget--ContentWrap">

            {
                (() => {
                    if (isLoaded === false)
                        return(
                            <div className="productWidget--LoadingWrap">
                                <p className="productWidget--LoadingWrapText">Lade Daten</p>
                                <p><PropagateLoader color={'#6f7277'} size={4} /></p>
                            </div> 
                        )

                    if (hasError === true)
                        return <div className="productWidget--ErrorWrap">Error</div>
                    else 
                        return(
                            cloudflareZones.map((position) => {
                                return(
                                    <MiniGraph
                                        key={position.zoneTag}
                                        zoneTag={position.zoneTag}   
                                        name={position.name} 
                                        link={position.link} 
                                        timerange={widgetOptions.optionDefaultTimerange}
                                        receiveIDToken={props.receiveIDToken}
                                    ></MiniGraph>
                                )  
                            })
                        )
                })()
            }
            </div>
        </>
    )

}

export default memo(CloudflareWidget)
