import React, { useCallback, memo, useRef } from "react";
import './styles/App.css';
import MainMenu from "./components/MainMenu";
import Header from "./components/Header"
import DowntimeAdmin from "./components/DowntimeAdmin";
import SynmonAnalyse from "./components/SynmonAnalyse";
import Popup from 'reactjs-popup';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import DashboardSystemWithHooks from "./components/Content"
import io from "socket.io-client";  
import { ToastContainer, toast } from 'react-toastify';
import { availableDashboardsAPI } from "./http-common";                                                    // Deconstruct der benötigten API
import PropagateLoader from 'react-spinners/PropagateLoader';
import useLocalStorage from 'use-local-storage'

// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError, PublicClientApplication, AccountInfo, Configuration } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./utils/MsGraphApiCall";

import 'react-toastify/dist/ReactToastify.css';
import './styles/react-tooltip.css';
import 'reactjs-popup/dist/index.css';

import Dashboards from './DashboardData.js'
import { timeseriesAPI } from "./http-common";                                                                      // Deconstruct der benötigten API
//import { socket } from './socket';
import { setupAPI } from "./http-common";

library.add(fas, far, faTwitter, faFontAwesome)

 


/***
 *        _   ___ ___   ___ _   _ _  _  ___ _____ ___ ___  _  _ 
 *       /_\ | _ \ _ \ | __| | | | \| |/ __|_   _|_ _/ _ \| \| |
 *      / _ \|  _/  _/ | _|| |_| | .` | (__  | |  | | (_) | .` |
 *     /_/ \_\_| |_|   |_|  \___/|_|\_|\___| |_| |___\___/|_|\_|
 *                                                              
 */

const App = ()=> {
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  }

/***
 *     __  ___      ___  ___  __  
 *    /__`  |   /\   |  |__  /__` 
 *    .__/  |  /~~\  |  |___ .__/ 
 *                                
 */

  const [showWhatsNew, setShowWhatsNew] = React.useState(false)

  const latestWhatsNewDate = 1757026800000;                                                        // Damit neue Whats New Popup erscheinen, muss hier bei jeder Aktualisierung dieser das Datum angepasst werden

  const changeDashboardOverrideID = useRef(0);

  // *************************************************************************************
  // Dashboard handling States
  // *************************************************************************************
  const [availabledashboards, setAvailableDashboards] = React.useState();
  const [shownDashboardID, setShownDashboardID] = React.useState("default");
  const [shownDashboardName, setShownDashboardName] = React.useState("Default Dashboard");
  const [newDashboardName, setNewDashboardName] = React.useState("Default Dashboard");
  const [activeWidgets, setActiveWidgets] = React.useState([
    { i: "productoverview_default", x: 0, y: 0, w: 4, lgw: 6, minW: 4, maxW: 11, h: 25, lgh: 40, minH: 10, maxH: 500, drag: true, resize: true },
    { i: "graphcontainer_default", x: 4, y: 0, w: 18, lgw: 6, minW: 6, maxW: 22, h: 25, lgh: 10, minH: 10, maxH: 500, drag: true, resize: true}
  ]);
  const [dashboardDataAvailable, setDashboardDataAvailable] = React.useState(false);
  const [dashboardFatalError, setDashboardFatalError] = React.useState(false);
  const [dashboardCount, setDashboardCount] = React.useState();
  const [savedWidgetArray, setSavedWidgetArray] = React.useState()
  const [savedOptionsArray, setSavedOptionsArray] = React.useState()

  const [addWidgetType, setAddWidgetType] = React.useState()  
  const [childKey, setChildKey] = React.useState(1);  // State um den grid-layout key zu tracken
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [firstSyncAfterLoad, setFirstSyncAfterLoad] = React.useState(true);
  const [showSaveModal, setShowSaveModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showUnsafedChangesModal, setShowUnsafedChangesModal] = React.useState(false);  
  const [markedToBeDeleted, setMarkedToBeDeleted] = React.useState({dashboardID: "",dashboardName: ""});
  // *************************************************************************************
  // END Dashboard handling States
  // *************************************************************************************

  const closeWhatsNewWindow = () => {
                                      setShowWhatsNew(false);  
                                      localStorage.setItem("whatsNewDate", latestWhatsNewDate);
                                    }
  const openWhatsNewWindow = () =>  {
                                      setShowWhatsNew(true);  
                                      localStorage.setItem("whatsNewDate", latestWhatsNewDate);
                                    }

  const { instance, inProgress, accounts } = useMsal();
  const [graphData, setGraphData] = React.useState(null);
  const [productDataIsLoaded, setProductDataIsLoaded] = React.useState(false);                // Indikator ob Daten geladen wurden. 
  const [productData, setProductData] = React.useState();

  const [downtimeAdminVisible, setDowntimeAdminVisible] = React.useState({
    shown: false
  })
  const [synmonAnalyseVisible, setSynmonAnalyseVisible] = React.useState({
    shown: false,
    data: {}
  })  
  const [errorDetailsVisible, seterrorDetailsVisible] = React.useState(false)
  
  const [timeseries, setTimeseries] = React.useState([]);
  const [visibleGraphs, setVisibleGraphs]  = React.useState([]);
  const [replaceGraph, setReplaceGraph] = React.useState(false)                                // Indikator ob die Graphen immer weiter zum Widget hinzugefügt werden oder die vorhandenen ersetzen
  const [graphDefaultTimerange, setGraphDefaultTimerange] = React.useState("6h") 
  const [showPrioFlags, setShowPrioFlags] = React.useState(false)                              // Indikator ob die Priostörungs Flags auf den Graphen mit angezeigt werden sollen
  const [showDeploymentFlags, setShowDeploymentFlags] = React.useState(false)                  // Indikator ob die Deployment Flags auf den Graphen mit angezeigt werden sollen


  // State um Windows resize Event zu detecten
  const [windowResizing, setWindowResizing] = React.useState(false);

  // State für die Sichtbarkeit des Hauptmenüs
  const [mainMenuStatus, setMainMenuStatus] = React.useState({
      shown: false
  })

   
  /**
   * get ID Token from local Storage
   */
  const getIDToken = useCallback(() => {
    
    //instance.acquireTokenSilent()
        
    let idToken = ""
    let accessToken = ""
    let accessTokenE2E = ""
    let accessTokenMS = ""
    let refreshToken = ""

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if(key.includes("accesstoken")){
        const lsObject = JSON.parse(localStorage.getItem(key))
        if (lsObject.target === "api://ac67ef6f-69d9-4ad1-9c6c-5a54d02bac02/e2e.all") {
          accessTokenE2E = lsObject.secret;
          //console.log("getting e2e accesstoken: ", accessTokenE2E);
        } else {
          accessTokenMS = lsObject.secret;
        }
        //console.log("lsObjectMS: ", accessTokenMS);
        //console.log("lsObjectE2E: ", accessTokenE2E);
        accessToken = lsObject.secret;
        // console.log("Access Token: " + accessToken);
      } else if(key.includes("refreshtoken")){
        const lsObject = JSON.parse(localStorage.getItem(key))
        refreshToken = lsObject.secret;
        //console.log("Refresh Token: " + lsObject.secret);        
      } else if(key.includes("idtoken")){
        const lsObject = JSON.parse(localStorage.getItem(key))
        idToken = lsObject.secret;
        //console.log("Gathered Token from LS: " + idToken)
      }      

    }    
    return(accessTokenE2E)

  }, [])

  
  /** 
   * ID Token refresh
   */
  const refreshIDToken = useCallback(async () => {
    let idToken;
    let accessToken;
    const account = instance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: ["api://ac67ef6f-69d9-4ad1-9c6c-5a54d02bac02/e2e.all"],
      account: accounts[0],
    };

    try {
      const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
      accessToken = accessTokenResponse.accessToken;
      idToken = accessTokenResponse.idToken;
      //console.log("Fetched ID Token: " + idToken);
    } catch (error) {
      console.log(error);
    }

    return accessToken;
  }, []);


  // State für dynamische Infos im Seiten Header
  const [headerData, setHeaderData] = React.useState(() => {
    
    let timestamp = new Date();
    timestamp = timestamp.toLocaleString('de-DE');
    let statusText = "Letzte Aktualisierung: " + timestamp;

    return {
      title: "Zentrales Monitoring Dashboard",
      description: statusText,
      username: "Maximilian Mustermann"
    }
  })

  // State für die verfügbaren Dashboards
  const [dashboardData, setDashboardData] = React.useState(Dashboards);



  /***********************************************************************************************************
  /********************************************* DATENABRUF **************************************************
  /********************************************************************************************************** */  

/**
 * Bei jedem erstaufruf des Dashboards den User im Backend melden um ggf. den User in der DB anzulegen
 * @param {*} userObject MS Graph API response
 */
const setupUser = async (userObject) => {
  //console.log(userObject)
  const idToken = getIDToken();
  const headers = { 'Authorization': idToken };
  try {
    await setupAPI.post('', userObject, {headers});
    //console.log("User Setup ran!");
    return "ok";
  } catch (error) {
    console.log("Fetch error while user setup" + error);
    setDashboardFatalError(true);
    let obj = [];
    return obj;
  }
};


/**
 * Alle für den User verfügbaren Dashboards abrufen
 * @param {*} userID MS Graph API
 */
const fetchDashboards = async (userID) => {  

  const idToken = getIDToken();
  const headers = { 'Authorization': idToken };
  await availableDashboardsAPI.get("?userId=" + userID, {headers})
  .then(response => {

    let obj = response.data;
    setAvailableDashboards(obj)

    //console.log("Available dashboards:")
    //console.log(obj)    

    const activeDashboardIndex = response.data.findIndex((dashboard) => dashboard.active === true);    

    if(activeDashboardIndex !== -1){
      /*
      console.log("Active Dashboard Index: " + activeDashboardIndex)
      console.log("Active Dashboard ID: " + obj[activeDashboardIndex]["_id"])
      console.log("Active Dashboard Widgets: ")
      console.log(obj[activeDashboardIndex]["widgets"])
      */

      setShownDashboardID(obj[activeDashboardIndex]["_id"])
      setActiveWidgets(obj[activeDashboardIndex]["widgets"])
      setDashboardCount(obj[activeDashboardIndex]["count"])
      setShownDashboardName(obj[activeDashboardIndex]["name"])
      setSavedOptionsArray(obj[activeDashboardIndex].options)

      obj[activeDashboardIndex].options.forEach(function (arrayItem) {
        
        if(arrayItem.i !== undefined){
          let string = arrayItem.i
          let subBefore= string.substring(0, string.indexOf('_'));
          if(subBefore === "graphcontainer"){
            setGraphDefaultTimerange(arrayItem.graphDefaultTimerange)
            setVisibleGraphs(arrayItem.visibleGraphOptions)
            setReplaceGraph(arrayItem.replaceGraphOption)
          }
        }
      });
      
      setDashboardDataAvailable(true)
    } else {
      setDashboardCount(0)
      setSavedOptionsArray([])
      setDashboardDataAvailable(true)
    }
    
    return obj

  })
  .catch(function (error) {
    console.log("Fetch error available Dashboards" + error)
    setDashboardFatalError(true);
    let obj = [];
    setTimeout(() => {
      console.log("Erneutes Aufrufen der Dashboard API wird versucht")
      setDashboardFatalError(false)
      fetchDashboards(userID);
    }, 2000);
    return (obj)
  });

}  




  /***
   *          ___  __   __   __   __        ___ ___                    __               __  
   *    |  | |__  |__) /__` /  \ /  ` |__/ |__   |     |__|  /\  |\ | |  \ |    | |\ | / _` 
   *    |/\| |___ |__) .__/ \__/ \__, |  \ |___  |     |  | /~~\ | \| |__/ |___ | | \| \__> 
   *                                                                                        
   */
 
  
  /*
  const idToken = getIDToken();


  const socket = io(process.env.REACT_APP_BACKEND_SVC, {
    path: "/ts/socket.io",
    auth: {
      token: idToken
    }
  }); 
 */

  //Room State
  //const [room, setRoom] = React.useState("");
  //const [isConnected, setIsConnected] = React.useState(socket.connected);
  //const [lastPong, setLastPong] = React.useState(null);

  // Messages States
  //const [message, setMessage] = React.useState("");
  //const [messageReceived, setMessageReceived] = React.useState("");

  /*
  const joinRoom = () => {
    if (room !== "") {
      socket.emit("join_room", room);
      console.log("joined: " + room)
    }
  };

  const sendMessage = () => {
    socket.emit("send_message", { message, room });
    console.log("send!")
  };
  */
  
  const [isConnected, setIsConnected] = React.useState(false);;

  React.useEffect(() => {

    //console.log("Websocket useEffect is called")
    const idToken = getIDToken();
    const socket = io(process.env.REACT_APP_BACKEND_SVC, {
      path: "/ts/socket.io",
      auth: {
        token: idToken
      }
    });

    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    /*
    socket.on('pong', () => {
      setLastPong(new Date().toISOString());
    });

    socket.on("receive_message", (data) => {
      setMessageReceived(data.message);
    });
    */

    socket.on('productData',(data) => {
      //console.log(data.message);     
      refreshIDToken().then((token) => {
        //console.log("New Token: ")
        //console.log(token)
        socket.auth.token = token;
      });

      let timestamp = new Date();
      timestamp = timestamp.toLocaleString('de-DE');
      let statusText = "Letzte Aktualisierung: " + timestamp;
     
      setHeaderData(prevStatus => ({
        ...prevStatus,
        description: statusText
      }))   

      setProductData(data.message)    
      setProductDataIsLoaded(true) 
      
    });

    /*
    socket.on('cloudflare',(data) => {
      console.log(data.message);
    });
    */

    socket.on("connect_error", (data) => {

      const errormessage = data.message

      if(errormessage === "invalid token"){
        console.log("Der Token wurde vom Websocket abgelehnt und scheint ungültig zu sein. Versuche Token refresh...")
        refreshIDToken().then((token) => {
          socket.auth.token = token;
          setTimeout(() => {
            socket.connect();
          }, 1000);
        });
      } else {
        console.log("Ran into an error while gathering websocket Data!")
        //notify({type: 'alert', message: 'Es können keine Produktübersicht Daten empfangen werden!Wir versuchen es in Kürze erneut.'})
        setTimeout(() => {
          socket.connect();
        }, 10000);
      }
      
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('productData');
    };

  }, []);  



  /***
   *          __   ___     ___  ___  ___  ___  __  ___  __  
   *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__` 
   *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/ 
   *                                                        
   */

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Abruf der Azure AD Graph Daten
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  React.useEffect(() => {
    
    if (!graphData && inProgress === InteractionStatus.None) {
        callMsGraph()
        .then(response => 
          { 
            //console.log(response)
            refreshIDToken();
            
            setupUser(response).then((userResponse) => {
              setGraphData(response);
              fetchDashboards(response.id);
            })

          })
          
        .catch((e) => {
            if (e instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect({
                    ...loginRequest,
                    account: instance.getActiveAccount()
                });
            }
        });
    } 

  }, [inProgress, graphData, instance]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Prüfen und Anzeigen der Whats New Info
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  React.useEffect(() => {
    const whatsNewDate = JSON.parse(localStorage.getItem('whatsNewDate')) 
    
    if(whatsNewDate !== undefined){

        if(whatsNewDate !== latestWhatsNewDate){
          localStorage.setItem("whatsNewDate", latestWhatsNewDate);
          setShowWhatsNew(true)
        } else {
          setShowWhatsNew(false)
        }

    } else {
        localStorage.setItem("whatsNewDate", latestWhatsNewDate);
        setShowWhatsNew(true)
    }

  }, []);  
  




  /*
  React.useEffect(() => {
    let timeout;
    const handleResize = () => {
      clearTimeout(timeout);
      setWindowResizing(true);

      timeout = setTimeout(() => {
        setWindowResizing(false);
       
        //notify({type: 'info', message: 'Resize event has ended!'})

        // grid Layout key verändern. Dies forced den component unmount und triggert somit ein resize der einzelnen Widgets
        setChildKey(prev => prev + 1);
        
      }, 200);
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  */

  /***
   *     ___            __  ___    __        __  
   *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
   *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
   *                                             
   */




  /**
   * Alert Box Handling
   * Bereitstellung von Alert Boxen die mit den Parametern 'type' und 'message' befüllt werden können
   * @param {*} param 
   */   

  const notify = useCallback((param) => {

    const notifyInfo = (message) => toast.info(message, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    const notifyInfoPerm = (message) => toast.success(message, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
    });

    const notifySuccess = (message) => toast.success(message, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    }); 
    
    const notifyError = (message) => toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    }); 

    const notifyErrorLong = (message) => toast.error(message, {
      position: "bottom-right",
      autoClose: 50000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });     

    const notifyWarn = (message) => toast.warn(message, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });    

    switch (param.type) {
      case 'alert':
        notifyError(param.message)
        break;
      case 'alertLong':
          notifyErrorLong(param.message)
          break;        
      case 'warning':
        notifyWarn(param.message)
        break;
      case 'success':
        notifySuccess(param.message)
      break; 
      case 'infoPerm':
        notifyInfoPerm(param.message)
      break;              
      default:
        notifyInfo(param.message)
    }
  }, [])

  const toggleMainMenuStatus = useCallback((setTo) => {
    //console.log("Main menu") 
    setMainMenuStatus(prevStatus => ({
      ...prevStatus,
      shown: setTo
    }))
  }, [])


  const toggleDowntime = useCallback(() => {
    let setTo = downtimeAdminVisible.shown;
    console.log("DowntimeVisible: " + setTo)
    if(downtimeAdminVisible.shown === true){
      setTo = false;
      console.log("Set to False")
    }else{
      setTo=true;
      console.log("Set to True")

    }
    console.log(setTo)

    setDowntimeAdminVisible(prevStatus => ({
      ...prevStatus,
      shown: setTo
    }))
  }, [downtimeAdminVisible])



  const toggleSynmonAnalyse = useCallback((param) => {
    
    let setTo = synmonAnalyseVisible.shown;
    console.log("SynmonAnalyseVisible: " + setTo)
    if(synmonAnalyseVisible.shown === true){
      setTo = false;
      console.log("Set to False")
    }else{
      setTo=true;
      console.log("Set to True")

    }
    console.log(setTo)

    setSynmonAnalyseVisible(prevStatus => ({
      ...prevStatus,
      shown: setTo,
      data: param
    }))
  }, [synmonAnalyseVisible])  

  

    /***********************************************************************************************************
    /******************************************* GRAPH HANDLING START ******************************************
    /***********************************************************************************************************
     /**
     * Löschen eines Graphen
     * @param {*} key Erwartet ID des Graphen
     */
     const deleteSingleGraph = useCallback((key) => {
      console.log("Delete: " + key)

      setVisibleGraphs(oldValues => {
        return oldValues.filter(obj => obj.testID !== key)
      })
    }, [])

     /**
     * Löschen aller Graphen
     * @param {*} key Erwartet ID des Graphen
     */
     const deleteAllGraph = useCallback(() => {
      console.log("Delete all Graphs")
      setVisibleGraphs([])
    }, [])

    /**
     * Hinzufügen aller Test eines Produktes zum Graph container
     * @param {*} test 
     * @param {*} product 
     */
    const addAllGraphs = (test, product) => {
      const productIndex = productData.findIndex((searchedItem) => searchedItem._id === product);
      if(replaceGraph === true){
        deleteAllGraph();
      }
      productData[productIndex].tests.forEach(function(testElement) {
        if(testElement.origin === "synthetic"){
          addGraph(testElement._id, productData[productIndex]._id, testElement.displayName, productData[productIndex].displayName, 'all')
        }
      })
    }

    /**
     * Hinzufügen eines Graphen zum Graph Container
     */
    const addGraph = (test, product, displayName, productName, type) => {

        const checkIfAlreadyExist = savedWidgetArray.find(e => e.type === 'graphcontainer');
        
        if(checkIfAlreadyExist === undefined){
          addNewWidget('graphcontainer', true)
        } 

        const productIndex = productData.findIndex((searchedProduct) => searchedProduct._id === product);
        const productTestIndex = productData[productIndex].tests.findIndex((searchedTest) => searchedTest._id === test);
        const visibleGraphsIndex = visibleGraphs.findIndex((searchedItem) => searchedItem.testID === test);
      
        if(replaceGraph === true && type !== 'all'){
          deleteAllGraph();
        }

        if(visibleGraphsIndex === -1 || replaceGraph === true){
          const newGraph = {
            testID: test,
            productID: product,
            displayName: displayName,
            productName: productName,
            thresholds: productData[productIndex].tests[productTestIndex].thresholds
          };
  
          setVisibleGraphs(prev => [
            ...prev,
            newGraph
          ])
  
        } else {
          console.log("Graph is already visible")
          notify({type: 'info', message: 'Dieser Graph wird bereits dargestellt.'})
        }        
    }


    const changeGraphOptions = (options) => {
      setReplaceGraph(options.replaceGraph)
      setGraphDefaultTimerange(options.timerange)
      syncOptionsArray("graphcontainer_default", options)
    }

    
  /***********************************************************************************************************
  /********************************************* GRAPH HANDLING END ******************************************
  /********************************************************************************************************** */

  const addNewWidget = (widgetType, fromGraph) => {
    console.log("Add: " + widgetType)
    let checkIfAlreadyExist = null

    if(savedWidgetArray !== undefined){
      savedWidgetArray.forEach(function(item) {

        let string = item.i
        let stringBefore= string.substring(0, string.indexOf('_'));  
        if(stringBefore === widgetType){
          checkIfAlreadyExist = true
        }      

      })
    }
    
    if(checkIfAlreadyExist === true){
      console.log("Widget wurde bereits gefunden")
      if(fromGraph !== true){
        notify({type: 'info', message: 'Dieses Widget befindet sich bereits im Dashboard.'})
      }
    } else {
      setAddWidgetType(widgetType)
    }
    
  }

    /**
     * Funktion welche einen Link in einem neuen Tab oeffnet
     * Der Link, welcher geoeffnet werden soll wird mitgegeben
     * @param {*} link 
     */
    const openLink = (link) => {
      window.open(link,"_blank");
  }


  
  /***********************************************************************************************************
  /********************************************* DASHBOARD HANDLING ******************************************
  /********************************************************************************************************** */
  const changeShownDashboardOverride = () => {       
      changeShownDashboard(changeDashboardOverrideID.current, true);    
  }
  
  const changeShownDashboard = (dashboardID, ignoreCheck) => {
    if(unsavedChanges === true && ignoreCheck !== true){
      changeDashboardOverrideID.current = dashboardID;
      setShowUnsafedChangesModal(true);
    } else {
      setVisibleGraphs([])
      setAddWidgetType(null)
      setShowUnsafedChangesModal(false)
      setDashboardDataAvailable(false)    
      setUnsavedChanges(false);
      setFirstSyncAfterLoad(true);
      
      const activeDashboardIndex = availabledashboards.findIndex((dashboard) => dashboard._id === dashboardID);
  
      //console.log("av Dash")
      //console.log(availabledashboards[activeDashboardIndex])

      if(activeDashboardIndex !== -1){
        setShownDashboardID(availabledashboards[activeDashboardIndex]["_id"])
        setShownDashboardName(availabledashboards[activeDashboardIndex]["name"])
        setNewDashboardName(availabledashboards[activeDashboardIndex]["name"])
        setActiveWidgets(availabledashboards[activeDashboardIndex]["widgets"])
        setDashboardCount(availabledashboards[activeDashboardIndex]["count"])
        setSavedOptionsArray(availabledashboards[activeDashboardIndex].options)
  
          availabledashboards[activeDashboardIndex].options.forEach(function (arrayItem) {
            
            if(arrayItem.i !== undefined){
              let string = arrayItem.i
              let subBefore= string.substring(0, string.indexOf('_'));
              
              if(subBefore === "graphcontainer"){
                //console.log(arrayItem)
                console.log("called!!!")
                setGraphDefaultTimerange(arrayItem.graphDefaultTimerange)
                setVisibleGraphs(arrayItem.visibleGraphOptions)
                setReplaceGraph(arrayItem.replaceGraphOption)
              }
            }
          });      
  
        setDashboardDataAvailable(true)
        setChildKey(prev => prev + 1)
        sendActiveDashboard(dashboardID)
      } else {
        setShownDashboardID(availabledashboards[activeDashboardIndex]["default"])
        setShownDashboardName(availabledashboards[activeDashboardIndex]["Default Dashboard"])
        setNewDashboardName(availabledashboards[activeDashboardIndex]["Default Dashboard"])
        setActiveWidgets([
          { i: "productoverview_default", x: 0, y: 0, w: 4, lgw: 6, minW: 4, maxW: 11, h: 25, lgh: 40, minH: 10, maxH: 500, drag: true, resize: true },
          { i: "graphcontainer_default", x: 4, y: 0, w: 18, lgw: 6, minW: 6, maxW: 22, h: 25, lgh: 10, minH: 10, maxH: 500, drag: true, resize: true}
        ])
        setDashboardCount(0)
        setDashboardDataAvailable(true)
        setChildKey(prev => prev + 1)
      }            
    }
  }



  const syncVisibleWidgets = (items)=> {
    if(savedWidgetArray !== items){
      setSavedWidgetArray(items)
      
      if(firstSyncAfterLoad === false){
        setUnsavedChanges(true);      
      } else {
        setFirstSyncAfterLoad(false)
      }
      
    } else {
      console.log("No changes found!")
    }

  }

  /**
   * Funktion um das Options Element beim Entfernen eines Widgets anzupassen
   */
  const removeFromOptions = (widgetID) => {
    console.log(widgetID)
    if(widgetID !== "productoverview_default"){
      setSavedOptionsArray(oldValues => {
        return oldValues.filter(obj => obj.i !== widgetID)
      })
    }
  }

  /**
   * Funktion um die vom user gemachten Änderungen an den Optionen zu synchronisieren
   * @param {*} widgetID  ID des Widgets, welches das Optionen Objekt an die Funtkion schickt 
   * @param {*} options Optionen Object eines Widgets
   */
  const syncOptionsArray = (widgetID, options)=> {

    //console.log("Sync called for: " + widgetID)
    //console.log("With options: ")
    //console.log(options)

    const optionIndex = savedOptionsArray.findIndex((widget) => widget.i === widgetID);
    let sendOptions = options;
    let string = widgetID
    let subBefore= string.substring(0, string.indexOf('_'));

    if(subBefore === "graphcontainer"){
        //console.log("Should be in graphcontainer now" + options)
        sendOptions = {
            i: widgetID,
            graphDefaultTimerange: options.timerange,
            replaceGraphOption: options.replaceGraph, 
            visibleGraphOptions: visibleGraphs
        };
    } 

  if(JSON.stringify(savedOptionsArray[optionIndex]) !== JSON.stringify(sendOptions)){

      //console.log("Saved Options:");
      //console.log(savedOptionsArray[optionIndex]);
      //console.log("Send Options:");
      //console.log(sendOptions);

      let tempArray = [...savedOptionsArray];  
      const index = tempArray.findIndex((element) => element.i === widgetID);
      tempArray[index] = sendOptions;

      if(index !== -1){
        if(subBefore === "productoverview"){
          tempArray[index].visibleTests.forEach(function (arrayItem) {
            arrayItem.tests = []
          });
          setSavedOptionsArray(tempArray);

        } else {
          setSavedOptionsArray(tempArray);
        }
      } else {
        if(subBefore === "graphcontainer" || subBefore === "productstatus" ){
          console.log("Found options for: " + subBefore)
          setSavedOptionsArray(current => [...current, sendOptions]);   // bei neu angelegten Widgets wird hier das zugehörige Options Objekt einfach hinzugefügt
        } else {
          setSavedOptionsArray(current => [...current, options]);       // bei neu angelegten Widgets wird hier das zugehörige Options Objekt einfach hinzugefügt
        }
      }
      

      if(firstSyncAfterLoad === false){
        setUnsavedChanges(true);      
      } else {
        setFirstSyncAfterLoad(false)
      }

    } else {
      console.log("No Option changes found!")
    }

  }

 
  const checkBeforeSaveDashboard = () => {
    setNewDashboardName(shownDashboardName)
    setShowSaveModal(true)
  }

  /**
   * Speichern des Dashboards
   */
  const saveDashboard = () => {
    if (newDashboardName !== "Default Dashboard") {
      console.log("Save Dashboard Options: ")
      console.log(savedOptionsArray)
      // JSON für das gesamte Dashboard erstellen
      const dashboardData = JSON.stringify({
        widgets: savedWidgetArray,
        options: savedOptionsArray,
        active: true,
        userId: graphData.id,
        name: newDashboardName,
        count: dashboardCount,
      });

      if (shownDashboardName === newDashboardName) {
        console.log("Dashboard Namen sind gleich = PUT!");

        const idToken = getIDToken();
        const headers = { 'Authorization': idToken };

        availableDashboardsAPI
          .put("/" + shownDashboardID, dashboardData, {headers})
          .then((response) => {
            console.log(response);
            notify({ type: "success", message: "Speichern abgeschlossen" });
            refreshAvailableDashboards(graphData.id); // Die availableDashboards mit den soeben gespeicherten Daten refreshen
            setUnsavedChanges(false);
            setShowSaveModal(false);
          })
          .catch((response) => {
            console.log(response);
            setShowSaveModal(false);
            notify({ type: "alert", message: "Speichern fehlgeschlagen" });
          });
      } else {
        //console.log("Dashboard Namen sind NICHT gleich = POST!");
        const idToken = getIDToken();
        const headers = { 'Authorization': idToken };

        availableDashboardsAPI
          .post("/", dashboardData, {headers})
          .then((response) => {
            //console.log(response);
            notify({ type: "success", message: "Speichern abgeschlossen" });
            refreshAvailableDashboards(graphData.id); // Die availableDashboards mit den soeben gespeicherten Daten refreshen
            setUnsavedChanges(false);
            setShowSaveModal(false);
          })
          .catch((response) => {
            console.log(response);
            setShowSaveModal(false);
            notify({ type: "alert", message: "Speichern fehlgeschlagen" });
          });
      }

    } else {
      notify({ type: "alert", message: "Es muss ein anderer Dashboard Name gewählt werden! Das Default Dashboard kann nicht überschrieben werden." });
    }
  };
    

  /**
   * Modal Abfrage bevor das Dashboard gelöscht wird. 
   */
  const checkBeforeDashboardDelete = (dashboardID, dashboardName) => {
    
    const dashboardObj = {
      dashboardID: dashboardID,
      dashboardName: dashboardName
    }
    setMarkedToBeDeleted(dashboardObj)
    setShowDeleteModal(true)
  }

  /**
   * Löschen eines Dashboards anhand seiner ID
   */
  const deleteDashboard = () => {

    const idToken = getIDToken();
    const headers = { 'Authorization': idToken };

    availableDashboardsAPI
    .delete("/" + markedToBeDeleted.dashboardID + "?userId=" + graphData.id, {headers})
    .then((response) => {
      //console.log(response);
      notify({ type: "success", message: "Das Dashboard: " + markedToBeDeleted.dashboardName + " wurde gelöscht!"});
      refreshAvailableDashboards(graphData.id); // Die availableDashboards mit den soeben gespeicherten Daten refreshen
      setShowDeleteModal(false);

      if(markedToBeDeleted.dashboardID === shownDashboardID){
        window.location.reload();
      }

    })
    .catch((response) => {
      console.log(response);
      setShowDeleteModal(false);
      notify({ type: "alert", message: "Löschen fehlgeschlagen" });
    });
  }

  /**
   * Ein Dashboard als das für den User aktive setzen. Dies hat zur Folge das beim nächsten Aufruf der Seite dieses Dashboard geladen wird. 
   */
  const sendActiveDashboard = (dashboardID) => {
    //console.log("Set active Dashboard for user: " + graphData.id + " to DashboardID: " + dashboardID)
    const idToken = getIDToken();
    const headers = { 'Authorization': idToken };

    availableDashboardsAPI
    .get("/" + dashboardID + "/setActive?userId=" + graphData.id, {headers})
    .then((response) => {
      //console.log(response);
      refreshAvailableDashboards(graphData.id); // Die availableDashboards mit den soeben gespeicherten Daten refreshen
    })
    .catch((response) => {
      //console.log(response);
    });    
  }

  /**
   * Nach dem Speichern müss das Hauptmenü sowie der availableDashboard State geupdated werden
   * @param {*} userID 
   */
  const refreshAvailableDashboards = async (userID) => {  
    const idToken = getIDToken();
    const headers = { 'Authorization': idToken };

    await availableDashboardsAPI.get("?userId=" + userID, {headers})
    .then(response => {
      let obj = response.data;

      const activeDashboardIndex = obj.findIndex((setActive) => setActive.active === true);

      if(activeDashboardIndex !== -1){
        setAvailableDashboards(obj)
      } else {
        const defaultDashboardIndex = obj.findIndex((dashboard) => dashboard.name === "Default Dashboard");
        sendActiveDashboard(obj[defaultDashboardIndex]._id)
      }
      
    })
    .catch(function (error) {
      console.log("Error on refresh available Dashboards" + error)
    });    
  }  

  /**
   * Event handler der bei Eingabe eines Dashboard Namens die zugehörigen States anpasst
   * @param {*} event 
   */
  const handleNewDashboardName = (event) => {
    if(event.target.value === ""){
      setNewDashboardName(shownDashboardName)
    } else {
      setNewDashboardName(event.target.value)
    }      
  }




 
  /***
   *     __   __            __   ___ ___       __       
   *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
   *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
   *                                                    
   */

  return (
    
    <div className="themeContainer" data-theme={theme}>
      <>
      <ToastContainer />
      <Popup open={showWhatsNew} closeOnDocumentClick onClose={closeWhatsNewWindow}>
        <div className="modal">
              <div className="header">Zentrales Monitoring Dashboard</div>
              <div className="content">
                {' '}
                Willkommen im zentralen monitoring Dashboard der Freenet. Hier findest Du den Ausgangspunkt in verschiedene Monitorings der Infrastruktur und erhälst einen Statusüberblick über diverse Systeme. <br /><br />
             
                <h3>September 2024 | Release der Version 2.3:</h3>

                <ul>
                  <li><b>Letzte Änderungen:</b><br/><br/>
                    <ul>  
                      <li>Die Möglichkeit GIT Produkte udn Test anzuzeigen wurde hinzugefügt.</li><br/>

                      <li>Die GIT Test sind per default ausgeblendet. Wenn ihr diese anzeigen wollt, müsst ihr die Produktübersicht entsprechend konfigurieren und das Dashboard danach speichern.</li><br/>
                      
                    </ul>
                  </li>
                </ul> 
                <br/>                                                                
                Die Roadmap für das Dashboard kannst du im folgenden aufrufen: <br /> <br/>
              </div>
              <div className="actions">
              <button
                  className="button"
                  onClick={() => {
                    console.log('open Roadmap');
                    openLink("https://freenetgroup.sharepoint.com/sites/IT_Operations/SitePages/D&D-Roadmap.aspx")
                  }}
                >
                  Roadmap
                </button>                 
                <button
                  className="button"
                  onClick={() => {
                    console.log('modal closed');
                    closeWhatsNewWindow();
                  }}
                >
                  OK
                </button>               
              </div>
            </div>
      </Popup> 
      
      <MainMenu 
        isShown={mainMenuStatus.shown} 
        handleHover={toggleMainMenuStatus}
        handleNotify={notify}
        handleToggleDowntime={toggleDowntime}
        handleAddNewWidget={addNewWidget}
        dashboardData={dashboardData}
        availableDashboards={availabledashboards}
        handleChangeShownDashboard={changeShownDashboard}
        handleDeleteDashboard={checkBeforeDashboardDelete}
        receiveIDToken={getIDToken}
        handleSwitchTheme={switchTheme}
      />

      <div className="mainWrap">
        <Popup open={showSaveModal} closeOnDocumentClick onClose={setShowSaveModal}>
            <div className="modal">
                  <div className="header"> Dashboard speichern </div>
                  
                  <div className="content">
                      <div className="saveModalStyle">
                        Name des Dashboards: <br/><br/>
                        <input className="saveModalInput"
                          type="text"
                          name="name"
                          placeholder={shownDashboardName}
                          onChange={handleNewDashboardName}
                        /><br/><br/>
                        Wenn kein Name eingegeben wird, werden wir das bestehende Dashboard überschreiben.
                      </div>
                  </div>

                  <div className="actions">
                    <button
                      className="button"
                      onClick={() => {
                        saveDashboard()
                      }}
                    >
                      SPEICHERN
                    </button>
                    <button
                      className="button"
                      onClick={() => {
                        setShowSaveModal(false)
                      }}
                    >
                      ABBRECHEN
                    </button>
                  </div>
                </div>
        </Popup> 
        <Popup open={showDeleteModal} closeOnDocumentClick onClose={setShowDeleteModal}>
            <div className="modal">
                  <div className="header"> Dashboard löschen </div>
                  
                  <div className="content">
                      <div className="saveModalStyle">
                        Soll das Dashboard: "{markedToBeDeleted.dashboardName}" wirklich gelöscht werden? 
                      </div>
                  </div>

                  <div className="actions">
                    <button
                      className="button"
                      onClick={() => {
                        deleteDashboard()
                      }}
                    >
                      LÖSCHEN
                    </button>
                    <button
                      className="button"
                      onClick={() => {
                        setShowDeleteModal(false)
                      }}
                    >
                      ABBRECHEN
                    </button>
                  </div>
                </div>
        </Popup>  
        <Popup open={showUnsafedChangesModal} closeOnDocumentClick onClose={setShowUnsafedChangesModal}>
            <div className="modal">
                  <div className="header"> Änderungen verwerfen </div>
                  
                  <div className="content">
                      <div className="saveModalStyle">
                        Es gibt ungesicherte Änderungen am Dashboard. Sollen diese wirklich verworfen werden? 
                      </div>
                  </div>

                  <div className="actions">
                    <button
                      className="button"
                      onClick={() => {
                        changeShownDashboardOverride();
                      }}
                    >
                      JA
                    </button>
                    <button
                      className="button"
                      onClick={() => {
                        setShowUnsafedChangesModal(false);
                      }}
                    >
                      NEIN
                    </button>
                  </div>
                </div>
        </Popup>                
        <Header 
          headerData={headerData} 
          isShown={mainMenuStatus.shown} 
          handleHover={toggleMainMenuStatus}
          handleWhatsNew={openWhatsNewWindow}
          userName={graphData ? graphData.displayName : ""}
          unsavedChanges={unsavedChanges}
          handleSaveDashboard={checkBeforeSaveDashboard}
          receiveIDToken={getIDToken}
        />

        <main className="contentWrap">
        {downtimeAdminVisible.shown ? <DowntimeAdmin 
                                  handleNotify = {notify}
                                  receiveIDToken={getIDToken}
                                  handleToggleDowntime={toggleDowntime}
                                  userName={graphData ? graphData.displayName : ""}
        /> : null}
        {synmonAnalyseVisible.shown ? <SynmonAnalyse 
                                  handleNotify = {notify}
                                  receiveIDToken={getIDToken}
                                  data = {synmonAnalyseVisible}
                                  handleToggleSynmonAnalyse={toggleSynmonAnalyse}
        /> : null}  

        
          {dashboardDataAvailable ? 
            <DashboardSystemWithHooks 
              key={childKey} 
              productData={productData}
              visibleGraphs={visibleGraphs}
              handleNotify={notify}
              handleAddWidget={addNewWidget}
              handleAddGraph={addGraph}
              handleAddAllGraphs={addAllGraphs}
              //handleDeleteWidget={deleteWidget}
              handleDeleteGraph={deleteSingleGraph}
              handleDeleteAllGraphs={deleteAllGraph}
              handleChangeGraphOptions={changeGraphOptions}
              handleGraphDefaultTimerange={graphDefaultTimerange}
              handleToggleSynmonAnalyse={toggleSynmonAnalyse}
              receiveIDToken={getIDToken}
              receiveAccessToken={refreshIDToken}

              shownDashboardID={shownDashboardID}
              activeWidgets={activeWidgets}
              dashboardCount={dashboardCount}
              userName={graphData ? graphData.displayName : ""}
              replaceGraph={replaceGraph}
              syncVisibleWidgets={syncVisibleWidgets}
              syncOptionsArray={syncOptionsArray}
              handleDeleteFromOptions={removeFromOptions}
              savedOptionsArray={savedOptionsArray}
              //dashboardWidgets={savedWidgetArray}
              addWidgetType={addWidgetType}
            /> : 
          <>
            <div className="dashLoading">
              {dashboardFatalError ? 
                <>
                  {<p className="productWidget--ErrorWrap">Die Dashboard Daten konnten nicht gelesen werden.<br></br>Bitte lade das Dashboard erneut und wende Dich an das Team Development & Dashboards, sollte das Problem längere Zeit bestehen.</p>}
                  <p className="productWidget--LoadingWrapText">Lade Dashboard</p>
                  <p><PropagateLoader color={'#6f7277'} size={4} /></p> 
                </>
                :
                <>
                  <p className="productWidget--LoadingWrapText">Lade Dashboard</p>
                  <p><PropagateLoader color={'#6f7277'} size={4} /></p> 
                </>
              }            
            </div> 
          </>

        }

        </main>

      </div>
      </>
    </div>
  );
}

const AppWrap = () => {
  const authRequest = {
      ...loginRequest
  };

  return (
      <MsalAuthenticationTemplate 
          interactionType={InteractionType.Redirect} 
          authenticationRequest={authRequest} 
          //errorComponent={ErrorComponent} 
          //loadingComponent={Loading}
      >
          <App />
      </MsalAuthenticationTemplate>
    )
};

export default memo(AppWrap)

