import React, {useCallback, memo} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Tooltip } from 'react-tooltip';
import { timeseriesAPI, graphPrioEventsAPI, graphDeploymentEventsAPI } from "../http-common";                                                    // Deconstruct der benötigten API
import { createTicketAPI } from '../http-common';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import exportAsImage from './exportAsImage';
import Popup from 'reactjs-popup';


import '../styles/SingleGraph.css';

// Globale Highcharts Optionen
Highcharts.setOptions({
  global: {
    useUTC: false
  },
  lang: {
    decimalPoint: ',',
    thousandsSep: '.',
    loading: 'Daten werden geladen...',
    months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    exportButtonTitle: "Exportieren",
    printButtonTitle: "Drucken",
    rangeSelectorFrom: "Von",
    rangeSelectorTo: "Bis",
    rangeSelectorZoom: "Zeitraum",
    downloadPNG: 'Download als PNG-Bild',
    downloadJPEG: 'Download als JPEG-Bild',
    downloadPDF: 'Download als PDF-Dokument',
    downloadSVG: 'Download als SVG-Bild',
    resetZoom: "Zoom zurÃ¼cksetzen",
    resetZoomTitle: "Zoom zurÃ¼cksetzen"
  }
});

const SingleGraph = (props) => {

    //console.log(props)
    const endpointURL = "/"+ props.productID +"/tests/"+ props.testID +"/performance?grouped=true"
    const eventURL = "/"+ props.productID +"/tests/"+ props.testID +"/performance/events"
    
    
    /***
     *     __  ___      ___  ___  __  
     *    /__`  |   /\   |  |__  /__` 
     *    .__/  |  /~~\  |  |___ .__/ 
     *                                
     */
    const [isLoaded, setIsLoaded] = React.useState(false);                                       // Indikator ob Daten geladen wurden.       
    const [hasError, setHasError] = React.useState(false);                                       // Indikator ob Fehler vorliegen
    const errMessage = "Nothing to see"
    const [options, setOptions] = React.useState(null);                                          // Options holds the options for the Highcharts
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [showTicketModal, setShowTicketModal] = React.useState(false);
    const [ticketIssueType, setTicketIssueType] = React.useState("service")
    
    const ref = React.useRef();
    const seriesCount = React.useRef(0);
    const prioCount = React.useRef(0);
    const deploymentCount = React.useRef(0);
    
    //console.log("Graph: " + props.testID + " re-rendered!")
    
    /***
     *          __   ___     ___  ___  ___  ___  __  ___  __  
     *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__` 
     *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/ 
     *                                                        
     */
    

    const fetchData = async () => {

      const idToken = await props.receiveAccessToken();
      //const idToken = props.receiveIDToken();
      const headers = { 'Authorization': idToken };  
      const res = await timeseriesAPI.get(endpointURL, {headers});

      const prioEvents = await graphPrioEventsAPI.get(eventURL + "?detailedString=false&type=prio_issue", {headers})
      .then(response => {
        
        if(props.showPrioFlags === true){
          let obj = {
            data:  response.data
          };
          return obj
        } else {
          let obj = {
            data:  []
          };
          return (obj)          
        }        
      })
      .catch(function (error) {
        //console.log("Fetch error prio events" +error)
        //props.handleNotify({type: 'alert', message: "Fehler beim Abrufen der Flags: Priostörungen"})
        let obj = {
          data:  []
        };
        return (obj)
      });

      //console.log("prioevents:")
      //console.log(prioEvents)

      const deploymentEvents = await graphDeploymentEventsAPI.get(eventURL + "?detailedString=false&type=deployment", {headers})
      .then(response => {
        if(props.showDeploymentFlags === true){
          let obj = {
            data:  response.data
          };
          return obj
        } else {
          let obj = {
            data:  []
          };
          return (obj)          
        }        
      })
      .catch(function (error) {
        console.log("Fetch error deployment events" + error)
        //props.handleNotify({type: 'alert', message: "Fehler beim Abrufen der Flags: Deployments"})
        let obj = {
          data:  []
        };
        return (obj)
      });
    
      //console.log("Loaded Prio:")
      //console.log(prioEvents.data)

      //console.log(res)      
      const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
          prio: prioEvents.data,
          deployments: deploymentEvents.data          
      };

      /*
      console.log("PrioData:")
      console.log(result.prio)
      console.log("deployments:")
      console.log(result.deployments)
      */
      
      return result
    }



    React.useEffect(() => {
      setIsLoaded(false);        
      let timeseriesInterval

      const fetchRecords = async () => {
          //console.log("Fetching Timeseries data..." + timeseriesAPI)
          const idToken = await props.receiveAccessToken();
          //const idToken = props.receiveIDToken();          
          const headers = { 'Authorization': idToken };
          const res = await timeseriesAPI.get(endpointURL, {headers});

          const prioEvents = await graphPrioEventsAPI.get(eventURL + "?detailedString=false&type=prio_issue", {headers})
          .then(response => {
            //console.log(response)
            if(props.showPrioFlags === true){
              let obj = {
                data:  response.data
              };
              return obj
            } else {
              let obj = {
                data:  []
              };
              return (obj)          
            }        
          })
          .catch(function (error) {
            //console.log("Fetch error prio events" +error)
            //props.handleNotify({type: 'alert', message: "Fehler beim Abrufen der Flags: Priostörungen"})
            let obj = {
              data:  []
            };
            return (obj)
          });          

          //detailed:true/false
          // prio_issue

          const deploymentEvents = await graphDeploymentEventsAPI.get(eventURL + "?detailedString=false&type=deployment", {headers})
          .then(response => {
            if(props.showDeploymentFlags === true){
              let obj = {
                data:  response.data
              };
              return obj
            } else {
              let obj = {
                data:  []
              };
              return (obj)          
            }        
          })          
          .catch(function (error) {
            //console.log("Fetch error deployment events" + error)
            //props.handleNotify({type: 'alert', message: "Fehler beim Abrufen der Flags: Deployments"})
            let obj = {
              data:  []
            };
            return (obj)
          });
          
          const result = {
              status: res.status + "-" + res.statusText,
              headers: res.headers,
              data: res.data,
              prio: prioEvents.data,
              deployments: deploymentEvents.data
          };

          const minCrit = props.thresholds.okay;
          const warn = props.thresholds.warn;
          const crit = props.thresholds.crit;
          const graphTitel = null;
          const subTitel = null;            
          const graphEinheit = 'sek.';
          const PlotLabelColor = 'var(--PlotLabelColorCustom)';
          let defaultTimerange = 0;

          switch(props.handleGraphDefaultTimerange) {
            case '6h':
              defaultTimerange = 0
              break;
            case '12h':
              defaultTimerange = 1
              break;
            case '1d':
              defaultTimerange = 2
            break;
            case '1w':
              defaultTimerange = 3
            break;
            case '3w':
              defaultTimerange = 4
            break;
            case 'all':
              defaultTimerange = 5
            break;                              
            default:
              defaultTimerange = 0
          } 

          /// Setting the Highstock options variable
          setOptions({
            
            title: {
              text: graphTitel,
              style: {
                color: 'white',
                fontWeight: 'bold'
              }
            },

            subtitle: {
                text: subTitel,
                style: {
                    color: 'white',
                }
            },

            scrollbar: {
              barBackgroundColor: 'var(--subtext-color)',
              barBorderRadius: 5,
              barBorderWidth: 0,
              buttonBackgroundColor: 'var(--subtext-color)',
              buttonBorderWidth: 0,
              buttonBorderRadius: 5,
              trackBackgroundColor: 'var(--hover-color)',
              trackBorderWidth: 0,
              trackBorderRadius: 6,
              trackBorderColor: '#CCC'
            },

            chart: {
              zoomType: 'x',
              backgroundColor: 'transparent',
              reflow: true,
              
            events: {
                load: function () {
                    //console.log("Updating Graph  data...") 
                    
                    let series = this.series[0];
                    let prioSeries = this.series[1];
                    let deploymentSeries = this.series[2]; 
                    seriesCount.current = series.xData.length;
                    prioCount.current = prioSeries.data.length;
                    deploymentCount.current = deploymentSeries.data.length; 
                    /*
                    console.log("Series: ")
                    console.log(series)
                    console.log("Prio: ")
                    console.log(prioSeries)                    
                    console.log("Series current: " + seriesCount.current)
                    console.log("Prio current: " + prioCount.current)
                    console.log("Deployment current: " + deploymentCount.current)
                    */
                    timeseriesInterval = setInterval(function () {
                        fetchData().then(
                          function(value) {
                            /*
                            console.log("value:")
                            console.log(value)
                            
                            console.log("series: ")
                            console.log(series)
        
                            console.log("PrioSeries: ")
                            console.log(prioSeries)
        
                            console.log("deploymentSeries: ")
                            console.log(deploymentSeries)  
                            */

                            //--------------------------------------------
                            // Prüfen ob neue Values des Graphen vorliegen
                            //--------------------------------------------
                            const oldLength = seriesCount.current;
                            //console.log("Series: ")
                            //console.log(series)
                            const newLength = value.data.length;
                            //console.log("Value: ")
                            //console.log(value)                            

                            if(oldLength !== newLength){
                                console.log("Neue Daten für Graphen gefunden.")
                                const newPoints = newLength - oldLength;
                                const newStart = newLength - newPoints;
                                
                                console.log("Old Length: " + oldLength)
                                console.log("New Length: " + newLength)
                                console.log("New Points: " + newPoints)
                                console.log("New Start: " + newStart)
                                
                                if(newPoints < 10){
                                  for(let i = 0; i < newPoints; i++ ){
                                      
                                      let getFrom = newStart;                                    
                                      console.log("Add Data: " + value.data[getFrom])
                                      series.addPoint([value.data[getFrom][0], value.data[getFrom][1]], true, true);
                                      getFrom++

                                  }
                                  seriesCount.current = newLength
                                } else {
                                  console.log("Zuviele neue Messungen. Seite refreshen!")    
                                }

                            } else {
                              //console.log("Keine neuen Daten vorhanden.")
                            }

                            //--------------------------------------------
                            // Prüfen ob neue Daten der Priostörungen vorliegen
                            //--------------------------------------------  
                            
                            const prioOldLength = prioCount.current;
                            const prioNewLength = value.prio.length;

                            /*
                            console.log("PrioOld: ")
                            console.log(prioCount.current) 
                            console.log("PrioNew: " + prioNewLength)
                            */

                            if(prioOldLength !== prioNewLength){
                                console.log("Neue Daten für Priostörungen gefunden.")
                                const prioNewPoints = prioNewLength - prioOldLength;
                                const prioNewStart = prioNewLength - prioNewPoints;

                                if(prioNewPoints < 10){
                                  for(let i = 0; i < prioNewPoints; i++ ){
                                      
                                      let prioGetFrom = prioNewStart;                                    
                                      console.log("Add Prio: " + value.prio[prioGetFrom])
                                      prioSeries.addPoint({x: value.prio[prioGetFrom]['x'] ,title: value.prio[prioGetFrom]['title'],text: value.prio[prioGetFrom]['text']});
                                      prioGetFrom++

                                  }
                                  prioCount.current = prioNewLength
                                } else {
                                  console.log("Zuviele neue Einträge bei den Priostörungen. Seite refreshen!")    
                                }

                            } else {
                              //console.log("Keine neuen Priostörungen vorhanden.")
                            }   
                            
                            //--------------------------------------------
                            // Prüfen ob neue Daten der Deployments vorliegen
                            //--------------------------------------------  
                            
                            const deploymentsOldLength = deploymentCount.current;
                            const deploymentsNewLength = value.deployments.length;

                            if(deploymentsOldLength !== deploymentsNewLength){
                                console.log("Neue Daten für Priostörungen gefunden.")
                                const deploymentsNewPoints = deploymentsNewLength - deploymentsOldLength;
                                const deploymentsNewStart = deploymentsNewLength - deploymentsNewPoints;

                                if(deploymentsNewPoints < 10){
                                  for(let i = 0; i < deploymentsNewPoints; i++ ){
                                      
                                      let deploymentsGetFrom = deploymentsNewStart;                                    
                                      console.log("Add Deployment: " + value.deployments[deploymentsGetFrom])
                                      deploymentSeries.addPoint({x: value.deployments[deploymentsGetFrom]['x'] ,title: value.deployments[deploymentsGetFrom]['title'],text: value.deployments[deploymentsGetFrom]['text']});
                                      deploymentsGetFrom++

                                  }
                                  deploymentCount.current = deploymentsNewLength
                                } else {
                                  console.log("Zuviele neue Einträge bei den Deployments. Seite refreshen!")    
                                }

                            } else {
                              //console.log("Keine neuen Deployments vorhanden.")
                            }                             
                          },
                          function(error) {
                            console.log("Error beim Updaten des Graphen")
                            console.log(error)
                            setHasError(true)
                          }
                        )                        

                    }, 10000);

                }
              }
              
            },

            yAxis: [{
              opposite:false,															              // Linke Seite für die Label festlegen
              gridLineColor: 'var(--subtext-color)',
              labels: {
                  formatter: function() {
                    return this.value + ' ' + graphEinheit;							// Übergebene Einheit
                  },
                  style: {
                    color: PlotLabelColor
                  }
              },

              title: {
                  text: 'Durchlaufzeit in ' + graphEinheit,
              },

              plotLines: [{
                  color: '#e6be00', 														        // Color value
                  dashStyle: 'shortdash', 												      // Style of the plot line. Default to solid
                  value: warn, 															            // Value of where the line will appear
                  width: 2, 																            // Width of the line
                  label: {
                      text: 'Warnung: ' + warn + ' ' + graphEinheit,		// Content of the label.
                      align: 'left', 														        // Positioning of the label.
                      style: {
                          color: PlotLabelColor,												// Fontcolor
                          'font-size': 'xx-small'
                      }
                  }
              },{
                  color: '#df002e', 														        // Color value
                  dashStyle: 'shortdash', 												      // Style of the plot line. Default to solid
                  value: crit,															            // Value of where the line will appear
                  width: 2, 																            // Width of the line
                  label: {
                      text: 'Kritisch: ' + crit + ' ' + graphEinheit,		// Content of the label.
                      align: 'left', 														        // Positioning of the label.
                      style: {
                          color: PlotLabelColor,												// Fontcolor
                          'font-size': 'xx-small'
                      }
                  }
              }]
            }],


            xAxis: [{
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              
              labels: {
                style: {
                  color: PlotLabelColor
                }
              },
            }],


            rangeSelector: {
                buttonTheme: { 
                  fill: 'none',
                  stroke: 'none',
                  'stroke-width': 0,
                  r: 8,
                  style: {
                      color: 'var(--subtext-color)',
                      fontWeight: 'bold',
                      fontSize: '11px'
                  },
                  states: {
                      hover: {
                        fill: 'var(--hover-color)',
                          style: {
                              color: 'white'
                          }
                      },
                      select: {
                          fill: 'var(--hover-color)',
                          style: {
                              color: 'white'
                          }
                      }
                  }
                },
                inputStyle: {
                  color: 'var(--subtext-color)',
                  fontWeight: 'bold',
                  fontSize: '11px'
                },

                selected: defaultTimerange,
                inputEnabled: true,
                inputDateFormat: '%d.%m.%Y',
                buttons: [{
                    type: 'hour',
                    count: 6,
                    text: '6h',
                    events: {
                        click: function() {

                        }
                    }
                },{
                    type: 'hour',
                    count: 12,
                    text: '12h',
                    events: {
                        click: function() {

                        }
                    }
                },{
                    type: 'day',
                    count: 1,
                    text: '1d',
                    events: {
                        click: function() {

                        }
                    }
                },{
                    type: 'week',
                    count: 1,
                    text: '1w',
                    events: {
                        click: function() {

                        }
                    }
                },{
                    type: 'week',
                    count: 3,
                    text: '3w',
                    events: {
                        click: function() {

                        }
                    }
                },{
                    type: 'all',
                    text: 'All'
                }]
            },    


            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: false
                    }
                },
                series: {
                  lineWidth: 2
                }
            },

            series: [
                {
                    name : 'Antwortzeit',
                    turboThreshold: 11000,
                    data: result.data,
                    id: 'dataseries',

                    zones: [{															      // Festlegen in welchen Bereichen Alarmiert wird
                      value: minCrit,														// Bei y-Wert bis minCrit = crit (rot)
                      color: '#df002e'
                    },{
                      value: warn,														  // Bei y-Wert bis warn = warnung (gelb)
                      color: '#84bc34'
                    }, {
                      value: crit,														  // Bei y-Wert ab crit = kritisch (rot)
                      color: '#e6be00'
                    }, {
                      color: '#df002e'													// sonst (grÃ¼n)
                  }],

                  dataGrouping: {
                    dateTimeLabelFormats: {
                        millisecond: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        second: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        minute: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        hour: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        day: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        week: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        month: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M'],
                        year: ['%A, %e. %B, %Y %H:%M:%S', '%A, %e. %B, %Y %H:%M', ' - %H:%M']
                    }
                },

                marker: {
                  symbol: 'circle',
                  states: {
                      hover: {
                          fillColor: 'white',
                          lineColor: 'white',
                          lineWidth: 3
                      }
                  }
                },

                shadow: true,
                tooltip: {
                    xDateFormat: '%A, %e. %B, %Y %H:%M:%S',
                    pointFormat: '<p style="font-size:11px;">Durchlaufzeit: <b>{point.y}</b><br/>',		// Tooltip um Errorcode erweitern (Wird nur in grÃ¶ÃŸter Zoomstfe angezeigt)
                    valueSuffix: ' ' + graphEinheit,
                    valueDecimals: 2,
                    backgroundColor: '#FCFFC5',
                    borderColor: 'black',
                    borderRadius: 10,
                    borderWidth: 60
                },               

                },
                {
                  type: 'flags',
                  fillColor: "#5F86B3",
                  cursor: 'pointer',
                  name: 'Flags in pane',
                  data: result.prio,
                  onSeries: 'dataseries',
                  style: { // text style
                      color: 'white',
                      fontSize: '11px'
                  },
                  states: {
                      hover: {
                          fillColor: '#395C84', // darker
                          lineColor: 'white'
                      },
                      normal: {
                          fillColor: '#395C84', // darker
                          lineColor: 'white'
                      }
                  },

                  point: {
                      events: {
                          click: function () {
                              window.open(this.link, '_blank');
                          }
                      }
                  }

                },
                {
                  type: 'flags',
                  fillColor: "#d09344",
                  cursor: 'pointer',
                  name: 'Flags in pane',
                  data: result.deployments,
                  onSeries: 'dataseries',
                  style: { // text style
                      color: '#000000',
                      fontSize: '11px'
                  },
                  states: {
                      hover: {
                          fillColor: '#d09344', // darker
                          lineColor: '#000000'
                      },
                      normal: {
                          fillColor: '#d09344', // darker
                          lineColor: '#000000'
                      }
                  },

                  point: {
                      events: {
                          click: function () {
                              window.open(this.html_url, '_blank');
                          }
                      }
                  }
                }
            ],

            credits: {
                enabled: false
            },

            accessibility: {
              enabled: false
            },

            exporting: {
                enabled: false
            }
          });

          setIsLoaded(true); 



      }

      fetchRecords().catch(function (error) {
        if (error.response) {
          setHasError(true)
          console.log("ERROR! Request made and server responded")
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          setHasError(true)
          console.log("ERROR! The request was made but no response was received")
          console.log(error.request);
        } else {
          setHasError(true)
          console.log("ERROR! Something happened in setting up the request that triggered an Error")
          console.log('Error', error.message);
        }
      })
       
    // Cleanup function on component dismount
    return () => {
      clearInterval(timeseriesInterval);
    };

  }, []);
    
  /**
   * Funktion um den Resize des Parent DIV des Graphen zu detecten
   */
  React.useEffect(() => {
      const observer = new ResizeObserver(([entry]) => {
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.height);
        onLayoutChange();
      });
      observer.observe(ref.current);
      onLayoutChange();
      return () => {
        observer.disconnect();
      };
    }, []);      
      
    /***
     *     ___            __  ___    __        __  
     *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
     *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
     *                                             
     */
    const onLayoutChange = () => {
        //console.log("Layout changed!")
        for (let i = 0; i < Highcharts.charts.length; i += 1) {
          if (Highcharts.charts[i] !== undefined) {
            Highcharts.charts[i].reflow(); // here is the magic to update charts' looking
          }
        }
    };
   
    const closeTicketWindow = () => {
      setShowTicketModal(false);  
    }

    const openTicketWindow = () =>  {
      setShowTicketModal(true);  
    }

    const onOptionChange = e => {
      setTicketIssueType(e.target.value)
    }

    const summaryRef = React.useRef();
    const descriptionRef = React.useRef();
    

    const createJiraTicket = async () => {  
            
      exportAsImage(ref.current, "test").then(async function(result) {

        // Ticket JSON erstellen
        const ticketData = JSON.stringify({
          reporter: props.userName,
          product: props.productName,
          summary: summaryRef.current.value,
          description: "Ticket erstellt von: " + props.userName + " -- " + descriptionRef.current.value,
          issue_type: ticketIssueType,
          file: result.src,
        });

        const idToken = await props.receiveAccessToken();
        //const idToken = props.receiveIDToken();
        const headers = { 'Authorization': idToken };
  
        
        createTicketAPI.post("/", ticketData, {headers})
          .then(response => {
            console.log(response);
            props.handleNotify({ type: "infoPerm", message: "Ticket erstellt: " + response.data.key });
            
            let ticketlink = response.data.self
            let pathArray = ticketlink.split( '/' );
            let protocol = pathArray[0];
            let host = pathArray[2];
            let url = protocol + '//' + host + '/browse/' + response.data.key;
            closeTicketWindow();
            
            try {
              window.open(url, '_blank').focus();
            } catch (exceptionVar) {
              console.log("Popup Berechtigungen im Browser nicht gesetzt")
            }
            
          })
          .catch(function (error) {
            console.error(error);
            props.handleNotify({ type: "alert", message: "Ticket erstellen fehlgeschlagen" });
          });          

        });

      }         


      /*
      console.log("User: " + props.userName) 
      console.log("Produkt: " + props.productName) 
      console.log("Test: " + props.testName) 
      console.log("Summary: " + summaryRef.current.value) 
      console.log("Description: " + descriptionRef.current.value)
      console.log("Issue Type: " + ticketIssueType) 
      */
    
    
    

    /***
     *     __   __            __   ___ ___       __       
     *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
     *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
     *                                                    
     */

    return(
        <>
          <Popup open={showTicketModal} closeOnDocumentClick onClose={setShowTicketModal}>
            <div className="modal">
                  <div className="header"> Jira Ticket erstellen </div>
                  
                  <div className="content">

                      <div className="ticketModalStyle">
                        <div className="ticketModalStaticContent">
                          Reporter: {props.userName}<br></br><br></br><br></br>
                          Produkt: {props.productName}<br></br>
                          Test: {props.testName}<br></br><br></br><br></br>
                        </div>

                        <label>Ticket Zusammenfassung:</label><br></br>
                        <input className="ticketModalInput"
                          ref={summaryRef}
                          type="text"
                          name="summary"
                        /><br/><br/><br></br>
                        
                        <label>Ticket Beschreibung:</label><br></br>
                        <textarea  className="ticketModalArea"
                          ref={descriptionRef}
                          type="textarea"
                          name="description"
                          rows="4"
                          cols="30"
                        /><br/><br/>

                        <label>Issue Type:</label><br></br><br></br>
                        <input className="ticketModalRadio"
                          id="ticketModalRadioService"
                          type="radio"
                          value="service"
                          name="tickettype"
                          checked={ticketIssueType === "service"}
                          onChange={onOptionChange}
                        /> Service-Auftrag<br></br>
                        <input className="ticketModalRadio"
                          type="radio"
                          value="alarm"
                          name="tickettype"
                          checked={ticketIssueType === "alarm"}
                          onChange={onOptionChange}
                        /> Alarm<br></br><br></br>

                      </div>
                  </div>

                  <div className="actions">
                    <button
                      className="button"
                      onClick={() => {
                        
                        if(summaryRef.current.value !== "" && descriptionRef.current.value !== ""){
                          props.handleNotify({type: 'info', message: "Das Ticket wird erstellt. Bitte warten..."})
                          createJiraTicket()                          
                        } else {
                          props.handleNotify({type: 'alert', message: "Es muss sowohl eine Zusammenfassung als auch eine Beschreibung eingegeben werden!"})
                        }

                      }}
                    >
                      SPEICHERN
                    </button>
                    <button
                      className="button"
                      onClick={() => {
                        closeTicketWindow()
                      }}
                    >
                      ABBRECHEN
                    </button>
                  </div>
                </div>
          </Popup> 
          <div className="singleGraphContainer" ref={ref}>
            <div className="singleGraph--HeaderContainer" onClick={onLayoutChange}>
                <div className="singleGraph--HeaderTextContainer">
                    <div className="singleGraph--HeaderTextProduct"><div>{props.productName}</div></div>
                    <div className="singleGraph--HeaderTextTest"><div>{props.testName}</div></div>
                </div>
                <div className="singleGraph--HeaderMiddle"></div>
                {/*
                <div id={"tooltip-refresh"} className="singleGraph--HeaderToolBtn" onClick={() => props.handleNotify({type: 'info', message: "TODO: Sobald der Daten Refresh durch die QS ist: Graphrefresh " + props.testID})}><FontAwesomeIcon icon="fa-solid fa-rotate" /></div>
                <Tooltip    
                    className="tooltipStyles"
                    anchorId={"tooltip-refresh"} 
                    html={"Graphdaten aktualisieren"} 
                    place="bottom" 
                />
                */}
                <div id={"tooltip-errordetails"} className="singleGraph--HeaderToolBtn" onClick={() => props.handleToggleSynmonAnalyse({testID: props.testID, productID: props.productID, testName: props.testName, productName: props.productName, ok: props.thresholds.okay, warn: props.thresholds.warn, crit: props.thresholds.crit})}><FontAwesomeIcon icon="fa-solid fa-lightbulb" /></div>
                <Tooltip    
                    className="tooltipStyles"
                    anchorSelect={"#tooltip-errordetails"} 
                    html={"Fehler Screenshots und Analyse"} 
                    place="bottom" 
                    style={{ backgroundColor: "var(--hover-color)", color: "var(--text-color)", zIndex: "500" }}
                />                 
                <div id={"tooltip-newticket"} className="singleGraph--HeaderToolBtn" onClick={() => openTicketWindow()}><FontAwesomeIcon icon="fa-solid fa-ticket" /></div>
                <Tooltip    
                    className="tooltipStyles"
                    anchorSelect={"#tooltip-newticket"} 
                    html={"Jira Ticket erstellen"} 
                    place="bottom" 
                    style={{ backgroundColor: "var(--hover-color)", color: "var(--text-color)", zIndex: "500" }}
                />                
                {/*            
                <div id={"tooltip-thresholds"} className="singleGraph--HeaderToolBtn" onClick={() => props.handleNotify({type: 'info', message: "TODO: Schwellwerte anpassen"})}><FontAwesomeIcon icon="fa-solid fa-plus-minus" /></div>
                <Tooltip    
                    className="tooltipStyles"
                    anchorSelect={"#tooltip-thresholds"} 
                    html={"Schwellwerte anpassen"} 
                    place="bottom" 
                    style={{ backgroundColor: "var(--hover-color)", color: "var(--text-color)", zIndex: "500" }}
                /> 
                */}               
                <div className="singleGraph--HeaderBtn"></div>
                <div className="singleGraph--HeaderBtn" onClick={() => props.handleDeleteGraph(props.testID)}><FontAwesomeIcon icon="fa-solid fa-trash" /></div>
            </div>
            

            {
                (() => {
                    if (hasError === true)
                        return <div className="productWidget--ErrorWrap">Fehler beim Laden der Graphdaten.</div>
                    else if (isLoaded === false)
                        return(
                          <div className="productWidget--LoadingWrap">
                              <p className="productWidget--LoadingWrapText">Lade Daten</p>
                              <p><PropagateLoader color={'#6f7277'} size={4} /></p>
                          </div> 
                        )
                    else 
                        return(
                          <HighchartsReact
                            highcharts={Highcharts}
                            constructorType={'stockChart'}
                            options={options}
                            allowChartUpdate={true}
                            containerProps={{ style: { height: "100%" } }}
                          />
                        )
                })()
            }

            </div>
          
        </>    
    )

}

export default memo(SingleGraph) 