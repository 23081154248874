import React, { memo, useRef } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import ScaleText from "react-scale-text";
//import AdaptiveText from "react-adaptive-text";
//import { ResponsiveFontSize } from "@allwqre/react-responsive-font-size";
//import {scaledSize} from 'react-native-simple-responsive'
//import { AutoFontSize } from "auto-fontsize";

import PropagateLoader from "react-spinners/PropagateLoader";
import { Tooltip } from "react-tooltip";
import "../styles/ProductStatus.css";
import "../styles/ProductOverviewWidget.css";
import "react-tooltip/dist/react-tooltip.css";
import { map } from "highcharts";

const ProductStatus = (props) => {
  /***
   *     __  ___      ___  ___  __
   *    /__`  |   /\   |  |__  /__`
   *    .__/  |  /~~\  |  |___ .__/
   *
   */

  const [configMenuStatus, setConfigMenuStatus] = React.useState(false);
  const [firstRun, setFirstRun] = React.useState(true);

  const [productsWithErrors, setProductsWithErrors] = React.useState(0);

  const [errorSort, setErrorSort] = React.useState(() => {
    let usedIndex = -1;

    props.savedOptionsArray.forEach(function (widget, index) {
      if (widget.i !== undefined) {
        let string = widget.i;
        let subBefore = string.substring(0, string.indexOf("_"));

        if (subBefore === "productoverview") {
          usedIndex = index;
        }
      }
    });

    if (props.savedOptionsArray[usedIndex].optionErrorSort !== undefined) {
      return props.savedOptionsArray[usedIndex].optionErrorSort;
    } else {
      return false;
    }
  });

  const [showErrorOnly, setShowErrorOnly] = React.useState(() => {
    let usedIndex = -1;

    props.savedOptionsArray.forEach(function (widget, index) {
      if (widget.i !== undefined) {
        let string = widget.i;
        let subBefore = string.substring(0, string.indexOf("_"));

        if (subBefore === "productoverview") {
          usedIndex = index;
        }
      }
    });

    if (props.savedOptionsArray[usedIndex].optionShowErrorOnly !== undefined) {
      return props.savedOptionsArray[usedIndex].optionShowErrorOnly;
    } else {
      return false;
    }
  });

  const [isLoaded, setIsLoaded] = React.useState(false); // Indikator ob Daten geladen wurden.

  const [toggleAllProductStatus, setToggleAllProductStatus] =
    React.useState(false);

  const [widgetOptions, setWidgetOptions] = React.useState(() => {
    let usedIndex = -1;

    props.savedOptionsArray.forEach(function (widget, index) {
      if (widget.i !== undefined) {
        let string = widget.i;
        let subBefore = string.substring(0, string.indexOf("_"));

        if (subBefore === "productstatus") {
          usedIndex = index;
        }
      }
    });

    let tmpShowErrorOnly = true;
    let tmpShowAWS = false;
    let tmpShowSynthetic = true;
    let tmpShowSyntheticGIT = false;
    let tmpShowCheckMK = true;
    let tmpShowGrafana = false;
    let tmpShowGithub = false;
    let tmpShowSonarcloud = false;
    let tmpShowAtlassian = false;
    let tmpShowDocker = false;
    let tmpErrorSort = false;

    if (
      usedIndex >= 0 &&
      props.savedOptionsArray[usedIndex] &&
      props.savedOptionsArray[usedIndex].optionShowErrorOnly !== undefined
    ) {
      tmpShowErrorOnly = props.savedOptionsArray[usedIndex].optionShowErrorOnly;
    }

    if (
      usedIndex >= 0 &&
      props.savedOptionsArray[usedIndex] &&
      props.savedOptionsArray[usedIndex].optionShowAWS !== undefined
    ) {
      tmpShowAWS = props.savedOptionsArray[usedIndex].optionShowAWS;
    }

    if (
      usedIndex >= 0 &&
      props.savedOptionsArray[usedIndex] &&
      props.savedOptionsArray[usedIndex].optionShowSynthetic !== undefined
    ) {
      tmpShowSynthetic = props.savedOptionsArray[usedIndex].optionShowSynthetic;
    }

    if (
      usedIndex >= 0 &&
      props.savedOptionsArray[usedIndex] &&
      props.savedOptionsArray[usedIndex].optionShowSyntheticGIT !== undefined
    ) {
      tmpShowSyntheticGIT = props.savedOptionsArray[usedIndex].optionShowSyntheticGIT;
    }

    if (
      usedIndex >= 0 &&
      props.savedOptionsArray[usedIndex] &&
      props.savedOptionsArray[usedIndex].optionShowCheckMK !== undefined
    ) {
      tmpShowCheckMK = props.savedOptionsArray[usedIndex].optionShowCheckMK;
    }

    if (
      usedIndex >= 0 &&
      props.savedOptionsArray[usedIndex] &&
      props.savedOptionsArray[usedIndex].optionShowGrafana !== undefined
    ) {
      tmpShowGrafana = props.savedOptionsArray[usedIndex].optionShowGrafana;
    }

    if (
      usedIndex >= 0 &&
      props.savedOptionsArray[usedIndex] &&
      props.savedOptionsArray[usedIndex].optionShowGithub !== undefined
    ) {
      tmpShowGithub = props.savedOptionsArray[usedIndex].optionShowGithub;
    }

    if (
      usedIndex >= 0 &&
      props.savedOptionsArray[usedIndex] &&
      props.savedOptionsArray[usedIndex].optionShowSonarcloud !== undefined
    ) {
      tmpShowSonarcloud =
        props.savedOptionsArray[usedIndex].optionShowSonarcloud;
    }

    if (
      usedIndex >= 0 &&
      props.savedOptionsArray[usedIndex] &&
      props.savedOptionsArray[usedIndex].optionShowAtlassian !== undefined
    ) {
      tmpShowAtlassian = props.savedOptionsArray[usedIndex].optionShowAtlassian;
    }

    if (
      usedIndex >= 0 &&
      props.savedOptionsArray[usedIndex] &&
      props.savedOptionsArray[usedIndex].optionShowDocker !== undefined
    ) {
      tmpShowDocker = props.savedOptionsArray[usedIndex].optionShowDocker;
    }

    if (
      usedIndex >= 0 &&
      props.savedOptionsArray[usedIndex] &&
      props.savedOptionsArray[usedIndex].optionErrorSort !== undefined
    ) {
      tmpErrorSort = props.savedOptionsArray[usedIndex].optionErrorSort;
    }

    let options = {
      i: props.id,

      optionShowErrorOnly: tmpShowErrorOnly,
      optionShowAWS: tmpShowAWS,
      optionShowSynthetic: tmpShowSynthetic,
      optionShowSyntheticGIT: tmpShowSyntheticGIT,
      optionShowCheckMK: tmpShowCheckMK,
      optionShowGrafana: tmpShowGrafana,
      optionShowGithub: tmpShowGithub,
      optionShowSonarcloud: tmpShowSonarcloud,
      optionShowAtlassian: tmpShowAtlassian,
      optionShowDocker: tmpShowDocker,
      optionErrorSort: tmpErrorSort,

      visibleProducts: [],
    };

    props.productData.forEach(function (product) {
      let productVisibleStatus = true;

      if (
        usedIndex >= 0 &&
        props.savedOptionsArray[usedIndex] &&
        props.savedOptionsArray[usedIndex].visibleProducts !== undefined
      ) {
        const currentProductIndexInOptions = props.savedOptionsArray[
          usedIndex
        ].visibleProducts.findIndex((product2) => product2.key === product._id);
        if (currentProductIndexInOptions !== -1) {
          productVisibleStatus =
            props.savedOptionsArray[usedIndex].visibleProducts[
              currentProductIndexInOptions
            ].productVisible;
        }
      }

      let originOptions = {
        name: product.displayName,
        key: product._id,
        productVisible: productVisibleStatus,
        status: product.status,
        description: product.description,
        tests: product.tests,
      };

      options.visibleProducts.push(originOptions);
    });

    return options;
  });

  const [optionHasChanged, setOptionHasChanged] = React.useState(false);

  /*
  const updateSquares = () => {

    var countByClass = ReactDOM.findDOMNode(containerRef.current).getElementsByClassName('simplifiedOverview-ProductElement').length;
    setProductCount(countByClass);
    console.log("Anzahl der Quadrate: ", countByClass);

    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;

      const containerArea = containerHeight * (containerWidth - 400);

      //const squareArea = containerArea / productCount;
      const squareArea = containerArea / countByClass;

      let squareSize = Math.sqrt(squareArea) - 5;

      if (containerHeight < squareSize) {
        squareSize = containerHeight - 20;
      }

      //const squareSize = containerWidth / 18; // Angenommen, Sie möchten 4 Quadrate in einer Reihe haben
      const squares = containerRef.current.querySelectorAll(
        ".simplifiedOverview-ProductElement"
      );

      squares.forEach((square) => {
        square.style.width = `${squareSize}px`;
        square.style.height = `${squareSize}px`;
      });

      const productNames = containerRef.current.querySelectorAll(
        ".simplifiedOverview-ProductName"
      );

      //let fontSize = squareSize / productCount;
      let fontSize = squareSize / countByClass;

      if (fontSize < 0.7) {
        fontSize = 0.7;
      }

      productNames.forEach((productName) => {
        productName.style.fontSize = `${fontSize}rem`;
      });

      const originNames = containerRef.current.querySelectorAll(
        ".simplifiedOverview-ProductOrigin"
      );

      let originSize = squareSize / 50;

      if (originSize < 1.2) {
        originSize = 1.2;
      }

      originNames.forEach((originName) => {
        originName.style.fontSize = `${originSize}rem`;
      });
    }
  };
 */

  /***
   *          __   ___     ___  ___  ___  ___  __  ___  __
   *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__`
   *    |__/ .__/ |___    |___ |    |    |___ \__,  |  .__/
   *
   */

  // Wenn in den Props neue Daten vorliegen, wird der visibleTest State neu erstellt, um die Componenden neu zu rendern
  React.useEffect(() => {
    let test = [];
    let countProducts = 0;
    //updateSquares();

    props.productData.forEach(function (product) {
      const currentProductIndex = widgetOptions.visibleProducts.findIndex(
        (product2) => product2.key === product._id
      );

      let originProduct = {
        name: product.displayName,
        key: product._id,
        testVisible:
          widgetOptions.visibleProducts[currentProductIndex]["testVisible"],
        productVisible:
          widgetOptions.visibleProducts[currentProductIndex]["productVisible"],
        status: product.status,
        description: product.description,
        tests: product.tests,
      };

      test.push(originProduct);
    });

    widgetOptions.visibleProducts.forEach(function (product) {
      const currentProductIndex = props.productData.findIndex(
        (product2) => product2._id === product.key
      );

      if (currentProductIndex !== -1) {
        let hasAWS = false;
        let hasSynthetic = false;
        let hasSyntheticGIT = false;
        let hasCheckMK = false;
        let hasGrafana = false;
        let showProduct = false;
        let hasGithub = false;
        let hasSonarcloud = false;
        let hasAtlassian = false;
        let hasDocker = false;

        const foundAWS = product.tests.find(
          (testsItem) => testsItem.origin === "AWS"
        );
        if (foundAWS) {
          hasAWS = true;
        }

        const foundSynthetic = product.tests.find(
          (testsItem) => testsItem.origin === "synthetic"
        );
        if (foundSynthetic) {
          hasSynthetic = true;
        }

        const foundSyntheticGIT = product.tests.find(
          (testsItem) => testsItem.origin === "syntheticGIT"
        );
        if (foundSynthetic) {
          hasSyntheticGIT = true;
        }

        const foundCheckMK = product.tests.find(
          (testsItem) => testsItem.origin === "checkmk"
        );

        if (foundCheckMK) {
          hasCheckMK = true;
        }

        const foundGrafana = product.tests.find(
          (testsItem) => testsItem.origin === "grafana"
        );
        if (foundGrafana) {
          hasGrafana = true;
        }

        const foundGithub = product.tests.find(
          (testsItem) => testsItem.origin === "github"
        );
        if (foundGithub) {
          hasGithub = true;
        }

        const foundSonarcloud = product.tests.find(
          (testsItem) => testsItem.origin === "sonarcloud"
        );
        if (foundSonarcloud) {
          hasSonarcloud = true;
        }

        const foundAtlassian = product.tests.find(
          (testsItem) => testsItem.origin === "atlassian"
        );
        if (foundAtlassian) {
          hasAtlassian = true;
        }

        const foundDocker = product.tests.find(
          (testsItem) => testsItem.origin === "docker"
        );
        if (foundDocker) {
          hasDocker = true;
        }

        if (
          widgetOptions.optionShowSynthetic === true &&
          hasSynthetic === true
        ) {
          showProduct = true;
        } else if (
          widgetOptions.optionShowSyntheticGIT === true &&
          hasSyntheticGIT === true
        ) {
          showProduct = true;
        } else if (
          widgetOptions.optionShowCheckMK === true &&
          hasCheckMK === true
        ) {
          showProduct = true;
        } else if (
          widgetOptions.optionShowGrafana === true &&
          hasGrafana === true
        ) {
          showProduct = true;
        } else if (
          widgetOptions.optionShowGithub === true &&
          hasGithub === true
        ) {
          showProduct = true;
        } else if (
          widgetOptions.optionShowSonarcloud === true &&
          hasSonarcloud === true
        ) {
          showProduct = true;
        } else if (
          widgetOptions.optionShowAtlassian === true &&
          hasAtlassian === true
        ) {
          showProduct = true;
        } else if (
          widgetOptions.optionShowDocker === true &&
          hasDocker === true
        ) {
          showProduct = true;
        } else if (widgetOptions.optionShowAWS === true && hasAWS === true) {
          showProduct = true;
        }

        if (
          props.productData[currentProductIndex].status !== "OK" &&
          product.productVisible === true &&
          showProduct
        ) {
          countProducts = countProducts + 1;
          setProductsWithErrors(countProducts);
        } else {
          //console.log(countProducts)
          if (countProducts === 0) {
            setProductsWithErrors(countProducts);
          }
        }
      }
    });

    if (widgetOptions.optionErrorSort) {
      //console.log("Error Sorting!!");
      const sortOrder = ["ERROR", "WARN", "DOWN", "OK"];
      let strAscending = test.sort(
        (a, b) => sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status)
      );

      strAscending.forEach((product) => {
        product.tests.sort(
          (a, b) => sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status)
        );
      });

      setWidgetOptions({ ...widgetOptions, visibleProducts: strAscending });
      setIsLoaded(true);
    } else {
      setWidgetOptions({ ...widgetOptions, visibleProducts: test });
      setIsLoaded(true);
    }
  }, [props.productData, showErrorOnly, widgetOptions.optionErrorSort]);

  /***
   *     ___            __  ___    __        __
   *    |__  |  | |\ | /  `  |  | /  \ |\ | /__`
   *    |    \__/ | \| \__,  |  | \__/ | \| .__/
   *
   */

  const toggleConfigMenuStatus = () => {
    //console.log(props.savedOptionsArray);
    //console.log(showErrorOnly);
    //console.log(errorSort);

    setConfigMenuStatus(!configMenuStatus);
  };

  const gatherOptions = (param) => {
    setWidgetOptions((prevOptions) => ({
      ...prevOptions,
      [param]: !prevOptions[param],
    }));
  };

  React.useEffect(() => {
    //console.log("Use Effect for Option sync is called");
    try {
      // Erstellen Sie eine tiefe Kopie von widgetOptions, um Änderungen vorzunehmen
      const updatedWidgetOptions = JSON.parse(JSON.stringify(widgetOptions));

      // Iteriere über alle Produkte in visibleProducts und setze den tests-Array leer
      updatedWidgetOptions.visibleProducts.forEach((product) => {
        product.tests = [];
      });

      props.syncOptionsArray(props.id, updatedWidgetOptions);
      setOptionHasChanged(false);
    } catch (error) {}
  }, [widgetOptions]);

  /**
   * Funktion zum Ein und Ausblenden von einzelnen Produkten
   * @param {*} product
   */
  const toggleProductVisibility = (productID) => {
    if (productID === "on") {
      setToggleAllProductStatus(true);
      //console.log("Turn all off")

      let newProducts = [];
      widgetOptions.visibleProducts.forEach(function (product) {
        const updatedProduct = { ...product, productVisible: false };
        newProducts.push(updatedProduct);
      });

      setWidgetOptions({ ...widgetOptions, visibleProducts: newProducts });
      setOptionHasChanged(true);
    } else if (productID === "off") {
      setToggleAllProductStatus(false);
      //console.log("Turn all on")

      let newProducts = [];
      widgetOptions.visibleProducts.forEach(function (product) {
        const updatedProduct = { ...product, productVisible: true };
        newProducts.push(updatedProduct);
      });

      setWidgetOptions({ ...widgetOptions, visibleProducts: newProducts });
      setOptionHasChanged(true);
    } else {
      // 1. Find the product with the provided id
      const currentProductIndex = widgetOptions.visibleProducts.findIndex(
        (product) => product.key === productID
      );
      // 2. Mark the testVisible as true or false
      const productValue =
        !widgetOptions.visibleProducts[currentProductIndex]["productVisible"];
      const updatedVisibleProducts = {
        ...widgetOptions.visibleProducts[currentProductIndex],
        productVisible: productValue,
      };
      // 3. Update the visibleTests
      const newVisibleProducts = [...widgetOptions.visibleProducts];
      newVisibleProducts[currentProductIndex] = updatedVisibleProducts;

      setWidgetOptions({
        ...widgetOptions,
        visibleProducts: newVisibleProducts,
      });
      setOptionHasChanged(true);
    }
  };

  /***********************************************************************************
   * DOM für das Konfigurationsmenü generieren
   ***********************************************************************************/
  const FillConfigMenu = () => {
    return (
      <div className="productWidget--configMenuWrap">
        <div className="productWidget--HeaderWrap drag-handle">
          <div className="productWidgetHeader--Title">
            Produktübersicht - Konfiguration
          </div>
          <button
            className="productWidgetHeader--MenuBtn"
            onClick={() => toggleConfigMenuStatus()}
          >
            <FontAwesomeIcon icon="fa-regular fa-rectangle-xmark" />
          </button>
        </div>
        <div className="productWidgetConfig--ContentWrap">
          <div className="productWidgetConfigContent--HeaderWrap">
            <div className="productWidgetConfigContent--HeaderTitle">
              Widget Optionen
            </div>
            <div className="productWidgetConfigContent--LineBreak"></div>
            <div className="productWidgetConfigContent--HeaderSubTitle"></div>
          </div>
          <div className="productWidgetConfigContent--ContentWrap">
            <div className="productWidgetConfigContent--OptionWrap">
              <div className="productWidgetConfigContent--Title">
                Alarme automatisch sortieren
              </div>
              <div className="productWidgetConfigContent--Checkbox">
                <label className="switch productWidgetConfigContent--Switch">
                  <input
                    type="checkbox"
                    onChange={() => gatherOptions("optionErrorSort")}
                    checked={widgetOptions.optionErrorSort ? "checked" : ""}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
             {/*
            <div className="productWidgetConfigContent--OptionWrap">
              <div className="productWidgetConfigContent--Title">
                Status OK ausblenden (Bad only)
              </div>
              <div className="productWidgetConfigContent--Checkbox">
                <label className="switch productWidgetConfigContent--Switch">
                  <input
                    type="checkbox"
                    onChange={() => gatherOptions("optionShowErrorOnly")}
                    checked={"checked"}
                    disabled={true}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            */}
            <div className="productWidgetConfigContent--OptionWrap">
              <div className="productWidgetConfigContent--Title">
                AWS Alarme anzeigen
              </div>
              <div className="productWidgetConfigContent--Checkbox">
                <label className="switch productWidgetConfigContent--Switch">
                  <input
                    type="checkbox"
                    onChange={() => gatherOptions("optionShowAWS")}
                    checked={widgetOptions.optionShowAWS ? "checked" : ""}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="productWidgetConfigContent--OptionWrap">
              <div className="productWidgetConfigContent--Title">
                Synthetische Test anzeigen
              </div>
              <div className="productWidgetConfigContent--Checkbox">
                <label className="switch productWidgetConfigContent--Switch">
                  <input
                    type="checkbox"
                    onChange={() => gatherOptions("optionShowSynthetic")}
                    checked={widgetOptions.optionShowSynthetic ? "checked" : ""}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="productWidgetConfigContent--OptionWrap">
              <div className="productWidgetConfigContent--Title">
                Synthetische GIT Test anzeigen
              </div>
              <div className="productWidgetConfigContent--Checkbox">
                <label className="switch productWidgetConfigContent--Switch">
                  <input
                    type="checkbox"
                    onChange={() => gatherOptions("optionShowSyntheticGIT")}
                    checked={widgetOptions.optionShowSyntheticGIT ? "checked" : ""}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>            
            <div className="productWidgetConfigContent--OptionWrap">
              <div className="productWidgetConfigContent--Title">
                CheckMK Alarme anzeigen
              </div>
              <div className="productWidgetConfigContent--Checkbox">
                <label className="switch productWidgetConfigContent--Switch">
                  <input
                    type="checkbox"
                    onChange={() => gatherOptions("optionShowCheckMK")}
                    checked={widgetOptions.optionShowCheckMK ? "checked" : ""}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="productWidgetConfigContent--OptionWrap">
              <div className="productWidgetConfigContent--Title">
                Grafana Alarme anzeigen
              </div>
              <div className="productWidgetConfigContent--Checkbox">
                <label className="switch productWidgetConfigContent--Switch">
                  <input
                    type="checkbox"
                    onChange={() => gatherOptions("optionShowGrafana")}
                    checked={widgetOptions.optionShowGrafana ? "checked" : ""}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="productWidgetConfigContent--OptionWrap">
              <div className="productWidgetConfigContent--Title">
                Github Alarme anzeigen
              </div>
              <div className="productWidgetConfigContent--Checkbox">
                <label className="switch productWidgetConfigContent--Switch">
                  <input
                    type="checkbox"
                    onChange={() => gatherOptions("optionShowGithub")}
                    checked={widgetOptions.optionShowGithub ? "checked" : ""}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="productWidgetConfigContent--OptionWrap">
              <div className="productWidgetConfigContent--Title">
                Sonarcloud Alarme anzeigen
              </div>
              <div className="productWidgetConfigContent--Checkbox">
                <label className="switch productWidgetConfigContent--Switch">
                  <input
                    type="checkbox"
                    onChange={() => gatherOptions("optionShowSonarcloud")}
                    checked={
                      widgetOptions.optionShowSonarcloud ? "checked" : ""
                    }
                  ></input>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="productWidgetConfigContent--OptionWrap">
              <div className="productWidgetConfigContent--Title">
                Atlassian Alarme anzeigen
              </div>
              <div className="productWidgetConfigContent--Checkbox">
                <label className="switch productWidgetConfigContent--Switch">
                  <input
                    type="checkbox"
                    onChange={() => gatherOptions("optionShowAtlassian")}
                    checked={widgetOptions.optionShowAtlassian ? "checked" : ""}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="productWidgetConfigContent--OptionWrap">
              <div className="productWidgetConfigContent--Title">
                Docker Alarme anzeigen
              </div>
              <div className="productWidgetConfigContent--Checkbox">
                <label className="switch productWidgetConfigContent--Switch">
                  <input
                    type="checkbox"
                    onChange={() => gatherOptions("optionShowDocker")}
                    checked={widgetOptions.optionShowDocker ? "checked" : ""}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="productWidgetConfigContent--HeaderWrap">
            <div className="productWidgetConfigContent--HeaderTitle">
              Produktauswahl
            </div>
            {toggleAllProductStatus ? (
              <>
                <div
                  className="productWidgetConfigContent--HeaderIcon"
                  onClick={() => toggleProductVisibility("off")}
                >
                  <FontAwesomeIcon icon="fa-regular fa-eye" />
                </div>
              </>
            ) : (
              <>
                <div
                  className="productWidgetConfigContent--HeaderIcon"
                  onClick={() => toggleProductVisibility("on")}
                >
                  <FontAwesomeIcon icon="fa-regular fa-eye-slash" />
                </div>
              </>
            )}

            <div className="productWidgetConfigContent--LineBreak"></div>
            <div className="productWidgetConfigContent--HeaderSubTitle">
              Nur hier ausgewählte Produkte werden in der Übersicht dargestellt
            </div>
          </div>

          <div className="productWidgetConfigContent--ProductContentWrap">
            {widgetOptions.visibleProducts.map((originElement) => (
              <div
                key={originElement.key}
                className="productWidgetConfigContent--ProductWrap"
                onClick={() => toggleProductVisibility(originElement.key)}
              >
                {originElement.productVisible ? (
                  <>
                    <div className="productWidgetConfigContent--Title">
                      {originElement.name}
                    </div>
                    <div className="productWidgetConfigContent--Icon">
                      <FontAwesomeIcon icon="fa-regular fa-eye" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="productWidgetConfigContent--Title productWidgetConfigContent--subtextColor">
                      {originElement.name}
                    </div>
                    <div className="productWidgetConfigContent--Icon productWidgetConfigContent--subtextColor">
                      <FontAwesomeIcon icon="fa-regular fa-eye-slash" />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="productWidgetConfig--DeleteWrap">
          <button
            className="productWidgetHeader--DeleteBtn"
            onClick={() => props.handleDelete(props.id)}
          >
            <FontAwesomeIcon icon="fa-solid fa-trash" /> Widget entfernen
          </button>
        </div>
      </div>
    );
  };

  const containerRef = useRef(null);

  /*
  React.useEffect(() => {
    const anzahlSquares = document.querySelectorAll('.simplifiedOverview-ProductElement ');

    var countByClass = ReactDOM.findDOMNode(containerRef.current).getElementsByClassName('simplifiedOverview-ProductElement').length;
    console.log("Anzahl der Quadrate: ", countByClass);

    //setProductCount(anzahlSquares);
  }, []);

  
  React.useEffect(() => {

    // Aktualisierung einmal beim Laden der Seite
    //updateSquares();

    const resizeObserver = new ResizeObserver(() => {
      updateSquares();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  */

  const FillProductContent = (product) => {
    let hasAWS = false;
    let hasSynthetic = false;
    let hasSyntheticGIT = false;
    let hasCheckMK = false;
    let hasGrafana = false;
    let showProduct = false;
    let hasGithub = false;
    let hasSonarcloud = false;
    let hasAtlassian = false;
    let hasDocker = false;

    const foundAWS = product.product.tests.find(
      (testsItem) => testsItem.origin === "AWS"
    );
    if (foundAWS) {
      hasAWS = true;
    }

    const foundSynthetic = product.product.tests.find(
      (testsItem) => testsItem.origin === "synthetic"
    );
    if (foundSynthetic) {
      hasSynthetic = true;
    }

    const foundSyntheticGIT = product.product.tests.find(
      (testsItem) => testsItem.origin === "syntheticGIT"
    );
    if (foundSyntheticGIT) {
      hasSyntheticGIT = true;
    }

    const foundCheckMK = product.product.tests.find(
      (testsItem) => testsItem.origin === "checkmk"
    );

    if (foundCheckMK) {
      hasCheckMK = true;
    }

    const foundGrafana = product.product.tests.find(
      (testsItem) => testsItem.origin === "grafana"
    );
    if (foundGrafana) {
      hasGrafana = true;
    }

    const foundGithub = product.product.tests.find(
      (testsItem) => testsItem.origin === "github"
    );
    if (foundGithub) {
      hasGithub = true;
    }

    const foundSonarcloud = product.product.tests.find(
      (testsItem) => testsItem.origin === "sonarcloud"
    );
    if (foundSonarcloud) {
      hasSonarcloud = true;
    }

    const foundAtlassian = product.product.tests.find(
      (testsItem) => testsItem.origin === "atlassian"
    );
    if (foundAtlassian) {
      hasAtlassian = true;
    }

    const foundDocker = product.product.tests.find(
      (testsItem) => testsItem.origin === "docker"
    );
    if (foundDocker) {
      hasDocker = true;
    }

    if (widgetOptions.optionShowSynthetic === true && hasSynthetic === true) {
      showProduct = true;
    } else if (
      widgetOptions.optionShowSyntheticGIT === true &&
      hasSyntheticGIT === true
    ) {
      showProduct = true;
    } else if (
      widgetOptions.optionShowCheckMK === true &&
      hasCheckMK === true
    ) {
      showProduct = true;
    } else if (
      widgetOptions.optionShowGrafana === true &&
      hasGrafana === true
    ) {
      showProduct = true;
    } else if (widgetOptions.optionShowGithub === true && hasGithub === true) {
      showProduct = true;
    } else if (
      widgetOptions.optionShowSonarcloud === true &&
      hasSonarcloud === true
    ) {
      showProduct = true;
    } else if (
      widgetOptions.optionShowAtlassian === true &&
      hasAtlassian === true
    ) {
      showProduct = true;
    } else if (widgetOptions.optionShowDocker === true && hasDocker === true) {
      showProduct = true;
    } else if (widgetOptions.optionShowAWS === true && hasAWS === true) {
      showProduct = true;
    }

    /* 
      Das hier ist wild. Ich will wenn Error only gesetzt ist ja nur die Dinger anzeigen die errors haben. Durch das 
      Optionen setzen hier oben sind ja schon die Produkte gesetzt, die generell angezeigt werden sollen. Deshalb
      prüfe ich hier nur noch einmal, ob error Only an ist. Wenn ja dann schaue ich, ob das Produkt generell schon
      angezeigt wird und ob es einen Status anders als OK hat. Wenn das der Fall ist wirds angezeigt, wenn nicht dann nicht!
    */
    //console.log("Show Error Only:");
    //console.log(widgetOptions.optionShowErrorOnly);
    if (widgetOptions.optionShowErrorOnly === true) {
      if (
        widgetOptions.optionShowErrorOnly === true &&
        product.product.status !== "OK" &&
        showProduct === true
      ) {
        showProduct = true;
      } else {
        showProduct = false;
      }
    }

    const productName = product.product.name;

    if (showProduct === false) {
      return null;
    } else {
      return (
        <>
          {product.product.status !== "OK" ? (
            (() => {
              let testOrigin;
              // Logik für das Setzen von testOrigin
              product.product.tests.forEach((test) => {
                if (test.status !== "OK") {
                  if (test.origin === "synthetic") {
                    testOrigin = "SYN";
                  } else if (test.origin === "syntheticGIT") {
                    testOrigin = "GIT";
                  } else if (test.origin === "github") {
                    testOrigin = "GITHUB";
                  } else if (test.origin === "grafana") {
                    testOrigin = "GRAFANA";
                  } else {
                    testOrigin = test.origin;
                  }
                }
              });

              return (
                <div
                  className={
                    "simplifiedOverview-ProductElement simplifiedOverview-ProductElement-" +
                    product.product.status
                  }
                >
                  <div className="simplifiedOverview-ProductOrigin">
                    {/*<ScaleText widthOnly={true} maxFontSize={48}>*/}
                    {/*<ResponsiveFontSize ratio={0.25} optionsObject={{ setFontSize: true, lockFontSize: false }}>*/}
                    {testOrigin}
                    {/*</ResponsiveFontSize>*/}
                    {/*</ScaleText>*/}
                    {/*<AutoFontSize
                      text={testOrigin}
                      minTextSize={16}
                      textSize={80}
                      textSizeStep={2}
                      //targetLines={1}
                    />*/}
                    {/*<AdaptiveText width="75%" text={testOrigin} />*/}
                    {/*testOrigin*/}
                  </div>
                  <div className="simplifiedOverview-ProductNameContainer">
                    <div className="simplifiedOverview-ProductName">
                      {/*<ScaleText widthOnly={true} maxFontSize={16}>*/}
                      {/*<ResponsiveFontSize ratio={0.25} optionsObject={{ setFontSize: true,lockFontSize: false }}>*/}
                      {productName}
                      {/*</ResponsiveFontSize>*/}
                      {/*</ScaleText>*/}
                      {/*<AutoFontSize
                        text={productName}
                        minTextSize={16}
                        textSize={80}
                        textSizeStep={2}
                        //targetLines={1}
                      />*/}
                      {/*<AdaptiveText width="75%" text={productName} />*/}
                      {/*productName*/}
                    </div>
                  </div>
                </div>
              );
            })()
          ) : (
            <div
              className={
                "simplifiedOverview-ProductElement simplifiedOverview-ProductElement-" +
                product.product.status
              }
            >
              <div className="simplifiedOverview-ProductNameContainer">
                <div className="simplifiedOverview-ProductName">
                  {/*<ScaleText widthOnly={true} maxFontSize={16}>*/}
                  {/*<ResponsiveFontSize ratio={0.25} optionsObject={{ setFontSize: true, lockFontSize: false }}>*/}
                  {productName}
                  {/*</ResponsiveFontSize>*/}
                  {/*</ScaleText>*/}
                  {/*<AutoFontSize
                        text={productName}
                        minTextSize={16}
                        textSize={80}
                        textSizeStep={2}
                        //targetLines={1}
                  />*/}
                  {/*<AdaptiveText width="75%" text={productName} />*/}
                  {/*productName*/}
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  };
/*
  const resizeFont = (entries) => {
    for (const entry of entries) {
      const container = entry.target;
      const fontSize = Math.min(container.clientWidth / 2, 36); // Anpassen, wie benötigt
      console.log(container.clientWidth)
      container.style.fontSize = `${fontSize}px`;
    }
  };
  
  const ro = new ResizeObserver(resizeFont);
  
  const elements = document.querySelectorAll('.simplifiedOverview-ProductName');
  elements.forEach((element) => {
    ro.observe(element);
  });
  
*/

  /***
   *     __   __            __   ___ ___       __
   *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ |
   *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \|
   *
   */

  return (
    <>
      {configMenuStatus ? <FillConfigMenu /> : null}
      <div className="productWidget--HeaderWrap drag-handle">
        <div className="mauiWidgetHeader--Title">Produktstatus</div>
        <button
          className="productWidgetHeader--MenuBtn"
          onClick={() => toggleConfigMenuStatus()}
        >
          <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
        </button>
      </div>

      <div ref={containerRef} className="simplifiedOverview-Wrapper">
        {(() => {
          if (isLoaded === false)
            return (
              <div className="productWidget--LoadingWrap">
                <p className="productWidget--LoadingWrapText">Lade Daten</p>
                <p>
                  <PropagateLoader color={"#6f7277"} size={4} />
                </p>
              </div>
            );

          if (
            isLoaded === true &&
            productsWithErrors === 0 &&
            widgetOptions.optionShowErrorOnly === true
          ) {
            return (
              <div className="productWidget--ErrorWrap">
                Bei den ausgewählten Produkten sind <br />
                z.Zt. keine Alarme vorhanden
              </div>
            );
          } else {
            return widgetOptions.visibleProducts.map((position) => {
              if (position.productVisible === true) {
                return (
                  <FillProductContent
                    key={position.key}
                    id={position.key}
                    product={position}
                  />
                );
              }
            });
          }
        })()}
      </div>
    </>
  );
};

export default memo(ProductStatus);
